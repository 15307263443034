import * as React from 'react';
import {env} from "../../dtos/API_DOMAINS";
import ARCLOGO_WHITE from "../../assets/img/ARC_Logo_White.png";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";

export type ARCLogoProps = {
    onClick?: () => void
}

export const ARCLogo:React.ComponentType<ARCLogoProps> = ({onClick}) => {

    return (
        <img className={'arc-logo'}
             style={!!onClick ? {cursor: 'pointer'} : {}}
             onClick={onClick}
             src={(env && (env === 'qa' || env === 'preview')) ? ARCLOGO_WHITE : ARCLOGO_BLACK}
             alt="ARC Logo"
        />
    )
}