import {createStyles, makeStyles, styled, Theme} from "@material-ui/core/styles";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import {Button, Container, Menu, MenuItem, useTheme} from "@material-ui/core";
import {appContextSelector} from "../app-context/appContextSlice";
import {useAppSelector} from "../../store/hooks";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {BLACK} from "../../themes/colors";
import {useNavigate, useLocation} from "react-router-dom";
import {useAppViewContext} from "../app-context/AppViewContainer";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as Icons from "@material-ui/icons";
import NavigationMenu from "../ui-components/NavigationMenu";
import {
    KPI_DASHBOARD,
    KPI_DASHBOARD_2_6,
    KPI_DASHBOARD_7_PLUS,
    REPORT_OPTIONS,
    SCORECARD_DASHBOARD
} from "../../dtos/Report";
import { ReportOption } from "../reports/ReportTypes";
import {useCurrentUserContext} from "../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../util/Localization";
import {SAMS_BRANDS} from "../../dtos/Brand";

type SideBarProps = {
    links?: React.ReactNode | undefined,
    underlined?: boolean
};

export const SideBar:React.ComponentType<SideBarProps> = (props) => {
    const theme = useTheme();
    const isMdOrBelow = useMediaQuery(theme.breakpoints.down('md'));

    const classes = useStyles();
    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const currentUserContext = useCurrentUserContext();
    const navigate = useNavigate();
    const location = useLocation();
    const firstName = context.authorizationDto?.firstName;

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const reportsViewable = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport;
    }, [currentUserContext.groups.isArcTechSupport]);

    const importViewable = React.useMemo(() => {
        return !currentUserContext.groups.isArcTechSupport && !currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);
    
    const isSams = React.useMemo(() => {
        if (SAMS_BRANDS.find(id => id === context.currentBrand?.brandId)) {
            return context.currentLocation?.serialIsDevice;
        }
        return false;
    }, [context.currentBrand?.brandId, context.currentLocation?.serialIsDevice])

    const REPORT_LIST = React.useMemo<ReportOption[]>(() => {
        let KPI_DASH: ReportOption;
        if(context.authorizationDto && context.currentBrand) {
            const brandLocationCount = context.authorizationDto.authorizedLocations.filter(l => l.brand.brandId === context.currentBrand?.brandId).length;
            const authorizedLocationCount = context.authorizationDto.authorizedLocations?.length;

            if(brandLocationCount === 1 || authorizedLocationCount === 1) {
                KPI_DASH = KPI_DASHBOARD
            } else if (brandLocationCount < 7 || authorizedLocationCount < 7) {
                KPI_DASH = KPI_DASHBOARD_2_6;
            } else if (brandLocationCount > 6 || authorizedLocationCount > 6) {
                KPI_DASH = KPI_DASHBOARD_7_PLUS;
            } else {
                KPI_DASH = KPI_DASHBOARD;
            }
        } else {
            KPI_DASH = KPI_DASHBOARD;
        }

        return [SCORECARD_DASHBOARD, KPI_DASH, ...REPORT_OPTIONS];
    }, [context.authorizationDto, context.currentBrand]);

    return (
        <Container className={classes.container}>
            {firstName &&
            <WelcomeText> {getLocalizedString('navigation.welcome', 'Welcome, ') + firstName} </WelcomeText>}
            {
                props.underlined &&
                <Underline/>
            }

            {props.links ? props.links :
                isMdOrBelow ?
                <>
                    <Button
                        id="sidebar-menu-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Icons.Menu/>
                    </Button>

                    <Menu
                        id="sidebar-menu"
                        anchorEl={anchorEl}
                        open={open}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'sidebar-menu-button',
                        }}
                        PaperProps={{
                            style: {
                                width: calculateResponsiveSizeWithMinMax('35', '300', '100')
                            },
                        }}
                    >

                    {reportsViewable &&
                        <>
                            <MenuLink
                                className={location.pathname.includes('reports') ? classes.focused_link : classes.link}
                                onClick={() => {
                                    navigate(`/reports/view/locationId=${context.currentLocation?.locationId}`)
                                }}>
                                {getLocalizedString('navigation.reports', 'Reports')}
                            </MenuLink>


                            {location.pathname.includes('reports') && REPORT_LIST.map((option, idx) => {
                                return (
                                <MenuSubLink key={idx} disabled={!option.viewable} onClick={() => {navigate(`/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${option.lookerId}`)}}
                                >
                                <p style={{margin: '0', width: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                            {option.displayName}
                                </p>
                                </MenuSubLink>
                                )
                            })}
                        </>
                    }

                        <MenuLink className={(location.pathname.includes('employees') || location.pathname.includes('home')) ? classes.focused_link : classes.link}
                                  onClick={() => {navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}>
                            {getLocalizedString('navigation.employees', 'Users')}
                        </MenuLink>

                        {location.pathname.includes("employees") &&
                        <div>
                            {appViewContext.brandView || context.currentBrand?.employeeAPIEnabled ?
                                // <Tooltip placement="top" title={'Please select a location above in order to use the Import Users feature.'}>
                                //     <MenuSubLink disabled={appViewContext.brandView || !importExportViewable} onClick={() => {navigate('/employees/import')}}
                                //                  className={classes.sub_link}
                                //     >
                                //         {'Import Users'}
                                //     </MenuSubLink>
                                // </Tooltip>
                                null
                                :
                                <MenuSubLink disabled={appViewContext.brandView || !importViewable} onClick={() => {navigate('/employees/import')}}
                                             className={location.pathname.includes("employees/import") ? classes.focused_sub_link : classes.sub_link}
                                >
                                    {getLocalizedString('navigation.importEmployees', 'Import Users')}
                                </MenuSubLink>
                            }

                            {appViewContext.brandView ?
                                // <Tooltip placement="top" title={'Please select a location above in order to use the Export Users feature.'}>
                                //     <MenuSubLink disabled={appViewContext.brandView || !importExportViewable} onClick={() => {navigate('/employees/export')}}
                                //                  className={classes.sub_link}
                                //     >
                                //         {'Export Users'}
                                //     </MenuSubLink>
                                // </Tooltip>
                                null
                                :
                                <MenuSubLink disabled={appViewContext.brandView} onClick={() => {navigate('/employees/export')}}
                                             className={location.pathname.includes("employees/export") ? classes.focused_sub_link : classes.sub_link}
                                >
                                    {getLocalizedString('navigation.exportEmployees', 'Export Users')}
                                </MenuSubLink>
                            }

                            {appViewContext.brandView ?
                                // <Tooltip placement="top" title={'Please select a location above in order to use the Device Permissions Groups feature.'}>
                                //     <span>
                                //         <MenuSubLink disabled={appViewContext.brandView || !importExportViewable} onClick={() => {navigate(`/employees/permissions/view/locationId=${context.currentLocation?.locationId}`)}}
                                //                      className={classes.sub_link}
                                //         >
                                //             {'Device Permissions Groups'}
                                //         </MenuSubLink>
                                //     </span>
                                // </Tooltip>
                                null
                                :
                                <MenuSubLink disabled={appViewContext.brandView} onClick={() => {navigate(`/employees/permissions/view/locationId=${context.currentLocation?.locationId}`)}}
                                             className={location.pathname.includes("devices/permissions") ? classes.focused_sub_link : classes.sub_link}
                                >
                                    {getLocalizedString('navigation.devicePickupPermission', 'Device Permissions Groups')}
                                </MenuSubLink>
                            }
                        </div>}

                        <MenuLink className={location.pathname.includes('devices') ? classes.focused_link : classes.link}
                                  onClick={() => {navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}>
                            {getLocalizedString('navigation.devices', 'Devices')}
                        </MenuLink>

                        {location.pathname.includes("devices") &&
                        <div>
                            {appViewContext.brandView ?
                                // <Tooltip placement="top" title={'Please select a location above in order to use the Import Devices feature.'}>
                                //     <span>
                                //         <MenuSubLink disabled={appViewContext.brandView || !importExportViewable} onClick={() => {navigate('/devices/import')}}
                                //                      className={classes.sub_link}
                                //         >
                                //             {'Import Devices'}
                                //         </MenuSubLink>
                                //     </span>
                                // </Tooltip>
                                null
                                :
                                <MenuSubLink disabled={appViewContext.brandView || !importViewable || isSams} onClick={() => {navigate('/devices/import')}}
                                             className={location.pathname.includes("devices/import") ? classes.focused_sub_link : classes.sub_link}
                                >
                                    {getLocalizedString('navigation.importDevices', 'Import Devices')}
                                </MenuSubLink>
                            }


                            {appViewContext.brandView ?
                                // <Tooltip placement="top" title={'Please select a location above in order to use the Export Devices feature.'}>
                                //     <span>
                                //         <MenuSubLink disabled={appViewContext.brandView || !importExportViewable} onClick={() => {navigate('/devices/export')}}
                                //                      className={classes.sub_link}
                                //         >
                                //             {'Export Devices'}
                                //         </MenuSubLink>
                                //     </span>
                                // </Tooltip>
                                null
                                :
                                <MenuSubLink disabled={appViewContext.brandView} onClick={() => {navigate('/devices/export')}}
                                             className={location.pathname.includes("devices/export") ? classes.focused_sub_link : classes.sub_link}
                                >
                                    {getLocalizedString('navigation.exportDevices', 'Export Devices')}
                                </MenuSubLink>
                            }
                        </div>}

                        <MenuLink className={(location.pathname.includes('faq') || location.pathname.includes('emergency')) ? classes.focused_link : classes.link}
                                  onClick={() => {navigate(`/faq/view/locationId=${context.currentLocation?.locationId}`)}}>
                            {getLocalizedString('navigation.faq', 'Help')}
                        </MenuLink>
                    </Menu>
                </>
                    :
                <>
                    <div className={classes.links}>

                        {reportsViewable &&
                        <NavigationMenu
                            label={getLocalizedString('navigation.reports', 'Reports')}
                            domain={'reports'}
                            currentPage={location.pathname}
                            navHomeOnClick={false}
                            homePath={`/reports/view/locationId=${context.currentLocation?.locationId}`}
                            options={REPORT_LIST.map(option => {
                                return (
                                    {
                                        name: option.displayName,
                                        path: `/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${option.lookerId}`
                                    }
                                );
                            })}
                        />
                        }

                        <NavigationMenu
                            label={getLocalizedString('navigation.employees', 'Users')}
                            domain={'employees'}
                            currentPage={location.pathname}
                            navHomeOnClick={true}
                            restrictMenuToHomePath={true}
                            homePath={`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`}
                            options={
                            [
                                !context.currentBrand?.employeeAPIEnabled ? {name: 'Import Users', path: '/employees/import', disabled: !importViewable} : undefined,
                                {name: 'Export Users', path: '/employees/export', disabled: appViewContext.brandView},
                                {name: 'Device Permissions Groups', path: `/employees/permissions/view/locationId=${context.currentLocation?.locationId}`, disabled: appViewContext.brandView}
                            ]}
                        />

                        <NavigationMenu
                            label={getLocalizedString('navigation.devices', 'Devices')}
                            domain={'devices'}
                            currentPage={location.pathname}
                            navHomeOnClick={true}
                            restrictMenuToHomePath={true}
                            homePath={`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`}
                            options={
                            [
                                (importViewable) ? {name: 'Import Devices', path: '/devices/import', disabled: !importViewable || isSams} : undefined,
                                {name: 'Export Devices', path: '/devices/export', disabled: appViewContext.brandView}
                            ]}
                        />

                        <NavigationMenu
                            label={getLocalizedString('navigation.faq', 'Help')}
                            domain={'faq'}
                            navHomeOnClick={true}
                            restrictMenuToHomePath={true}
                            currentPage={location.pathname}
                            homePath={`/faq/view/locationId=${context.currentLocation?.locationId}`}
                        />

                    </div>
                </>
            }
        </Container>
    )
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            borderBottom: '2px solid',
            borderBottomColor: theme.palette.primary.main,
            fontSize: '11px',
            width: '84vw',
            minWidth: 'unset',
            maxWidth: 'unset',
            padding: '0 0 0 0',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            margin: '0 6vw 0 6vw'
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        link: {
          cursor: 'pointer',
          color: BLACK,
          width: '50%',
          fontSize: calculatedFontSize('1em', '1.4em'),
          "&:hover": {
              color: '#D4693C',
              textDecoration: 'none',
              borderBottom: 'none'
          }
        },
        sub_link: {
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: BLACK,
            margin: '0 10px 0 0',
            width: '100%',
            fontSize: calculatedFontSize('.8em', '.9em'),
            "&:hover": {
                color: '#D4693C',
                textDecoration: 'none',
                borderBottom: 'none'
            },
            [`${theme.breakpoints.down('md')}`]: {
                fontSize: calculatedFontSize('1em', '1.4em'),
            }
},
        focused_sub_link: {
            cursor: 'pointer',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            textTransform: 'none',
            color: theme.palette.primary.main,
            margin: '0 10px 0 0',
            width: '100%',
            fontSize: calculatedFontSize('.8em', '.9em'),
            "&:hover": {
                color: '#D4693C',
                textDecoration: 'none',
                borderBottom: 'none'
            },
            [`${theme.breakpoints.down('md')}`]: {
                fontSize: calculatedFontSize('1em', '1.4em'),
            }
        },
        focused_link: {
            cursor: 'pointer',
            color: theme.palette.primary.main,
            width: '50%',
            fontSize: calculatedFontSize('1em', '1.4em'),
            fontWeight: 'bold',
            "&:hover": {
                color: '#D4693C',
                textDecoration: 'none',
                borderBottom: 'none'
            }
        },
        links: {
            fontSize: '1.5em',
            width: '50vw',
            maxWidth: '300px',
            display: 'flex',
            paddingBottom: '5px',
        }
    }),
);

const WelcomeText = styled(Typography) (({theme}) => ({
    fontSize: calculatedFontSize('1.7em', '1.7em'),
    marginTop: '0',
    width: '40vw',
    maxWidth: '300px',
    paddingBottom: '5px'
}));

const Underline = styled('div') (({theme}) => ({
    borderBottom: '2px solid',
    borderBottomColor: theme.palette.primary.main,
    width: '90vw',
    maxWidth: 'unset',
    marginLeft: 0
}));

const MenuLink = styled(MenuItem) (({theme}) => ({
    fontSize: calculateResponsiveSizeWithMinMax('3', '20', '8'),
    minWidth: '125px'
}));

const MenuSubLink = styled(MenuItem) (({theme}) => ({
    fontSize: calculateResponsiveSizeWithMinMax('2', '16', '8'),
    paddingTop: '5px',
    paddingLeft: '25px',
    minWidth: '125px'
}));
