import React from 'react';
import HoverErrorMessage from './HoverErrorMessage';

export interface ErrorWrapperProps {
    errorMessage: string | undefined,
    shown: boolean,
    setShown: (newShown: boolean) => void
}

const ErrorWrapper: React.FC<ErrorWrapperProps> = ({ children, errorMessage, shown, setShown }) => {
    const handleFocusIn = React.useCallback(() => {
        setShown(true);
    }, [setShown]);
    
    const handleFocusOut = React.useCallback((event: React.FocusEvent<HTMLDivElement, Element>) => {
        // Checks if the new active element is still within the parent
        if (!event.currentTarget.contains(document.activeElement)) {
            setShown(false);
        }
    }, [setShown]);
    
    const closeErrorMessage = React.useCallback(() => {
        setShown(false);
    }, [setShown]);

    return (
        <div style={{ position: 'relative' }} onFocus={handleFocusIn} onBlur={handleFocusOut}>
            {children}
            {errorMessage && shown && (
                <div>
                    <HoverErrorMessage message={errorMessage} onClose={closeErrorMessage}/>
                </div>
            )}
        </div>
    );
};
export const useFieldErrors = () => {
    const [fieldErrors, setFieldErrors] = React.useState<{
        [fieldName: string]: {
            error: string | undefined,
            shown: boolean,
        }
    }>({});
    const createSetShownByField = (fieldName: string) => (shown: boolean) => {
        setFieldErrors(prevState => ({
            ...prevState, 
            [fieldName]: {
                error: prevState[fieldName]?.error,
                shown
            }
        }))
    }
    
    const createErrorProps = (fieldName: string): ErrorWrapperProps => ({
        errorMessage: fieldErrors[fieldName]?.error,
        shown: fieldErrors[fieldName]?.shown,
        setShown: createSetShownByField(fieldName)
    })
    
    const setFieldError = (fieldName: string, error: string, shown: boolean = true) => {
        setFieldErrors(prevState => ({
            ...prevState, 
            [fieldName]: {error, shown}
        }))
    }
    
    const clearFieldError = (fieldName: string) => {
        setFieldErrors(prevState => ({
            ...prevState, 
            [fieldName]: {error: undefined, shown: false}
        }))
    }
    
    return {
        fieldErrors,
        setFieldErrors,
        createErrorProps,
        setFieldError,
        clearFieldError
    }
};
export default ErrorWrapper;