import * as React from 'react';
import {
    ArrowDownward,
    ArrowDropDown,
    ArrowDropUp,
    ArrowUpward,
    AssessmentOutlined,
    GroupOutlined,
    Search
} from "@material-ui/icons";
import {makeStyles, styled} from "@material-ui/core/styles";
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    TextField,
    Typography,
    useTheme
} from "@material-ui/core";
import {BLACK, BLUE, DARK_GREY, GREY, LIGHTER_GREY, RED, WHITE} from "../../../themes/colors";
import {
    CancelIcon,
    CancelIconContainer,
    CenteredDiv,
    ErrorMessageSectionItem,
    FormTitle,
    HiddenOnMobileDiv,
    MainContentContainer,
    ScalableForm,
    ScalableFormControl,
    ScalableGrid,
    SuccessMessageSectionItem,
    UndeterminedCheckboxIcon
} from "../../ui-components/StyledComponents";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {
    employeeContextActions,
    employeeContextSelector, employeeOffsetSelector,
    getEmployeesByBrand, getEmployeesByBrandRefresh
} from "../employeeSlice";
import {AppWithFooter} from "../../../App";
import {LocationState} from "../../../@types/types";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {
    EMPLOYEE_ORDER_BY_TYPES,
    EMPLOYEE_STATUS,
    EmployeeDto,
    getAccessLevel, hasFullPickupPermission, hasPickupPermission,
    isFilterEqual
} from "../../../dtos/Employee";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {useAppViewContext} from "../../app-context/AppViewContainer";
import {useCurrentUserContext} from "../../app-context/CurrentUserContextContainer";
import {ReportOption} from "../../reports/ReportTypes";
import {BulkEmployeeAction} from "../components/BulkEmployeeAction";
import {KIOSK_GROUPS} from "../../../dtos/EmployeeGroup";
import {KioskGroupsType} from "../../app-context/containers/UserPermissions";
import {selectBrandThunk} from "../../app-context/thunks/SelectBrandThunk";
import {VanillaCheckbox} from "../../ui-components/VanillaCheckbox";
import {EMPLOYEE_DASHBOARD} from "../../../dtos/Report";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DropdownMenu from "../../ui-components/DropdownMenu";
import {LocationSelect} from "../../ui-components/LocationSelect";
import {AllLocationsDto} from "../../../dtos/Location";
import {
    devicePickupPermissionContextSelector,
    viewAllDevicePickupPermissions
} from "../../devices/devicePickupPermissionSlice";
import {InfiniteScrollList, InfiniteScrollProps} from "../../ui-components/InfiniteScroll";
import {ListOnItemsRenderedProps, ListOnScrollProps, VariableSizeList as List} from "react-window";
import "../../../css/ViewEmployees.css"
import {SAMS_BRANDS} from "../../../dtos/Brand";
import ColorSelect, {Option} from "../../ui-components/ColorSelect";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";
import {ARCBadge} from "../../ui-components/design-system/ARCBadge";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, brandId} = useParams();
    const {state} = useLocation() as LocationState;

    const offset = React.useRef(useAppSelector(employeeOffsetSelector));

    const getEmployees = React.useCallback(() => {
        if (offset.current >= 0) {
            dispatch(getEmployeesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    }, [offset, dispatch, context.currentLocation?.locationId, context.currentBrand?.brandId]);

    React.useEffect(() => {
        if (state?.devicePickupPermissionId) {
            dispatch(employeeContextActions.setEmployeeFilterDevicePickupPermissionId(state.devicePickupPermissionId))
        }
        if(brandId && context.currentBrand?.brandId !== parseInt(brandId)) {
            dispatch(selectBrandThunk(parseInt(brandId)));
        }

        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            if(parseInt(locationId) !== AllLocationsDto.locationId) {
                dispatch(viewAllDevicePickupPermissions({locationId: parseInt(locationId)}));
            }

            dispatch(selectLocationThunk(parseInt(locationId)));
            getEmployees();
        }
        else if(context.currentLocation?.locationId) {
            if(context.currentLocation.locationId !== AllLocationsDto.locationId) {
                dispatch(viewAllDevicePickupPermissions({locationId: context.currentLocation.locationId}));
            }

            getEmployees();
        }
    }, [brandId, context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, getEmployees, locationId, state?.devicePickupPermissionId]);
}

export const ViewEmployees = () => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const employeeContext = useAppSelector(employeeContextSelector);
    const devicePickupPermissionContext = useAppSelector(devicePickupPermissionContextSelector);
    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const currentUserContext = useCurrentUserContext();
    const styles = useStyles();
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('xs'));
    const {state} = useLocation() as LocationState;
    const [sortBy, setSortBy] = React.useState<EMPLOYEE_ORDER_BY_TYPES | undefined>(employeeContext.employeeFilters?.orderByString);
    const [order, setOrder] = React.useState<'asc' | 'desc'>(employeeContext.employeeFilters.isAsc ? 'asc' : 'desc');

    const sortedList = employeeContext.employeesList;
    const renderItemSize = 55;

    const ReportList : ReportOption[] = React.useMemo(() => {
        return [
            EMPLOYEE_DASHBOARD
        ];
    }, [])

    const [bulkEmployeeMap, setBulkEmployeeMap] = React.useState<{[empId: number] : boolean;}>({});
    const [showBulkMenu, setShowBulkMenu] = React.useState<boolean>(false);

    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
    const [successOpen, setSuccessOpen] = React.useState<boolean>(true);
    
    const resetBulkEmployee = React.useCallback(() => {
        setBulkEmployeeMap({});
        dispatch(getEmployeesByBrandRefresh({
            locationId: context.currentLocation?.locationId ?? -1,
            brandId: context.currentBrand?.brandId
        }));
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch])

    const allowDevicePickupPermissionEdit = React.useMemo(() => {
        return !SAMS_BRANDS.find(id => id === context.currentBrand?.brandId);
    }, [context.currentBrand?.brandId]);

    type searchCriteriaType = {
        filters:{
            nameOrID: string | undefined,
            kioskAccess: KioskGroupsType | undefined,
            portalAccess: string[]
            devicePickupPermissionId: number | undefined
        },
        status: EMPLOYEE_STATUS | undefined;
    }

    const DEFAULT_CRITERIA : searchCriteriaType = React.useMemo(() => {
        return {
            filters: {
                nameOrID: employeeContext.employeeFilters.nameOrId ?? '',
                kioskAccess: employeeContext.employeeFilters.kioskAccess,
                portalAccess: [],
                devicePickupPermissionId: employeeContext.employeeFilters.devicePickupPermissionId
            },
            status: employeeContext.employeeFilters.status
        }
    }, [employeeContext.employeeFilters.kioskAccess, employeeContext.employeeFilters.nameOrId, employeeContext.employeeFilters.status, employeeContext.employeeFilters.devicePickupPermissionId]);

    const [searchCriteria, setSearchCriteria] = React.useState<searchCriteriaType>(DEFAULT_CRITERIA);

    const kioskGroupOptions = React.useMemo(() => {
        const groups : Option[] = KIOSK_GROUPS.map((group) => {
            return {
                value: group,
                label: group
            }
        });

        return groups;
    }, []);

    const devicePickupPermissionOptions = React.useMemo(() => {
        const permissions : Option[] = devicePickupPermissionContext.permissions.map((perm) => {
            return {
                value: perm.devicePickupPermissionId,
                label: perm.permissionName
            }
        });

        return permissions;

    }, [devicePickupPermissionContext.permissions]);

    React.useEffect(() => {
        setSearchCriteria(DEFAULT_CRITERIA);
        setSortBy(employeeContext.employeeFilters.orderByString);
        setOrder(employeeContext.employeeFilters.isAsc ? 'asc' : 'desc');
    }, [DEFAULT_CRITERIA, employeeContext.employeeFilters.isAsc, employeeContext.employeeFilters.orderByString]);

    const StatusFilterValue = React.useMemo(() => {
        if(appViewContext.brandView) {
            return '';
        } else if(!searchCriteria.status) {
            return '';
        } else {
            return searchCriteria.status;
        }
    }, [appViewContext.brandView, searchCriteria.status]);

    const canEditEmployee = React.useMemo(() => {
        return !context.authorizationDto?.employee || !context.currentBrand?.employeeAPIEnabled;
    }, [context.authorizationDto?.employee, context.currentBrand?.employeeAPIEnabled]);

    const viewOnlyView = React.useMemo(() => {
        return currentUserContext.groups.isArcTechSupport || currentUserContext.groups.isSSOHQUser;
    }, [currentUserContext.groups.isArcTechSupport, currentUserContext.groups.isSSOHQUser]);

    React.useEffect(() => {
        setSearchCriteria(prevCriteria => ({
            ...prevCriteria,
            showAll: appViewContext.brandView
        }));
    }, [appViewContext.brandView]);

    const gridSize = React.useMemo(() => {
        let template: string = '1fr .5fr .5fr .5fr .5fr .5fr';
        if (isSmOrBelow) {
            template = '1fr .5fr';
        }
        if (!appViewContext.brandView && !viewOnlyView && canEditEmployee) { // bulk checkbox
            template = '.5fr ' + template;
        }

        return template;
    }, [isSmOrBelow, appViewContext.brandView, viewOnlyView, canEditEmployee])

    React.useEffect(() => {
        if(state) {
            const { successmessage } = state; // Read values passed on state

            if(successmessage) {
                setSuccessMessage(successmessage);
            }
        }
    }, [state]);

    React.useEffect(() => {
        return () => {
            dispatch(employeeContextActions.resetGetEmployees());
            dispatch(employeeContextActions.resetBulkAction());
            window.scrollTo(0, 0);
        }
    }, [dispatch]);
    
    const employeeStatus = React.useCallback((employee: EmployeeDto) => {
        return !appViewContext.brandView ?
                (employee.employeeLocations && context.currentLocation?.locationId &&
                    (employee.employeeLocations.some(location => (location.location.locationId === context.currentLocation?.locationId) && location.active))
                )
                    ? getLocalizedString('viewEmployees.active', 'ACTIVE') : getLocalizedString('viewEmployees.inactive', 'INACTIVE')
                :
                '';
    }, [appViewContext.brandView, context.currentLocation?.locationId])

    const RenderListItem: InfiniteScrollProps<EmployeeDto>['renderItem'] = React.useCallback((employee, index) => {
        return (
            employee &&
            <div className={'employee-row'}
                 key={employee.employeeId}
                 style={{
                    backgroundColor: (employee.employeeLocations.some(location => (location.location.locationId === context.currentLocation?.locationId) && location.active) || appViewContext.brandView) ? WHITE : LIGHTER_GREY,
                    gridTemplateColumns: gridSize
                 }}>
                {!appViewContext.brandView && !viewOnlyView && canEditEmployee &&
                <div className={'employee-cell'}>
                    <VanillaCheckbox
                        style={{backgroundColor: theme.palette.primary.main}}
                        onClick={() => {
                            if (!showBulkMenu) {
                                setShowBulkMenu(true);
                            }
                            if (employee.employeeId) {
                                if (bulkEmployeeMap[employee.employeeId]) {
                                    const updatedBulkList = {...bulkEmployeeMap};
                                    delete updatedBulkList[employee.employeeId];

                                    setBulkEmployeeMap(updatedBulkList);
                                } else {
                                    setBulkEmployeeMap({
                                        ...bulkEmployeeMap,
                                        [employee.employeeId]: !bulkEmployeeMap[employee.employeeId]
                                    });
                                }
                            }
                        }}
                        checked={!!bulkEmployeeMap[employee.employeeId ?? -1]}
                    />
                </div>
                }

                <div className={'employee-cell'} style={{fontSize: calculateResponsiveSizeWithMinMax('1.4', '18', '10')}}>{employee.firstName + ' ' + (employee.middleName ? employee.middleName + ' ' : '') + employee.lastName}</div>

                {!isSmOrBelow &&
                <div className={'employee-cell'}>{
                    employee.employeeGroups?.map((group) => {
                        if (group.kioskGroup) {
                            return group.groupName;
                        } else {
                            return null;
                        }
                    })}
                </div>
                }

                {!isSmOrBelow &&
                <div className={'employee-cell'}>{
                    employee.employeeGroups.some(group => !group.kioskGroup) ?
                        (employee.employeeGroups?.map((group) => {
                                if (!group.kioskGroup) {
                                    return group.groupName;
                                } else {
                                    return null;
                                }
                            })
                        )
                        :
                        'None'
                }
                </div>
                }

                {!isSmOrBelow && allowDevicePickupPermissionEdit && !appViewContext.brandView &&
                <div className={'employee-cell'}>{
                    hasFullPickupPermission(employee) ?
                        <div style={{padding: '0 5px 0 5px'}}>
                            {getLocalizedString('employee.devicePickupPermission.notRestricted', 'Not Restricted')}
                        </div>
                        :
                        employee.activeAtMultipleLocations && hasPickupPermission(employee) ?
                            context.currentLocation?.defaultDevicePickupPermission ?
                            <div className={'Link'}
                                 onClick={() => navigate(`/employees/permissions/edit/devicePickupPermissionId=${context.currentLocation?.defaultDevicePickupPermission?.devicePickupPermissionId}`)}>
                                {context.currentLocation.defaultDevicePickupPermission.permissionName}
                            </div>
                                :
                            <div style={{padding: '0 5px 0 5px'}}>
                                {getLocalizedString('default', 'Default')}
                            </div>
                            :
                            employee.devicePickupPermission ?
                                employee.devicePickupPermission.custom ?
                                    <div style={{padding: '0 5px 0 5px'}}>
                                        {getLocalizedString('custom', 'Custom')}
                                    </div>
                                    :
                                    viewOnlyView ?
                                        <div style={{padding: '0 5px 0 5px'}}>
                                            {employee.devicePickupPermission.permissionName}
                                        </div>
                                        :
                                        <div className={'Link'}
                                             onClick={() => navigate(`/employees/permissions/edit/devicePickupPermissionId=${employee.devicePickupPermission?.devicePickupPermissionId}`)}>
                                            {employee.devicePickupPermission.permissionName}
                                        </div>
                                :
                                <div style={{padding: '0 5px 0 5px'}}>
                                    {getLocalizedString('employee.devicePickupPermission.none', 'None')}
                                </div>
                }
                </div>
                }
                
                <div className={'employee-cell'} style={{overflow: 'unset', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <DropdownMenu
                        label={'...'}
                        isSmOrBelow={isSmOrBelow}
                        onChange={(option) => {
                            if(option.value !== '') {
                                dispatch(employeeContextActions.setViewPageContext({
                                    lastSelectedIdx: index
                                }));

                                dispatch(employeeContextActions.setSelectedEmployee(employee));
                                navigate(option.value);
                            }
                        }}
                        options={[
                            (!context.authorizationDto?.employee || canEditEmployee) ?
                            {value: `/employees/edit/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}/employeeId=${employee.employeeId}`, label: (currentUserContext.accessLevel >= getAccessLevel(employee) && (!context.authorizationDto?.employee || canEditEmployee)) ? getLocalizedString('edit', 'Edit') : getLocalizedString('view', 'View'), icon: () => <GroupOutlined/>}
                                :
                            {value: `/employees/edit/viewonly/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}/employeeId=${employee.employeeId}`, label: getLocalizedString('view', 'View'), icon: () => <GroupOutlined/>}
                            ,
                            ...ReportList.map(report => { return {value: `/reports/view/locationId=${context.currentLocation?.locationId}/lookerId=${report.lookerId}/employeeId=${employee.employeeId ?? ''}`, disabled: currentUserContext.accessLevel < getAccessLevel(employee), label: getLocalizedString('insight', 'Insights'), icon: () => <AssessmentOutlined/>}})
                        ]}
                    />

                </div>
                
                {!isSmOrBelow &&
                <div className={'employee-cell'} style={{overflow: 'unset', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <ARCBadge
                        label={employeeStatus(employee)}
                        status={employeeStatus(employee) === 'ACTIVE' ? 'on' : employeeStatus(employee) === 'INACTIVE' ? 'off' : 'standby'}
                        type={'status'}
                    >
                    </ARCBadge>
                </div>
                }
            </div>
        )
    }, [appViewContext.brandView, gridSize, viewOnlyView, canEditEmployee, theme.palette.primary.main, bulkEmployeeMap, isSmOrBelow, allowDevicePickupPermissionEdit, context.currentLocation?.defaultDevicePickupPermission, context.currentLocation?.locationId, context.authorizationDto?.employee, context.currentBrand?.brandId, currentUserContext.accessLevel, ReportList, employeeStatus, showBulkMenu, navigate, dispatch])
    
    const listRef = React.useRef<List | null>(null);
    
    const [showBackToTop, setShowBackToTop] = React.useState<boolean>(false);
    const handleOnScroll = (props: ListOnScrollProps) => {
        if (props.scrollOffset > renderItemSize && !showBackToTop) {
            setShowBackToTop(true);
        } else if (props.scrollOffset <= renderItemSize && showBackToTop) {
            setShowBackToTop(false);
        }
    };
    
    const [listHasScrollbar, setListHasScrollbar] = React.useState<boolean>(false);
    
    const handleItemsRendered = (props : ListOnItemsRenderedProps) => {
        let visibleStopCount = props.visibleStopIndex + 1;
        if (visibleStopCount !== listRef.current?.props?.itemCount) {
            setListHasScrollbar(true);
        } else {
            setListHasScrollbar(false)
        }
        if (!employeeContext.getEmployeesLoading && (listRef.current?.props?.itemCount ?? 0) > 0 && (visibleStopCount === listRef.current?.props?.itemCount)) {
            handleScrollToBottom()
        }
    };

    const handleScrollToBottom = () => {
        getEmployeeData()
    };

    const getEmployeeData = React.useCallback(() => {
        if (employeeContext.employeesListOffset >= 0) {
            dispatch(getEmployeesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    }, [dispatch, context.currentBrand?.brandId, context.currentLocation?.locationId, employeeContext.employeesListOffset])

    const searchFilter = React.useCallback((sortBy, order, newSearchCriteria?) => {
        let sc = newSearchCriteria ?? searchCriteria;
        let filterDto = {
            isAsc: order === 'asc',
            nameOrId: sc.filters.nameOrID,
            kioskAccess: sc.filters.kioskAccess,
            orderByString: sortBy,
            status: sc.status,
            devicePickupPermissionId: sc.filters.devicePickupPermissionId
        }
        if (!isFilterEqual(employeeContext.employeeFilters, filterDto)) {
            dispatch(employeeContextActions.setEmployeeFilter(filterDto));
            dispatch(getEmployeesByBrand({
                locationId: context.currentLocation?.locationId ?? -1,
                brandId: context.currentBrand?.brandId
            }));
        }
    },[searchCriteria, employeeContext.employeeFilters, dispatch, context.currentLocation?.locationId, context.currentBrand?.brandId])

    const EmployeeUpdate = React.useMemo(() => {
        let empLink;
        if(state?.editingFromEmployee) {
            empLink =
                <Link style={{fontWeight: 'bold', fontSize: calculatedFontSize('.8em', '1.2em'), textDecoration: 'none'}}
                      target={'_self'}
                      to={`/employees/edit/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}/employeeId=${state.editingFromEmployee.employeeId}`}>
                    {(state.editingFromEmployee.firstName + ' ' + state.editingFromEmployee.lastName)}
                </Link>
            const empText = ' ' + getLocalizedString('employee.saveSuccessful', 'activated successfully.');
            const empMessage = <span style={{marginLeft: '5px', fontSize: calculatedFontSize('.8em', '1.2em')}}>{empText}</span>

            return (
                <ServerMessage 
                    variant={'success'}
                    closable={{
                        isOpen: successOpen,
                        onClose: () => {setSuccessOpen(false)}
                    }}
                >
                    {empLink}
                    {empMessage}
                </ServerMessage>
            )
        }

        return null;
    }, [context.currentBrand?.brandId, context.currentLocation?.locationId, state?.editingFromEmployee, successOpen]);

    return (
    <AppWithFooter>
        <AppBar/>
        <CenteredDiv style={{flexGrow: 1}}>
            <SideBar/>

            <MainContentContainer style={{flexGrow: 1}}>
                {successMessage &&
                    <ServerMessage variant={'success'}>
                        <SuccessMessageSectionItem>
                            {successMessage}
                        </SuccessMessageSectionItem>
                    </ServerMessage>}

                {employeeContext.getEmployeesErrorMessage &&
                <ServerMessage variant={'error'}>
                    <ErrorMessageSectionItem>
                        {employeeContext.getEmployeesErrorMessage}
                    </ErrorMessageSectionItem>
                </ServerMessage>}

                {EmployeeUpdate}

                {employeeContext.bulkActionResponse &&
                <ServerMessage variant={'success'}>
                    <SuccessMessageSectionItem>
                        {employeeContext.bulkActionResponse.devicePickupPermissionId ?
                            <div style={{display: 'flex'}}>
                                {getLocalizedString('employee.devicePickupPermission.bulkAdd', (employeeContext.bulkActionResponse.updatedEmployeeCount ?? '') + ' users added to ', [`${employeeContext.bulkActionResponse.updatedEmployeeCount}`])}

                                <div className={'Link'} onClick={() => navigate(`/employees/permissions/edit/devicePickupPermissionId=${employeeContext.bulkActionResponse?.devicePickupPermissionId}`)}>
                                    {devicePickupPermissionContext.permissions.find(perm => perm.devicePickupPermissionId === employeeContext.bulkActionResponse?.devicePickupPermissionId)?.permissionName ?? 'group'}
                                </div>

                                {' ' + getLocalizedString('employee.devicePickupPermission.bulkAddSuccess', 'successfully')}
                            </div>
                            :
                            employeeContext.bulkActionResponse.message
                        }
                    </SuccessMessageSectionItem>
                </ServerMessage>
                }

                {employeeContext.bulkActionResponse?.duplicates &&
                <ServerMessage variant={'error'}>
                    <div>
                        <ErrorMessageSectionItem>
                            {getLocalizedString('viewEmployees.bulkAddLocation.failCount', 'Location access not granted for ' + employeeContext.bulkActionResponse?.duplicates.length + ' users because user records with the same name exist at selected locations:', [`${employeeContext.bulkActionResponse?.duplicates.length}`])}
                        </ErrorMessageSectionItem>

                        <ul>
                            {employeeContext.bulkActionResponse.duplicates.map((dup, idx) =>
                                <BulkErrorListItem key={idx}>
                                    <Link target={'_self'} to={`/employees/edit/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}/employeeId=${dup.employeeId}`}>
                                        {dup.employeeName}
                                    </Link>
                                    {' ' + getLocalizedString('viewEmployees.bulkAddLocation.duplicate1', 'matches') + ' '}
                                    <b>{dup.employeeName}</b>
                                    {getLocalizedString('viewEmployees.bulkAddLocation.duplicate2', ' at ' + dup.locationName, [dup.locationName])}
                                </BulkErrorListItem>
                            )}
                        </ul>

                        <ErrorMessageSectionItem>
                            {getLocalizedString('viewEmployees.bulkAddLocation.changeName', 'To grant access to selected locations, add a Middle Initial to differentiate the users.')}
                        </ErrorMessageSectionItem>
                    </div>
                </ServerMessage>
                }

                <FormTitle variant='h5' className={'o-heading--xl'}>
                    {getLocalizedString('viewEmployees.title', 'User Roster (' + (employeeContext.totalFilteredEmployees ?? '0') + ')', [`${employeeContext.totalFilteredEmployees ?? '0'}`])}
                </FormTitle>

                <div className={styles.topActions}>

                    {
                    !viewOnlyView &&
                    <ARCButton
                        size={'md'}
                        icon={'add-user'}
                        fill={'filled'}
                        variant={'primary'}
                        disabled={!canEditEmployee}
                        label={getLocalizedString('viewEmployees.addEmployee', 'Activate New User')}
                        onClick={() => {
                            navigate(`/employees/edit/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)
                        }}
                    >
                    </ARCButton>
                    }

                    {(!appViewContext.brandView && !viewOnlyView && canEditEmployee) &&
                        <Button onClick={() => setShowBulkMenu(!showBulkMenu)}
                                style={{color: showBulkMenu ? BLUE : BLACK, marginLeft: '45px'}}
                        >
                            {showBulkMenu ?
                                <ArrowDropUp/>
                                :
                                <ArrowDropDown/>
                            }
                            <span className={'sub-header primary'}>
                                {getLocalizedString('viewEmployees.createBulkTask', 'Create Bulk Task')}
                            </span>
                        </Button>
                    }
                </div>

                {!appViewContext.brandView && !viewOnlyView && showBulkMenu &&
                <BulkEmployeeAction bulkEmployeeList={bulkEmployeeMap} resetBulkList={resetBulkEmployee} devicePickupPermissionList={devicePickupPermissionContext.permissions}/>
                }

                <ScalableForm 
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <ScalableFormControl>
                        <Container className={styles.input_root}>
                            <ScalableGrid container>

                                <CenterGrid style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}} item>
                                    <span>
                                        {getLocalizedString('viewEmployees.location', 'Location Name')}
                                    </span>

                                    <div className={styles.input_div}>
                                        <LocationSelect showAllLocations={true}/>
                                        <CancelIconContainer/>
                                    </div>
                                </CenterGrid>

                                <CenterGrid style={{alignItems: 'flex-end'}} item>
                                    <div className={styles.input_div}>
                                        <TextField
                                            placeholder={getLocalizedString('viewEmployees.nameOrEmployeeNumber', 'Name or ' + (context.currentBrand?.badgeValidation ? 'Badge ID' : 'Emp ID'), [context.currentBrand?.badgeValidation ? 'Badge ID' : 'Emp ID'])}
                                            InputProps={{
                                                classes: { input: styles.input_placeholder},
                                                disableUnderline: true,
                                                endAdornment:
                                                    <InputAdornment style={{marginRight: '4%', cursor: 'pointer'}} position="end" onClick={() => {searchFilter(sortBy, order)}}>
                                                        <Search style={{
                                                            color: DARK_GREY, 
                                                            width: calculateResponsiveSizeWithMinMax('3', '24', '10'),
                                                            height: calculateResponsiveSizeWithMinMax('3', '24', '10')
                                                        }}/>
                                                    </InputAdornment>
                                            }}
                                            fullWidth
                                            autoComplete={"search"}
                                            id="viewuser-search-name"
                                            className={styles.input_text}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    searchFilter(sortBy, order);
                                                }
                                            }}
                                            onChange={(event) => {setSearchCriteria({...searchCriteria, filters: {...searchCriteria.filters, nameOrID: event.target.value}});}}
                                            value={searchCriteria.filters.nameOrID}
                                        />

                                        <CancelIconContainer>
                                            <CancelIcon style={{display: searchCriteria.filters.nameOrID ? 'inherit' : 'none'}} onClick={() => {
                                                const newSearchCriteria = {
                                                    ...searchCriteria,
                                                    filters: {
                                                      ...searchCriteria.filters,
                                                      nameOrID: ''
                                                    }
                                                }
                                                setSearchCriteria(newSearchCriteria);
                                                searchFilter(sortBy, order, newSearchCriteria)
                                            }}>
                                            </CancelIcon>
                                        </CancelIconContainer>

                                    </div>
                                </CenterGrid>

                                <CenterGrid style={{alignItems: 'flex-end'}} item>
                                    <HiddenOnMobileDiv>
                                        <div className={styles.input_div}>
                                            <FormControl>
                                                <ColorSelect
                                                    id="viewuser-search-kiosk-access"
                                                    variant="outline"
                                                    className={styles.input_text}
                                                    label={searchCriteria.filters.kioskAccess ? searchCriteria.filters.kioskAccess : getLocalizedString('viewEmployees.kioskGroup', 'Kiosk Role')}
                                                    value={searchCriteria.filters.kioskAccess}
                                                    options={kioskGroupOptions}
                                                    onChange={(option) => {
                                                        const newSearchCriteria = {
                                                            ...searchCriteria,
                                                            filters: {
                                                              ...searchCriteria.filters,
                                                              kioskAccess: option.value !== '' ? option.value as KioskGroupsType | undefined : undefined
                                                            }
                                                        }
                                                        setSearchCriteria(newSearchCriteria);
                                                        searchFilter(sortBy, order, newSearchCriteria)
                                                    }
                                                    }
                                                />

                                            </FormControl>

                                            <CancelIconContainer>
                                                <CancelIcon style={{display: searchCriteria.filters.kioskAccess ? 'inherit' : 'none'}} onClick={() => {
                                                    const newSearchCriteria = {
                                                        ...searchCriteria,
                                                        filters: {
                                                          ...searchCriteria.filters,
                                                          kioskAccess: undefined
                                                        }
                                                    }
                                                    setSearchCriteria(newSearchCriteria);
                                                    searchFilter(sortBy, order, newSearchCriteria)
                                                }}>
                                                </CancelIcon>
                                            </CancelIconContainer>
                                        </div>
                                    </HiddenOnMobileDiv>
                                </CenterGrid>

                                {!appViewContext.brandView && allowDevicePickupPermissionEdit && devicePickupPermissionContext.permissions.length > 0 &&
                                <CenterGrid style={{alignItems: 'flex-end'}} item>
                                    <HiddenOnMobileDiv>
                                        <div className={styles.input_div}>
                                            <FormControl>
                                                <ColorSelect
                                                    id="viewuser-pickup-permissions"
                                                    variant="outline"
                                                    className={styles.input_text}
                                                    label={getLocalizedString('viewEmployees.devicePickupPermission', 'Device Group')}
                                                    value={searchCriteria.filters.devicePickupPermissionId}
                                                    options={devicePickupPermissionOptions}
                                                    onChange={(option) => {
                                                        const newSearchCriteria = {
                                                            ...searchCriteria,
                                                            filters: {
                                                              ...searchCriteria.filters,
                                                              devicePickupPermissionId: option.value !== '' ? parseInt(option.value) ?? undefined : undefined
                                                            }
                                                        }
                                                        setSearchCriteria(newSearchCriteria);
                                                        searchFilter(sortBy, order, newSearchCriteria)
                                                    }
                                                    }
                                                />

                                            </FormControl>

                                            <CancelIconContainer>
                                                <CancelIcon
                                                    style={{display: searchCriteria.filters.devicePickupPermissionId ? 'inherit' : 'none'}}
                                                    onClick={() => {
                                                        const newSearchCriteria = {
                                                            ...searchCriteria,
                                                            filters: {
                                                              ...searchCriteria.filters,
                                                              devicePickupPermissionId: undefined
                                                            }
                                                        }
                                                        setSearchCriteria(newSearchCriteria);
                                                        searchFilter(sortBy, order, newSearchCriteria)
                                                    }}>
                                                </CancelIcon>
                                            </CancelIconContainer>

                                        </div>
                                    </HiddenOnMobileDiv>
                                </CenterGrid>
                                }

                                <CenterGrid style={{alignItems: 'flex-end'}} item>
                                    <HiddenOnMobileDiv>
                                        <div className={styles.input_div}>
                                            <FormControl>
                                                <ColorSelect
                                                        disabled={appViewContext.brandView}
                                                        id="viewuser-search-status"
                                                        variant="outline"
                                                        className={styles.input_text}
                                                        style={{outline: 'none'}}
                                                        label={getLocalizedString('viewEmployees.status.all', 'All')}
                                                        value={StatusFilterValue}
                                                        options={[
                                                            {value: '', label: getLocalizedString('viewEmployees.status.all', 'All')},
                                                            {value: 'active', label: getLocalizedString('viewEmployees.status.active', 'Active')},
                                                            {value: 'inactive', label: getLocalizedString('viewEmployees.status.inactive', 'Inactive')}
                                                        ]}
                                                        onChange={(option) => {
                                                            const newSearchCriteria = {
                                                                ...searchCriteria,
                                                                filters: {
                                                                  ...searchCriteria.filters,
                                                                },
                                                                status: option.value !== '' ? option.value as EMPLOYEE_STATUS : undefined
                                                            }
                                                            setSearchCriteria(newSearchCriteria);
                                                            searchFilter(sortBy, order, newSearchCriteria);
                                                        }}
                                                />

                                            </FormControl>

                                            <CancelIconContainer>
                                                <CancelIcon style={{display: (searchCriteria.status) ? 'inherit' : 'none'}} onClick={() => {
                                                    const newSearchCriteria = {
                                                        ...searchCriteria,
                                                        filters: {
                                                          ...searchCriteria.filters,
                                                        },
                                                        status: undefined
                                                    }
                                                    setSearchCriteria(newSearchCriteria);
                                                    searchFilter(sortBy, order, newSearchCriteria)
                                                }}>
                                                </CancelIcon>
                                            </CancelIconContainer>

                                        </div>
                                    </HiddenOnMobileDiv>
                                </CenterGrid>
                            </ScalableGrid>
                        </Container>
                    </ScalableFormControl>
                </ScalableForm>

                <div className={'employee-grid-table'} id={'employee-table'}>
                    <div className={'employee-header-div'} style={{borderBottom: '2px solid black', gridTemplateColumns: gridSize, paddingRight: listHasScrollbar ? '10px' : '0px'}}>
                        {!appViewContext.brandView && !viewOnlyView && canEditEmployee &&
                        <div className={'employee-header employee-cell'}>
                            <HeaderTableContent style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                <Checkbox
                                    indeterminate={Object.keys(bulkEmployeeMap).length > 0 && Object.keys(bulkEmployeeMap).length < (sortedList?.length ?? 0)}
                                    indeterminateIcon={<UndeterminedCheckboxIcon/>}
                                    onClick={() => {
                                        if (!showBulkMenu) {
                                            setShowBulkMenu(true);
                                        }

                                        if(Object.keys(bulkEmployeeMap).length > 0) {
                                            setBulkEmployeeMap({});
                                        } else {
                                            const updatedBulkList = {...bulkEmployeeMap};

                                            sortedList?.forEach(e => {
                                                if(e.employeeId) {
                                                    updatedBulkList[e.employeeId] = true;
                                                }
                                            });

                                            setBulkEmployeeMap(updatedBulkList);
                                        }
                                    }}
                                    checked={(sortedList?.length ?? 0) > 0 && Object.keys(bulkEmployeeMap).length === (sortedList?.length ?? 0)}
                                    size={'small'}
                                    color="primary"/>
                                    {getLocalizedString('viewEmployees.bulkTask.selectAll', 'All')}
                            </HeaderTableContent>
                        </div>
                        }

                        <div className={'employee-header employee-cell'} onClick={() => {
                            const newOrder = (order === 'asc' && sortBy === EMPLOYEE_ORDER_BY_TYPES.NAME ? 'desc' : 'asc');
                            const newSortBy = (EMPLOYEE_ORDER_BY_TYPES.NAME);
                            setOrder(newOrder);
                            setSortBy(newSortBy);
                            searchFilter(newSortBy, newOrder);
                        }}>
                            <HeaderTableContent style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                {getLocalizedString('viewEmployees.header.name', 'Name')}
                                {sortBy === EMPLOYEE_ORDER_BY_TYPES.NAME ? order === 'asc' ? <ArrowUpward/> : <ArrowDownward/> : <ArrowUpward style={{visibility: 'hidden'}}/>}
                            </HeaderTableContent>
                        </div>

                        {!isSmOrBelow &&
                        <div className={'employee-header employee-cell'}
                             onClick={() => {
                                 const newOrder = (order === 'asc' && sortBy === EMPLOYEE_ORDER_BY_TYPES.KIOSK_ROLE ? 'desc' : 'asc');
                                 const newSortBy = (EMPLOYEE_ORDER_BY_TYPES.KIOSK_ROLE);
                                 setOrder(newOrder);
                                 setSortBy(newSortBy);
                                 searchFilter(newSortBy, newOrder);
                             }}>
                            <HeaderTableContent style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                {getLocalizedString('viewEmployees.header.kioskGroup', 'Kiosk Role')}
                                {sortBy === EMPLOYEE_ORDER_BY_TYPES.KIOSK_ROLE ? order === 'asc' ? <ArrowUpward/> : <ArrowDownward/> :
                                    <ArrowUpward style={{visibility: 'hidden'}}/>}
                            </HeaderTableContent>
                        </div>
                        }

                        {!isSmOrBelow &&
                        <div className={'employee-header employee-cell'} onClick={() => {
                            const newOrder = (order === 'asc' && sortBy === EMPLOYEE_ORDER_BY_TYPES.ClIENT_PORTAL_ROLE ? 'desc' : 'asc');
                            const newSortBy = (EMPLOYEE_ORDER_BY_TYPES.ClIENT_PORTAL_ROLE);
                            setOrder(newOrder);
                            setSortBy(newSortBy);
                            searchFilter(newSortBy, newOrder);
                        }}>
                            <HeaderTableContent style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                {getLocalizedString('viewEmployees.header.portalGroup', 'Client Portal Role')}
                                {sortBy === EMPLOYEE_ORDER_BY_TYPES.ClIENT_PORTAL_ROLE ? order === 'asc' ? <ArrowUpward/> : <ArrowDownward/> :
                                    <ArrowUpward style={{visibility: 'hidden'}}/>}
                            </HeaderTableContent>
                        </div>
                        }


                        {!isSmOrBelow && allowDevicePickupPermissionEdit && !appViewContext.brandView &&
                        <div className={'employee-header employee-cell'} onClick={() => {}}>
                            <HeaderTableNonSortable style={{fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                {getLocalizedString('viewEmployees.header.devicePickupPermission', 'Device Group')}
                            </HeaderTableNonSortable>
                        </div>
                        }
                        <div className={'employee-header employee-cell'}>
                            <HeaderTableNonSortable style={{textAlign: 'center', fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                {getLocalizedString('viewEmployees.header.actions', 'Actions')}
                            </HeaderTableNonSortable>
                        </div>
                        {!isSmOrBelow &&
                        <div className={'employee-header employee-cell'}>
                            {appViewContext.brandView ?
                                <HeaderTableNonSortableDisabled style={{textAlign: 'center', fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                    {getLocalizedString('viewEmployees.header.status', 'Status')}
                                </HeaderTableNonSortableDisabled>
                                :
                                <HeaderTableNonSortable style={{textAlign: 'center', fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')}}>
                                    {getLocalizedString('viewEmployees.header.status', 'Status')}
                                </HeaderTableNonSortable>
                            }
                        </div>
                        }
                    </div>

                    <div className={'employee-body-div'}>
                        <InfiniteScrollList<EmployeeDto>
                            initialHeight={renderItemSize}
                            data={sortedList}
                            renderItem={RenderListItem}
                            onScroll={handleOnScroll}
                            padding={0}
                            onItemsRendered={handleItemsRendered}
                            listRef={listRef}
                        />
                        {
                            employeeContext.getEmployeesLoading &&
                                <div className={'employee-loading-div'}>
                                    <CircularProgress style={{marginTop: '10vh'}} size={100}/>
                                </div>
                        }

                    </div>
                </div>
                {
                    showBackToTop ?
                    <div className={'employee-to-top-button'} onClick={() => {
                        listRef.current?.scrollTo(0)
                    }}>
                        <ArrowUpward/>
                        {getLocalizedString('viewEmployees.toTop', 'TO TOP')}
                    </div>
                        :
                    undefined
                }
            </MainContentContainer>
        </CenteredDiv>
    </AppWithFooter>
    );
};

const useStyles = makeStyles((theme) => ({
    form_title: {
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: 550
    },
    input_root: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        margin: '0 0 10px 0',
        padding: 0,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            maxWidth: 'unset'
        },
    },
    input_div: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: '1.3vw'
    },
    input_label: {
        position: 'unset',
        fontWeight: 500,
        fontSize: calculateResponsiveSizeWithMinMax('1.6', '20', '10')
    },
    input_text: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '5px',
        border: '2px solid',
        backgroundColor: WHITE,
        borderRadius: '4px',
        borderColor: LIGHTER_GREY,
        width: calculateResponsiveSizeWithMinMax('30', '240', '200'),
        fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
        minHeight: '40px',
        paddingLeft: '10px',
        cursor: 'pointer'
    },
    input_placeholder: {
        fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
        '&::placeholder': {
            opacity: 1,
        }
    },
    topActions: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        marginBottom: '10px',
        flexDirection: 'row',
        alignItems: 'center',
    },
    topAction: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        margin: '10px 10% 10px 0',
        color: BLACK,
        fontSize: calculateResponsiveSizeWithMinMax('3', '24', '10'),
        fontWeight: 500,
        textDecoration: 'none',
        textTransform: 'unset',
        [`${theme.breakpoints.down('md')}`]: {
            margin: '10px 5% 10px 5%',
        },
    },
    topActionIcon: {
      marginRight: '20px'
    },
    buttonDiv: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-evenly'
    },
    button: {
        marginLeft: '60%',
        color: 'white',
        backgroundColor: RED,
        width: '20%',
        borderRadius: '15px',
        marginTop: theme.spacing(4),
    },
    all_checkbox: {
        fontSize: 'min(3vw, 1.5vh)',
        width: 'min(3vw, 1.5vh)',
        height: 'min(3vw, 1.5vh)',
        '&.Mui-checked': {
            fontSize: 'min(3vw, 1.5vh)',
            width: 'min(3vw, 1.5vh)',
            height: 'min(3vw, 1.5vh)',
        }
    }
}));

const CenterGrid = styled(Grid)({
   display: 'flex',
   flexWrap: 'nowrap',
   justifyContent: 'center',
   alignItems: 'center',
   marginBottom: '10px'
});

const HeaderTableContent = styled(Typography)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    fontSize: calculatedFontSize('1em', '1.45em'),
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('.75em', '1.25em'),
    }
}));

const HeaderTableNonSortable = styled(Typography) (({theme}) => ({
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontFamily: "Proxima Nova",
    fontSize: calculatedFontSize('1em', '1.45em'),
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('.75em', '1.25em'),
    }
}));


const HeaderTableNonSortableDisabled = styled(Typography) (({theme}) => ({
    color: GREY,
    opacity: 0.5,
    fontWeight: 'bold',
    fontFamily: "Proxima Nova",
    fontSize: calculatedFontSize('1em', '1.45em'),
    [`${theme.breakpoints.down('xs')}`]: {
        fontSize: calculatedFontSize('.75em', '1.25em'),
    }
}));

const BulkErrorListItem = styled('li')({
    width: '100%',
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: DARK_GREY
});
