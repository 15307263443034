import {DeviceDto} from "../../../../dtos/Device";
import * as React from "react";
import {useAppSelector} from "../../../../store/hooks";
import {deviceTypeSelector} from "../../deviceTypeSlice";
import {Dispatch, SetStateAction} from "react";
import {CreateDeviceChildComponentNames} from "../../pages/CreateDevice";
import {NextButton} from "../../../ui-components/StyledComponents";
import ColorSelect, {Option} from "../../../ui-components/ColorSelect";
import {getLocalizedString} from "../../../../util/Localization";

type DeviceTypeProps = {
    formDevice: DeviceDto,
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    goForward: (name: string) => void,
}

export const DeviceType:React.ComponentType<DeviceTypeProps> = (props) => {
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {formDevice, 
            setFormDevice, 
            goForward} = props;

    const deviceTypeOptions = React.useMemo(() => {
        const options: Option[] = deviceTypeContext.deviceTypes.map(deviceType => {
            return {
                value: deviceType.deviceTypeId,
                label: deviceType.deviceTypeName
            }
        });

        return [{value: undefined, label: getLocalizedString('createDevice.selectDeviceType', 'Select Device Type')}, ...options];
    }, [deviceTypeContext.deviceTypes]);
    
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (formDevice.deviceType) {
                    goForward(CreateDeviceChildComponentNames.deviceIdNumber)
                }
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [formDevice.deviceType, goForward]);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className={'createdevice-device-type-title-div'}>
                <div className={'createdevice-device-type-title'}>
                    {getLocalizedString('createDevice.deviceType', 'Device Type')}
                    <span className={'createdevice-red-star'}>
                        {'*'}
                    </span>
                </div>
            </div>
            <div className={'editdevice-input-div'}>
                <ColorSelect
                    variant="outline"
                    className={'createdevice-device-type-select'}
                    value={formDevice.deviceType?.deviceTypeId}
                    options={deviceTypeOptions}
                    onChange={(option) => {
                        setFormDevice({
                            ...formDevice,
                            deviceType: option.value !== '' ? deviceTypeContext.deviceTypes.find(dType => dType.deviceTypeId === option.value) : undefined
                        });
                    }}
                />
            </div>

            <NextButton
                id="device-type-next"
                variant='contained'
                disabled={!formDevice.deviceType}
                onClick={() => {
                    goForward(CreateDeviceChildComponentNames.deviceIdNumber)
                }}
            >
                {getLocalizedString('next', 'Next')}
            </NextButton>
        </div>
    )
}

