import React from "react";
import IMPORT_IMAGE from "../../../assets/img/Import_Image.png"
import {FormTitle} from "../StyledComponents";
import {LocationSelect} from "../LocationSelect";
import "../css/ImportComponent.css"
import {appContextSelector} from "../../app-context/appContextSlice";
import {useAppSelector} from "../../../store/hooks";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../design-system/ARCButton";
interface ImportLandingProps {
    isDeviceImport: boolean,
    getPreviousStatusAndNavigateToUploadFile: () => void,
}

export const ImportLanding: React.FC<ImportLandingProps> = ({ isDeviceImport, getPreviousStatusAndNavigateToUploadFile }) => {
    const context = useAppSelector(appContextSelector);
    const hasLocation = React.useMemo(() => {
        return !(!context.currentLocation?.locationId || context.currentLocation?.locationId === -1)
    }, [context.currentLocation?.locationId])
     
    return (
        <>
            <div className={'importlanding-container-div'}>
                <div className={'importlanding-image-div'}>
                    <img src={IMPORT_IMAGE} alt={'Import Icon'}/>
                </div>
                <div className={'importlanding-main-div'}>
                    <FormTitle variant='h5' className={'o-heading--xl'}>
                        {getLocalizedString('import.title', 'Import ' + (isDeviceImport ? 'Devices' : 'Users') + ' in Bulk', [`${isDeviceImport ? getLocalizedString('device.plural', 'Devices') : getLocalizedString('employee.plural', 'Users')}`])}
                    </FormTitle>
                    <div className={'importlanding-text'} style={{marginTop: '16px'}}>
                        {getLocalizedString('import.singleFile', 'Upload a single file to easily add and update multiple ' + (isDeviceImport ? 'devices' : 'users') + ' at once.', [`${(isDeviceImport ? getLocalizedString('device.plural', 'devices').toLowerCase() : getLocalizedString('employee.plural', 'users').toLowerCase())}`])}
                    </div>
                    {
                        !hasLocation ?
                        <div className={'importlanding-text'} style={{marginTop: '16px'}}>
                            {getLocalizedString('import.getStarted', 'To get started, first select a location to import ' + (isDeviceImport ? 'devices' : 'users') + ' to.', [`${(isDeviceImport ? getLocalizedString('device.plural', 'devices').toLowerCase() : getLocalizedString('employee.plural', 'users').toLowerCase())}`])}
                        </div>
                        :
                        <div className={'importlanding-text'} style={{marginTop: '16px'}}>
                            {getLocalizedString('import.clickGetStarted', 'Click Get Started to begin the process.')}
                        </div>
                        
                    }
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '16px'}}>
                        <span>
                            {getLocalizedString('import.location.label', 'Location Name')}
                        </span>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <LocationSelect showAllLocations={false} removeSerialIsDevice={true} placeHolder={getLocalizedString('import.location.select', 'Select Location')}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', marginTop: '32px'}}>
                        <ARCButton
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            disabled={!hasLocation}
                            onClick={() => {
                                getPreviousStatusAndNavigateToUploadFile()
                            }}
                            label={getLocalizedString('import.getStarted.label', 'Get Started')}
                        >
                        </ARCButton>
                    </div>
                </div>
            </div>
        </>
    )
}
