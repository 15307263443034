import {DeviceTypeDto} from "./DeviceType";

export const DEV_STATUS_ACTIVE = 'Active';
export const DEV_STATUS_PENDING = 'PendingActivation';
export const DEV_STATUS_INACTIVE = 'Deactivated';

export const DeviceValidationKeys = {
    deviceTag: 'Device Tag',
    deviceIdNumber: 'Device ID'
}

export type DEVICE_STATUS = typeof DEV_STATUS_ACTIVE | typeof DEV_STATUS_PENDING | typeof DEV_STATUS_INACTIVE;

export interface DeviceDto {
    deviceId: number | undefined;
    locationId: number | undefined;
    deviceTag: string;
    deviceIdNumber: string;
    status: DEVICE_STATUS;
    deviceType: DeviceTypeDto | undefined;
    inactiveRmaReasons?: string;
    deactivationDate?: string;
    deactivationUser?: string;
    inactiveReason: InactiveReasonType;
    inactiveReasonDetail: string;
    deviceIssues?: DeviceIssueDto[]
    deviceHistoryList?: DeviceHistoryDto[];
}

export interface DeviceHistoryDto {
    deviceHistoryId: number;
    deviceId: number;
    changeDateTime: string;
    reason: string;
    changeUserName: string;
}

export type DeviceStatDto = {
    lastPickup: string,
    lastReturn: string,
    recentIssues: string[]
}

export interface DeviceIssueDto {
    brandDeviceIssueId: number,
    deviceIssueType: string,
    deviceIssueOrder: number
}

export enum INACTIVE_REASONS {
    INACTIVE_REASON_NONFUNCTIONAL = 'Nonfunctional (RMA)',
    INACTIVE_REASON_MISSING = 'Missing/Lost',
    INACTIVE_REASON_OTHER = 'Other'
}

export enum DEVICE_ORDER_BY_TYPES {
    SERIAL_NUMBER = 'deviceTag',
    DEVICE_ID = 'deviceIdNumber',
    DEVICE_TYPE = 'deviceType'
}

export type DeviceFilterDto = {
    isAsc: boolean,
    deviceTag: string | undefined,
    deviceIdNumber: string | undefined,
    orderByString: DEVICE_ORDER_BY_TYPES | undefined,
    status: DEVICE_STATUS | undefined,
}

export type DeviceFieldToSave = {
    isDeviceId: boolean | undefined,
    isDeviceTag: boolean | undefined, 
    isDeviceType: boolean | undefined
}

export type ExistingDeviceDto = {
    sameTagIdLocation: boolean | undefined,
    sameTagIdDifLocation: boolean | undefined,
    sameTagDifIdSameLocation: boolean | undefined,
    sameTagDifIdDifLocation: boolean | undefined,
    sameIdDifTagSameLocation: boolean | undefined,
    sameIdDifTagDifLocation: boolean | undefined,
    sameTagIdOnDifferentDevices: boolean | undefined,
    deviceDto: DeviceDto | undefined,
    locationName: string | undefined
}

export function existingDeviceAtDiffLocation(dev: ExistingDeviceDto | undefined) {
    return dev && (dev.sameTagIdDifLocation || dev.sameTagDifIdDifLocation || dev.sameIdDifTagDifLocation);
}

export const isFilterEqual = (f1: DeviceFilterDto, f2: DeviceFilterDto) => {
    let isEqual: boolean;
    isEqual = JSON.stringify(f1) === JSON.stringify(f2);
    return isEqual;
}

export type InactiveReasonType = INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL | INACTIVE_REASONS.INACTIVE_REASON_MISSING | INACTIVE_REASONS.INACTIVE_REASON_OTHER | '';


export const isActive = (device: DeviceDto) => {
    return device.status && device.status === DEV_STATUS_ACTIVE;
}

export const isPending = (device: DeviceDto) => {
    return device.status && device.status === DEV_STATUS_PENDING;
}

export const isInactive = (device: DeviceDto) => {
    return device.status && device.status === DEV_STATUS_INACTIVE;
}

export const getStatusDisplay = (device: DeviceDto) => {
    switch (device.status) {
        case DEV_STATUS_ACTIVE:
            return 'ACTIVE';
        case DEV_STATUS_PENDING:
            return 'PENDING ACTIVATION';
        case DEV_STATUS_INACTIVE:
            return 'DEACTIVATED';
        default:
            return '';
    }
}

/**
* ARCCP-602: Device ID Validation
*   -CIS Provided means device_id_length is present
*   -Brand Provided means device_id_length_min is present, device_id_length_max is optional
*   -Both means Brand has a length AND a min/max present
*
*   -CIS Error means value doesn't match length
*   -Brand Error means value falls outside min/max
*
* 1. CIS Provided ONLY -> Show CIS Error
*
* 2. Brand Provided ONLY -> Show Brand Error
*
* 3. Both
*    a. serial_is_device = 'T'
*      i. Brand Provided has error -> Show Brand Error
*      ii. CIS Provided has error -> ignore
*
*    b. serial_is_device = 'F'
*      i. Both have errors -> Show Brand Error  
*      ii. Brand Provided only has error -> ignore
*      iii. CIS Provided only has error -> ignore
* 
* */
export const getDeviceIdNumberValidation = (minLength: number, length: number, serialIsDevice: boolean | undefined, brandProvidedError: string | undefined, cisProvidedError: string | undefined) => {
    const isOnlyBrandProvided = minLength !== null && length === null;
    const isOnlyCisProvided = minLength === null && length !== null;
    if (isOnlyCisProvided) {
        return cisProvidedError ?? '';
    } else if (isOnlyBrandProvided) {
        return brandProvidedError ?? '';
    } else {
        if (serialIsDevice) {
            return brandProvidedError ?? '';
        } else {
            if (brandProvidedError && cisProvidedError) {
                return brandProvidedError ?? '';
            }
        }
    }
    return '';
}
