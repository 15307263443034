import * as React from 'react';
import {AppWithFooter} from '../../../App';
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {
    CancelButton,
    CenteredDiv,
    FormTitle,
    InputDiv,
    InputViewOnlyValue,
    MainContentContainer,
    ResponsiveInputLabel,
    RowFormControl,
    SectionHeaderLg
} from '../../ui-components/StyledComponents';
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import {employeeContextSelector, getEmployeeForEdit} from "../employeeSlice";
import {useAppViewContext} from "../../app-context/AppViewContainer";
import {Typography} from "@material-ui/core";
import {selectBrandThunk} from "../../app-context/thunks/SelectBrandThunk";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {getLocalizedString} from "../../../util/Localization";

const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, brandId, employeeId} = useParams();

    React.useEffect(() => {
        if(brandId && context.currentBrand?.brandId !== parseInt(brandId)) {
            dispatch(selectBrandThunk(parseInt(brandId)));
        }

        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(employeeId) {
                dispatch(getEmployeeForEdit({locationId: parseInt(locationId), brandId: brandId ? parseInt(brandId) : context.currentBrand?.brandId, employeeId: parseInt(employeeId)}));
            }
        }
        else if(employeeId && context.currentLocation?.locationId) {
            dispatch(getEmployeeForEdit({locationId: context.currentLocation.locationId, brandId: brandId ? parseInt(brandId) : context.currentBrand?.brandId, employeeId: parseInt(employeeId)}));
        }

    }, [brandId, context.currentBrand?.brandId, context.currentLocation?.locationId, dispatch, employeeId, locationId]);
}

export const EditEmployeeViewOnly = () => {
    useAutoSelectLocationByUrl();

    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const appViewContext = useAppViewContext();
    const employeeContext = useAppSelector(employeeContextSelector);
    const stateEmployee = employeeContext.selectedEmployee;

    return (
        <AppWithFooter>
            <AppBar/>
            <CenteredDiv>
                <SideBar/>

                <MainContentContainer style={{alignItems: 'unset'}}>

                    <FormTitle variant='h5'>{getLocalizedString('employee.view', 'View User')}</FormTitle>

                    {!appViewContext.brandView &&
                        <RowFormControl style={{marginBottom: '2vh'}}>
                            <ResponsiveInputLabel>
                                {getLocalizedString('employee.view.status', 'User Status') + ':'}
                            </ResponsiveInputLabel>

                            <InputDiv>
                                <InputViewOnlyValue>
                                    {stateEmployee?.employeeLocations.find(l => l.location.locationId === context.currentLocation?.locationId && l.active) ? getLocalizedString('employee.view.status.active', 'Active') : getLocalizedString('employee.view.status.inactive', 'Inactive')}
                                </InputViewOnlyValue>
                            </InputDiv>
                        </RowFormControl>
                    }

                    <SectionHeaderLg style={{marginTop: '2vh'}}>{getLocalizedString('employee.view.info', 'User Details')}</SectionHeaderLg>

                    <RowFormControl>
                        <ResponsiveInputLabel>
                            {getLocalizedString('employee.firstName', 'First Name') + ':'}
                        </ResponsiveInputLabel>

                        <InputDiv>
                            <InputViewOnlyValue>
                                {stateEmployee?.firstName ?? ''}
                            </InputViewOnlyValue>
                        </InputDiv>
                    </RowFormControl>

                    <RowFormControl>
                        <ResponsiveInputLabel>
                            {getLocalizedString('employee.lastName', 'Last Name') + ':'}
                        </ResponsiveInputLabel>

                        <InputDiv>
                            <InputViewOnlyValue>
                                {stateEmployee?.lastName ?? ''}
                            </InputViewOnlyValue>
                        </InputDiv>
                    </RowFormControl>


                    <SectionHeaderLg>{getLocalizedString('employee.employeeGroups', 'System Access')}</SectionHeaderLg>

                    <RowFormControl>
                        <ResponsiveInputLabel>
                            {getLocalizedString('employee.kioskGroup', 'Kiosk Access') + ':'}
                        </ResponsiveInputLabel>

                        <InputDiv>
                            <InputViewOnlyValue>
                                {stateEmployee?.employeeGroups.find(group => group.kioskGroup)?.groupName ?? ''}
                            </InputViewOnlyValue>
                        </InputDiv>
                    </RowFormControl>

                    <RowFormControl>
                        <ResponsiveInputLabel>
                            {(context.currentBrand?.badgeValidation ? getLocalizedString('employee.badgeId', 'Badge ID') : getLocalizedString('employee.employeeNumber', 'Employee ID')) + ':'}
                        </ResponsiveInputLabel>

                        <InputDiv>
                            <InputViewOnlyValue>
                                {context.currentBrand?.badgeValidation ? stateEmployee?.badgeId ?? getLocalizedString('none', 'None') : stateEmployee?.employeeId ?? getLocalizedString('none', 'None')}
                            </InputViewOnlyValue>
                        </InputDiv>
                    </RowFormControl>

                    <RowFormControl>
                        <ResponsiveInputLabel>
                            {getLocalizedString('employee.portalGroup', 'Client Portal Access') + ':'}
                        </ResponsiveInputLabel>

                        <InputDiv>
                            <InputViewOnlyValue>
                                {stateEmployee?.employeeGroups.find(group => !group.kioskGroup)?.groupName ?? getLocalizedString('none', 'None')}
                            </InputViewOnlyValue>

                        </InputDiv>
                    </RowFormControl>

                    <SectionHeaderLg>{getLocalizedString('employee.view.employeeLocations', 'Active Locations')}</SectionHeaderLg>
                    <RowFormControl style={{borderBottom: '1px solid grey', marginBottom: '3vh'}}>
                        <InputDiv style={{marginBottom: '10px'}}>
                            <InputViewOnlyValue>
                                {stateEmployee?.employeeLocations.map(loc => {
                                    return (
                                        <Typography style={{marginBottom: '10px'}}>
                                            {loc.location.locationName}
                                        </Typography>
                                    )
                                })}
                            </InputViewOnlyValue>

                        </InputDiv>
                    </RowFormControl>

                    <div>
                        <CancelButton
                            id="viewuser-cancel"
                            variant='contained'
                            onClick={() => {
                                navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, {state: {lastSelectedIdx: employeeContext.viewPageContext.lastSelectedIdx}});
                            }}
                        >
                            {getLocalizedString('back', 'Back')}
                        </CancelButton>
                    </div>

                </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}
