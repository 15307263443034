import AppBar from "../appbar/AppBar";
import {CenteredDiv, MainContentContainer} from "../ui-components/StyledComponents";
import {AppWithFooter} from "../../App";
import { Typography } from "@material-ui/core";
import {appContextActions} from "../app-context/appContextSlice";
import {useAppDispatch} from "../../store/hooks";
import {getLocalizedString} from "../../util/Localization";

export const UnauthorizedPage = () => {
    const dispatch = useAppDispatch();
    dispatch(appContextActions.setUnauthorizedAccess(false));

    return (
        <AppWithFooter>
            <AppBar title={' '} right={<></>}/>
            <CenteredDiv>
                <MainContentContainer style={{textAlign: 'center', width: '100%'}}>
                    <Typography variant='h4'>
                        {getLocalizedString('unauthorized.title', 'You Are Not Authorized To Access This Page')}
                    </Typography>
                </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    );
}