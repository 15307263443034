import * as React from 'react';
import {styled} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import {BLACK} from "../../../themes/colors";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import ReactMarkdown from 'react-markdown';
import {InfoOutlined} from "@material-ui/icons";

type BannerProps = {
    message: string,
    type: 'success' | 'info' | 'warning' | 'danger',
    style?: React.CSSProperties
}

export const Banner:React.ComponentType<BannerProps> = ({message, type, style}) => {

    const BannerClass:string = React.useMemo(() => {
        let bannerClass;

        switch (type) {
            case 'success':
                bannerClass = 'Banner-Success';
                break;
            case 'info':
                bannerClass = 'Banner-Info';
                break;
            case 'warning':
                bannerClass = 'Banner-Warning';
                break;
            case 'danger':
                bannerClass = 'Banner-Danger';
                break;
            default:
                bannerClass = 'Banner-Info';
        }

        return bannerClass;
    }, [type]);

    return (
      <BannerDiv style={style} className={BannerClass}>
          <BannerText>
              <div style={{display: 'flex', height: 'min(4vh, 4vw)'}}>
                  <InfoOutlined style={{color: '#517D9E', width: '30px', height: 'min(4vh, 4vw)', marginRight: '5px'}}/>

                  <div style={{display: 'flex', alignItems: 'center', height: 'min(4vh, 4vw)', fontSize: calculateResponsiveSizeWithMinMax('2', '24', '10')}}>
                      <ReactMarkdown components={{ a: function LinkRenderer(props) {
                              return (
                                  <a href={props.href?.includes('http') ? props.href : ('https://' + props.href)} target="_self">
                                      {props.children}
                                  </a>
                              );
                          }}}>
                          {message}
                      </ReactMarkdown>
                  </div>
              </div>
          </BannerText>
      </BannerDiv>
    );
}

const BannerDiv = styled('div')({
    height: '10vh',
    maxHeight: '72px',
    color: BLACK,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100001
});

const BannerText = styled(Typography)({
    color: BLACK,
    fontSize: calculatedFontSize('.7em', '1.3em'),
    "@media (max-width:1280px)": {
        fontSize: calculatedFontSize('.6em', '.95em'),
    }
});
