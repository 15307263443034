import * as React from 'react';
import {SideBar} from "../../sidebar/SideBar";
import AppBar from "../../appbar/AppBar";
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {makeStyles, styled} from "@material-ui/core/styles";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {employeeContextActions, employeeContextSelector, exportEmployees} from "../employeeSlice";
import {appContextSelector} from "../../app-context/appContextSlice";
import {CenteredDiv, FormTitle, ErrorMessageSectionItem, MainContentContainer} from "../../ui-components/StyledComponents";
import {AppWithFooter} from "../../../App";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";

export const ExportEmployees = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const employeeContext = useAppSelector(employeeContextSelector);

    const [activeOnly, setActiveOnly] = React.useState<boolean>(true);

    React.useEffect(() => {
        return () => {dispatch(employeeContextActions.resetExportEmployee())}
    }, [dispatch]);

    React.useEffect(() => {
        if(employeeContext.exportFile) {
            const link = document.createElement('a');
            link.href = employeeContext.exportFile;
            link.setAttribute('download', 'EmployeeExportFile.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [employeeContext.exportFile])

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(exportEmployees({locationId: context.currentLocation.locationId, activeOnly: activeOnly}));
        }
    }, [activeOnly, context.currentLocation?.locationId, dispatch]);

    return (
        <AppWithFooter>
            <AppBar/>
            <CenteredDiv>
                <SideBar/>

                <MainContentContainer style={{alignItems: 'unset'}}>
                    {employeeContext.exportErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {employeeContext.exportErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>}

                    <FormTitle variant='h5' className={'o-heading--xl'}>{getLocalizedString('exportEmployees.title', 'Export Users')}</FormTitle>

                    <FormControl>
                        <RadioGroup
                            className={styles.radio}
                            aria-label="kiosk-access"
                            name="radio-buttons-group"
                            value={activeOnly}
                            onChange={(event) =>
                                setActiveOnly(event.target.value === 'true')
                            }>
                            <FormControlLabel
                                checked={activeOnly} 
                                value={'true'} 
                                control={<ColorRadio color="default"/>} 
                                label={<span style={{fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16')}}>{getLocalizedString('exportEmployees.activeOnly', 'All active users')}</span>}
                            />
                            <FormControlLabel
                                checked={!activeOnly} 
                                value={'false'} 
                                control={<ColorRadio color="default"/>} 
                                label={<span style={{fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16')}}>{getLocalizedString('exportEmployees.allEmployees', 'All active and inactive users')}</span>}
                            />

                        </RadioGroup>
                    </FormControl>

                    <div className={styles.buttonDiv}>
                        <ARCButton
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            onClick={submitForm}
                            label={getLocalizedString('exportEmployees.submit', 'Export')}
                        >
                        </ARCButton>
                        <ARCButton 
                            fill={'outlined'}
                            variant={'secondary'}
                            size={'md'}
                            onClick={() => {navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}
                            label={getLocalizedString('exportEmployees.cancel', 'Cancel')}
                        >
                        </ARCButton>
                    </div>
                </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}

const useStyles = makeStyles((theme) => ({
    form_title: {
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: 550
    },
    form: {
        width: '100%'
    },
    help_text: {
        fontSize: calculatedFontSize('.6em', '1.3em')
    },
    radio: {
        fontSize: calculatedFontSize('1.9em', '2.3em')
    },
    link: {
      cursor: 'pointer'
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        marginTop: theme.spacing(4)
    }
}));

const ColorRadio = styled(Radio) (({theme}) => ({
    color: theme.palette.primary.main
}));
