export const PORTAL_ADMIN = 'Admin';
export const PORTAL_SUPER_ADMIN = 'Super Admin';
// export const PORTAL_DAILY_ADMIN = 'Daily Administrator';
// export const PORTAL_LOCATION_GM = 'Location Manager';
// export const PORTAL_REGIONAL_MANAGER = 'Regional Manager';
// export const PORTAL_HQ_MANAGER = 'HQ Manager';
export const KIOSK_NONE = 'None';
export const KIOSK_EMPLOYEE = 'Associate';
export const KIOSK_TEAM_LEAD = 'Team Lead';
export const KIOSK_MANAGER = 'Manager';

export const ARC_SUPER_ADMIN = 'Super Admin';
export const ARC_ADMIN = 'Admin';
export const ARC_TECH_SUPPORT = 'Technical Support';
export const ARC_FIELD_TECH = 'Field Tech';

export const SSO_HQ_USER = 'SAMS_CLUB_HQ';

// type AllGroupsType = typeof SUPER_ADMIN | typeof PORTAL_DAILY_ADMIN | typeof PORTAL_LOCATION_GM | typeof PORTAL_REGIONAL_MANAGER | typeof PORTAL_HQ_MANAGER | typeof KIOSK_EMPLOYEE | typeof KIOSK_TEAM_LEAD | typeof KIOSK_MANAGER;
export type KioskGroupsType = typeof KIOSK_NONE | typeof KIOSK_EMPLOYEE | typeof KIOSK_TEAM_LEAD | typeof KIOSK_MANAGER;
export type PortalGroupsType = typeof PORTAL_ADMIN | typeof PORTAL_SUPER_ADMIN;
export type UserGroupsType = typeof ARC_SUPER_ADMIN | typeof ARC_ADMIN | typeof ARC_TECH_SUPPORT | typeof ARC_FIELD_TECH;

export const KIOSK_GROUPS: KioskGroupsType[] = [KIOSK_NONE, KIOSK_EMPLOYEE, KIOSK_TEAM_LEAD, KIOSK_MANAGER];
export const PORTAL_GROUPS: PortalGroupsType[] = [PORTAL_ADMIN, PORTAL_SUPER_ADMIN];
export const USER_GROUPS: UserGroupsType[] = [ARC_SUPER_ADMIN, ARC_ADMIN, ARC_TECH_SUPPORT, ARC_FIELD_TECH];