import {Typography, useTheme} from '@material-ui/core';
import * as React from 'react';
import {CURRENT_VERSION} from "../../@types/App_Versioning";
import {calculatedFontSize} from "../../util/TextUtils";
import {useLocation, useNavigate} from "react-router-dom";
import "../../css/Footer.css"
import {getLocalizedString} from "../../util/Localization";
export const Footer = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    return (
        <>
            <div className={'footer-content'}>
                <Typography variant="body1" style={{marginLeft: '5vw', fontSize: calculatedFontSize('.8em', '1.1em')}} className={'footer-text'}>
                    {getLocalizedString('footer.copyright', '© ChargeItSpot') + ' ' + new Date().getFullYear()}
                </Typography>

                <Typography variant="body1" style={{marginRight: '3vw', fontSize: calculatedFontSize('.8em', '1.1em')}} className={'footer-text'}>
                    {CURRENT_VERSION}
                </Typography>
            </div>

            <div className={'footer'}
                style={{
                    backgroundColor: theme.palette.secondary.main
                }}>
                <div className={'footer-terms-privacy-div'}>
                    <div className={'footer-privacy'}
                         onClick={() => navigate(location.pathname.includes('emergency') ? '/privacy/emergency' : '/privacy/clientportal')}>
                        <div className={'footer-terms-privacy-text'}>
                            {getLocalizedString('privacy.title', 'Privacy Policy')}
                        </div>
                    </div>
                    <br/>
                    <div className={'footer-terms'}
                         onClick={() => navigate(location.pathname.includes('emergency') ? '/terms/emergency' : '/terms/clientportal')}>
                         <div className={'footer-terms-privacy-text'}>
                             {getLocalizedString('terms.title', 'Terms & Conditions')}
                         </div>
                    </div>
                </div>
            </div>
        </>
    )
};
