import * as React from 'react';
import {styled} from "@material-ui/core/styles";
import {BLUE, LIGHT_GREY} from "../../themes/colors";
import {calculatedFontSize} from "../../util/TextUtils";

type ColoredLinkProps = {
    disabled?: boolean,
    onClick: () => void,
    style?: React.CSSProperties
}

export const ControlledLink:React.ComponentType<ColoredLinkProps> = ({disabled, onClick, style, children}) => {
    return (
        disabled ?
            <DisabledLink className={'Link'} onClick={() => {}} style={style}>
                {children}
            </DisabledLink>
            :
            <StyledLink className={'Link'} onClick={onClick} style={style}>
                {children}
            </StyledLink>
    );
};

export const StyledLink = styled('span') ({
    cursor: 'pointer',
    color: BLUE,
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('.85em', '1em')
    }
});

export const DisabledLink = styled('span') ({
    color: LIGHT_GREY,
    cursor: 'default',
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('.85em', '1em')
    }
});