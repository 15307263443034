import * as React from 'react';
import './css/RadioButton.css';

interface RadioButtonProps {
    on: boolean;
    className?: string;
    onChange: () => void;
    style?: React.CSSProperties;
}

const RadioButton: React.FC<RadioButtonProps> = ({ on, className, onChange, style }) => {
    return (
        <div className={'radio-button ' + (className ?? '')} style={{...style}} onClick={on ? undefined : onChange}>
            {on &&
            <div className={'radio-button-option active'}/>
            }
        </div>
    );
}

export default RadioButton;
