import * as React from 'react';
import MuiAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    appContextActions,
    appContextSelector,
    getPortalGlobalMessages,
    locationsForBrandSelector
} from "../app-context/appContextSlice";
import {UserProfile} from "./prefabs/UserProfile";
import {Grid, GridSize, GridSpacing} from '@material-ui/core';
import {media_url} from "../../dtos/API_DOMAINS";
import { Banner } from './prefabs/Banner';
import {hasOnboardingMedia} from "../../dtos/Brand";
import Dialog from "@material-ui/core/Dialog";
import Carousel from "../ui-components/Carousel";
import {useLocation, useNavigate} from "react-router-dom";
import {ARCLogo} from "../ui-components/ARCLogo";
import {useCurrentUserContext} from "../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../util/Localization";

type AppBarProps = {
    left?: React.ReactNode,
    right?: React.ReactNode,
    title?: React.ReactNode,
    logoLabel?: string,
    leftGridWidth?: GridSize,
    midGridWidth?: GridSize,
    rightGridWidth?: GridSize,
    gridSpacing?: GridSpacing,
    alwaysShowLabel?: boolean,
    sx?: {},
    className?: string,
    static?: boolean,
    logoNonInteractive?: boolean,
    hideBgImage?: boolean,
    hideGlobalMessages?: boolean
};

const AppBar:React.ComponentType<AppBarProps> = (props) => {
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const currentUserContext = useCurrentUserContext();
    const locations = useAppSelector(locationsForBrandSelector(context.currentBrand?.brandId ?? 0));
    const dispatch = useAppDispatch();
    const location = useLocation();

    const firstTimeLogin = React.useMemo<boolean>(() => context.firstTimeLogin, [context.firstTimeLogin]);
    const [showDialog, setShowDialog] = React.useState<boolean>(firstTimeLogin);

    React.useEffect(() => {
        if(!props.hideGlobalMessages) {
            dispatch(getPortalGlobalMessages({brandId: context.currentBrand?.brandId}));
        }
    }, [context.currentBrand?.brandId, dispatch, props.hideGlobalMessages]);
    
    const logoOnClick = React.useCallback(() => {
        if (!props.logoNonInteractive) {
            if (!location.pathname.includes("/emergency")) {
                (!currentUserContext.groups.isArcTechSupport ?
                        navigate(`/reports/view/locationId=${context.currentLocation?.locationId}`)
                        :
                        navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)
                )
            } else {
                localStorage.removeItem('emergencyToken');
                navigate('/emergency/login')
            }
        }
    },[props.logoNonInteractive, currentUserContext.groups.isArcTechSupport, context.currentLocation?.locationId, context.currentBrand?.brandId, location.pathname, navigate])

    const showOnboardingGuide = React.useMemo(() => {
        return context.currentBrand && context.firstTimeLogin && hasOnboardingMedia(context.currentBrand);
    }, [context.currentBrand, context.firstTimeLogin]);

    return (
        <MuiAppBar position={props.static ? "static" : "sticky"} style={{marginBottom: '3vh'}}>
            <MuiAppBar color={'inherit'} position={props.static ? "static" : "sticky"} className={props.className ?? 'app-bar'} style={props.hideBgImage ? {backgroundImage: 'none'} : {}}>
                <Toolbar style={{justifyContent: 'space-between', height: '100%'}}>
                    <Grid container spacing={props.gridSpacing ?? 2}>

                        <Grid className={'left-grid'} item xs={props.leftGridWidth ?? 4}>
                            {props.left ? props.left :
                                <div className={'logo-div'}>
                                    <ARCLogo 
                                        onClick={logoOnClick}
                                    />
                                    <Typography className={`${props.alwaysShowLabel ? 'logo-label' : 'logo-label hidden'}`} variant="h5"> {props.logoLabel ? props.logoLabel : getLocalizedString('title', 'Client Portal')}</Typography>
                                </div>
                            }
                        </Grid>

                        <Grid className={'center-grid'} item xs={props.midGridWidth ?? 4}>
                            {props.title ? props.title :
                            <Typography className={'center-grid-title'} variant="h6">

                                {context.demoMode && location.pathname.includes('reports') ?
                                    <>
                                        {getLocalizedString('appbar.demoTitle', 'XYZ Corp.')}
                                    </>
                                    :
                                    <>
                                        {context.currentBrand?.brandName}

                                        {context.authorizationDto && locations.length === 1 &&
                                        <>
                                            {' - '} {context.currentLocation?.locationName}
                                        </>
                                        }
                                    </>
                                }
                            </Typography>
                            }
                        </Grid>

                        <Grid className={'right-grid'} item xs={props.rightGridWidth ?? 4}>
                            {props.right ? props.right :
                            <UserProfile firstname={context.authorizationDto?.firstName} lastname={context.authorizationDto?.lastName}/>}
                        </Grid>

                        {showOnboardingGuide &&
                        <Dialog maxWidth={'lg'} open={showDialog} onClose={() => {}}>
                            <Carousel
                                onClose={() => {
                                    setShowDialog(false);
                                    dispatch(appContextActions.setFirstTimeLogin());
                                }}
                                firstTimeLogin={true}
                                title={getLocalizedString('onboarding.title', 'Welcome to ARC\'s Client Portal')}
                                images={[
                                    context.currentBrand?.onboardingImageFile1 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile1) : '',
                                    context.currentBrand?.onboardingImageFile2 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile2) : '',
                                    context.currentBrand?.onboardingImageFile3 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile3) : '',
                                    context.currentBrand?.onboardingImageFile4 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile4) : '',
                                    context.currentBrand?.onboardingImageFile5 ? (media_url + context.currentBrand?.brandId + '/' + context.currentBrand?.onboardingImageFile5) : ''
                                ].filter(img => img !== '')}>

                            </Carousel>
                        </Dialog>
                        }
                    </Grid>
                </Toolbar>
            </MuiAppBar>

            {!props.hideGlobalMessages && context.portalGlobalMessages &&
                context.portalGlobalMessages.map((globalMessage, idx) => {
                    return (
                        <Banner style={{padding: '0 15vw 0 7vw', marginBottom: '-3px'}} key={idx} message={globalMessage.message} type={'info'}/>
                    )
                })
            }
        </MuiAppBar>
    );
};

export default AppBar;
