import * as React from 'react';
import { ChangeEvent } from 'react';

type ARCTextInputProps = {
    id: string,
    size: 'sm' | 'md',
    label?: string,
    placeholder?: string,
    required: boolean,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    validate?: (val: any) => string | undefined,
    className?: string
}

export const ARCTextInput : React.FC<ARCTextInputProps> = (props) => {

    const [inputError, setInputError] = React.useState<string | undefined>(undefined);

    return (
        <div className={`o-textinput__container ${props.className ? props.className : ''}`}>
            {props.label &&
            <label className={`o-textinput__label--${props.size}`}>
                {props.label}

                {props.required &&
                <div className={'required-star'}>
                    {'*'}
                </div>
                }
            </label>
            }

            <input type={'text'}
                   disabled={props.disabled}
                   placeholder={props.placeholder ?? ''}
                   className={`o-textinput o-textinput--${props.size} ${inputError ? 'o-input__error' : ''}`}
                   value={props.value}
                   onChange={(event) => {
                       props.onChange(event);

                       if(props.validate) {
                           setInputError(props.validate(event.target.value));
                       }
                   }}
            />

            {inputError &&
            <div className={'o-textinput__error'}>
                {inputError}
            </div>
            }


        </div>
    )
}