import * as React from 'react';
import {CheckBoxColorBorder} from "./StyledComponents";

type ColorCheckboxProps = {
    checked: boolean,
    onChange: (event: any) => void
}

export const ColorCheckbox:React.ComponentType<ColorCheckboxProps> = (props) => {

    return (
        <CheckBoxColorBorder checked={props.checked}
                             onChange={props.onChange}
        />
    )
}