import * as React from 'react';
import {FormEvent} from 'react';
import {AppWithFooter} from "../../App";
import AppBar from "../appbar/AppBar";
import {
    CenteredDiv,
    ColoredLink,
    FormTitle,
    MainContentContainer,
    ResponsiveTextAreaTall
} from "../ui-components/StyledComponents";
import {SideBar} from "../sidebar/SideBar";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {appContextSelector} from "../app-context/appContextSlice";
import {useNavigate} from "react-router-dom";
import {FormControlLabel, InputLabel, Radio, RadioGroup, Tooltip, Typography} from "@material-ui/core";
import {BLACK, RED} from "../../themes/colors";
import {
    getUrgencyString,
    LABEL_TYPE_DEVICE,
    LABEL_TYPE_EMPLOYEE,
    LABEL_URGENCYS,
    LabelRequestDto,
    LabelType, LabelUrgency
} from "../../dtos/LabelRequestDto";
import {styled} from "@material-ui/core/styles";
import {Error} from "@material-ui/icons";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../util/TextUtils";
import {labelRequestContextActions, labelRequestContextSelector, saveLabelRequest} from "./labelRequestSlice";
import {ServerMessage} from "../ui-components/ServerMessage";
import {isDevNumCisProvided, isEmpNumCisProvided} from "../../dtos/Brand";
import ColorSelect, {Option} from "../ui-components/ColorSelect";
import '../../css/LabelRequest.css'
import {getLocalizedString} from "../../util/Localization";
import {ARCButton} from "../ui-components/design-system/ARCButton";

export const CreateLabelRequest = () => {

    const context = useAppSelector(appContextSelector);
    const requestContext = useAppSelector(labelRequestContextSelector);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const allowEmployeeRequests = React.useMemo(() => {
        return !context.currentBrand?.employeeAPIEnabled;
    }, [context.currentBrand?.employeeAPIEnabled]);

    const defaultLabelType = React.useMemo(() => {
        if(context.currentBrand) {
            const empNumAllowed = isEmpNumCisProvided(context.currentBrand) && allowEmployeeRequests;
            const devNumAllowed = isDevNumCisProvided(context.currentBrand);

            if(empNumAllowed && !devNumAllowed) {
                return LABEL_TYPE_EMPLOYEE;
            } else if (!empNumAllowed && devNumAllowed) {
                return LABEL_TYPE_DEVICE;
            } else {
                return undefined;
            }
        }

        return undefined;
    }, [allowEmployeeRequests, context.currentBrand]);

    const locations = React.useMemo(() => {
       return context.authorizationDto?.authorizedLocations?.filter(loc => loc.brand.brandId === context.currentBrand?.brandId);
    }, [context.authorizationDto?.authorizedLocations, context.currentBrand?.brandId]);

    const locationOptions = React.useMemo(() => {
        const options : Option[] = (locations?.slice() ?? []).sort((l1, l2) => l1.locationName.localeCompare(l2.locationName)).map((location) => {
            return {
                value: location.locationId,
                label: location.locationName
            }
        })

        return [{value: undefined, label: getLocalizedString('labelRequest.location.select', 'Select Location')}, ...options];
    }, [locations]);

    const urgencies = LABEL_URGENCYS.map((opt) => {
        return {
            value: opt,
            label: getUrgencyString(opt)
        }
    });

    const [formRequest, setFormRequest] = React.useState<LabelRequestDto>({
        labelRequestId: undefined,
        locationId: locations?.length === 1 ? locations[0].locationId : undefined,
        location: locations?.length === 1 ? locations[0] : undefined,
        labelType: defaultLabelType,
        requestNotes: undefined
    });

    const submitForm = React.useCallback((event: FormEvent) => {
        event.preventDefault();

        if(context.currentLocation?.locationId) {
            dispatch(saveLabelRequest({locationId: context.currentLocation.locationId, labelRequest: formRequest}));
        }
    }, [context.currentLocation?.locationId, dispatch, formRequest]);

    React.useEffect(() => {
        return () => {
            dispatch(labelRequestContextActions.resetState())
        };
    }, [dispatch]);

    React.useEffect(() => {
        if(requestContext.saveLabelRequestSuccessful && requestContext.savedLabelRequest?.labelRequestId) {
            navigate(`/faq/view/locationId=${context.currentLocation?.locationId}/qrRequestId=${requestContext.savedLabelRequest?.labelRequestId}`);
        }
    }, [context.currentLocation?.locationId, navigate, requestContext.saveLabelRequestSuccessful, requestContext.savedLabelRequest?.labelRequestId]);

    return (
        <AppWithFooter>
            <AppBar/>
            <CenteredDiv>
                <SideBar/>
                <MainContentContainer>

                    {requestContext.saveLabelRequestErrorMessage &&
                        requestContext.saveLabelRequestErrorMessage.map((error, idx) => {
                            return (
                                <ServerMessage variant={'error'} key={idx}>
                                    {error}
                                </ServerMessage>
                            )
                        })
                    }

                    <div style={{width: '100%', display: 'flex', alignItems: 'baseline'}}>
                        <FormTitle variant='h5' className={'o-heading--xl'} style={{width: 'unset', marginRight: '30px'}}>{getLocalizedString('labelRequest.title', 'Request QR Codes')}</FormTitle>

                        <ColoredLink onClick={() => {navigate(`/qr/viewall/locationId=${context.currentLocation?.locationId}`)}}>
                            {getLocalizedString('labelRequest.viewPrev', 'View Previous Requests')}
                        </ColoredLink>
                    </div>

                    <div style={{width: '100%', marginBottom: '2vh'}}>
                        {getLocalizedString('labelRequest.urgency.label', 'Replenishments are 50 QR Codes. If you need more, please specify in the notes.')}
                    </div>

                    <form style={{width: '100%'}} id={'createLabelRequestForm'} onSubmit={submitForm}>
                        <div className={'request-field-row'}>
                            <span className={'request-field-input-label'}>
                                {getLocalizedString('labelRequest.location', 'Location') + ':'} <span style={{color: RED}}>{'*'}</span>
                            </span>

                            {locations && locations.length > 1 ?
                                <ColorSelect
                                    label={getLocalizedString('labelRequest.location.select', 'Select Location')}
                                    value={formRequest.location?.locationId}
                                    className={'request-input-select'}
                                    variant="outline"
                                    options={locationOptions}
                                    onChange={(option) => {
                                        setFormRequest(prevState => ({
                                            ...prevState,
                                            locationId: option.value,
                                            location: locations.find(loc => loc.locationId === parseInt(option.value))
                                        }));
                                    }}
                                />

                                :

                                <div className={'request-field-input-div'}>
                                    <span id="label-request-shipping-address">
                                        {formRequest.location?.locationName ?? ''}
                                    </span>
                                </div>
                            }
                        </div>

                        <div className={'request-field-row'}>
                            <span className={'request-field-input-label'}>
                                {getLocalizedString('labelRequest.location.shippingAddress', 'Shipping Address:')}
                            </span>

                            <div className={'request-field-input-div'}>
                                <span id="label-request-shipping-address">
                                    {formRequest.location?.shippingAddress ?? ''}
                                </span>
                            </div>
                        </div>

                        <div className={'request-field-row'} style={{marginBottom: '0'}}>
                            <ToolTipLabel shrink={false}>
                                <ToolTipLabelText>
                                    {getLocalizedString('labelRequest.labelType', 'ID Type') + ':'}
                                </ToolTipLabelText>

                                <span style={{color: RED}}>{'*'}</span>

                                <Tooltip placement={'bottom-start'} title={getLocalizedString('labelRequest.labelType.tooltip', 'Only one QR Code Type can be ordered at a time. If you need both Device IDs and Badge IDs, please submit this form twice.')}>
                                    <Error className={'request-tooltip-icon'}/>
                                </Tooltip>
                            </ToolTipLabel>


                            <>
                                <RadioGroup aria-label="label-type"
                                            name="row-radio-buttons-group"
                                            value={formRequest.labelType}
                                            style={{flexDirection: 'row'}}
                                            onChange={(event) => {
                                                setFormRequest(prevState => ({
                                                    ...prevState,
                                                    labelType: event.target.value as LabelType
                                                }))
                                            }}
                                >
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    {(context.currentBrand && isDevNumCisProvided(context.currentBrand)) &&
                                    <RadioButtonFormControl 
                                                      checked={formRequest.labelType === LABEL_TYPE_DEVICE || !isEmpNumCisProvided(context.currentBrand)}
                                                      value={LABEL_TYPE_DEVICE}
                                                      control={<Radio color="primary"/>}
                                                      label={getLocalizedString('labelRequest.labelType.device', 'Device IDs - ' + (context.currentBrand?.deviceNumberLength ?? 0) + ' digits', [`${context.currentBrand?.deviceNumberLength ?? 0}`])}/>
                                    }

                                    {(context.currentBrand && isEmpNumCisProvided(context.currentBrand)) &&
                                    <RadioButtonFormControl
                                                      checked={formRequest.labelType === LABEL_TYPE_EMPLOYEE || !isDevNumCisProvided(context.currentBrand)}
                                                      value={LABEL_TYPE_EMPLOYEE}
                                                      control={<Radio color="primary"/>}
                                                      label={getLocalizedString('labelRequest.labelType.badge', 'Badge IDs - ' + (context.currentBrand?.employeeNumberLength ?? 0) + ' digits', [`${context.currentBrand?.employeeNumberLength ?? 0}`]) }/>
                                    }
                                </div>
                                </RadioGroup>
                            </>
                        </div>

                        <div className={'request-field-row'} style={{marginBottom: '1.5vh'}}>
                            <span className={'request-field-input-label'}>
                                {getLocalizedString('labelRequest.urgency', 'Urgency:')}
                            </span>

                            <div className={'request-field-input-div'}>
                                    <ColorSelect
                                        className={'request-input-select-sm'}
                                        variant="outline"
                                        options={[{value: '', label: getLocalizedString('labelRequest.urgency.select', 'Select Urgency')}, ...urgencies]}
                                        label={getLocalizedString('labelRequest.urgency.select', 'Select Urgency')}
                                        value={formRequest.urgency}
                                        onChange={(option) => {
                                            setFormRequest(prevState => ({
                                                ...prevState,
                                                urgency: option.value as LabelUrgency
                                            }));
                                        }}
                                    >

                                    </ColorSelect>
                                </div>
                        </div>

                        <div className={'request-field-row'}>
                            <span className={'request-field-input-label'}>
                                {getLocalizedString('labelRequest.requestNotes', 'Notes:')}
                            </span>

                            <div className={'request-field-input-div'} style={{maxWidth: 'unset', width: 'unset'}}>
                                <ResponsiveTextAreaTall
                                           style={{marginLeft: '0px', border: '2px solid', borderRadius: '10px', borderColor: BLACK, height: '20vh', maxHeight: '100px', paddingLeft: '10px'}}
                                           id="label-request-notes"
                                           value={formRequest.requestNotes}
                                           onChange={(event) => {
                                               setFormRequest(prevState => ({
                                                   ...prevState,
                                                   requestNotes: event.target.value
                                               }));
                                           }}
                                />
                            </div>
                        </div>

                        <div className={'request-button-div'}>
                            <ARCButton
                                fill={'filled'}
                                variant={'primary'}
                                size={'md'}
                                id="save-label-request"
                                submit={true}
                                onClick={() => {}}
                                label={getLocalizedString('labelRequest.submit', 'Submit')}
                            >
                            </ARCButton>
                            <ARCButton
                                fill={'outlined'}
                                variant={'secondary'}
                                size={'md'}
                                id="save-label-request-cancel"
                                onClick={() => {
                                    navigate(`/faq/view/locationId=${context.currentLocation?.locationId}`);
                                }}
                                label={getLocalizedString('cancel', 'Cancel')}
                            >
                            </ARCButton>
                        </div>

                    </form>

                </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}

const ToolTipLabel = styled(InputLabel)(({theme}) => ({
    display: 'flex',
    minWidth: '200px',
    height: '100%',
    fontWeight: 550,
    [theme.breakpoints.down('xs')]: {
        minWidth: '120px',
        fontSize: calculatedFontSize('.9em', '1.25em'),
        marginLeft: '0px',
        marginTop: '-5px'
    },
    marginBottom: '20px',
    alignItems: 'center',
    position: "initial",
    color: BLACK
}));

const ToolTipLabelText = styled(Typography)(({theme}) => ({
    display: 'flex',
    fontSize: '1.2rem',
    transform: 'unset',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
        fontSize: calculatedFontSize('.9em', '1.25em'),
        transform: 'unset',
    },
    position: "initial",
    color: BLACK
}));

const RadioButtonFormControl = styled(FormControlLabel)(() => ({
    marginRight: calculateResponsiveSizeWithMinMax('.2', '20', '0'),
    '& .MuiFormControlLabel-label': {
        fontSize: calculateResponsiveSizeWithMinMax('2.2', '20', '8')
    }
}));
