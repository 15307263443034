import * as React from 'react';
import {RowFormControl} from "../../ui-components/StyledComponents";
import {InputLabel, Tooltip} from "@material-ui/core";
import ColorSelect from "../../ui-components/ColorSelect";
import DevicePickupPermissionInputContainer from "../../devices/components/DevicePickupPermissionInputContainer";
import {DevicePickupPermissionDto} from "../../../dtos/DevicePickupPermission";
import {EmployeeDto} from "../../../dtos/Employee";
import {Theme} from "@material-ui/core/styles";
import {DeviceTypeDto} from "../../../dtos/DeviceType";
import {RED} from "../../../themes/colors";
import {LocationDto} from "../../../dtos/Location";
import {getLocalizedString} from "../../../util/Localization";

type EmployeeDevicePickupPermissionInputProps = {
    display: boolean,
    required?: boolean,
    editable?: boolean,
    forceHideInput?: boolean,
    belowPermissions: boolean,
    abovePermissions: boolean,
    forceDefault: boolean,
    authLocations?: LocationDto[],
    onChange: (permission: DevicePickupPermissionDto | undefined) => void,
    allowMultipleCheckouts: boolean,
    permissions: DevicePickupPermissionDto[],
    deviceTypes: DeviceTypeDto[],
    deviceTypesForBrand: DeviceTypeDto[],
    employee: EmployeeDto,
    theme: Theme;
    classNames?: {
        label: string,
        input: string
    }
}

export const EmployeeDevicePickupPermissionInput:React.FC<EmployeeDevicePickupPermissionInputProps> = ({display, required, editable, forceHideInput, belowPermissions, abovePermissions, onChange, allowMultipleCheckouts, deviceTypes, deviceTypesForBrand, forceDefault, authLocations, permissions, employee, theme, classNames}) => {

    const BOLD_DELIMITER : string = '~';

    const addBoldStyleToKeyword = (input: string) => {
        const regex = /~([^~]+)~/g;
        const resultString = input.replace(regex, (_, match) => `<strong>${match}</strong>`);

        return <span dangerouslySetInnerHTML={{ __html: resultString }} />;
    };

    const [allowSelectEdit, setAllowSelectEdit] = React.useState<boolean>(false);
    const [showCustomInput, setShowCustomInput] = React.useState<boolean>(true);
    const [showFullSummary, setShowFullSummary] = React.useState<boolean>(false);

    React.useEffect(() => {
        if(forceHideInput) {
            setAllowSelectEdit(false);
        }
    }, [forceHideInput]);

    const showInputContainer = React.useMemo(() => {
        return allowSelectEdit && employee.devicePickupPermission?.custom && showCustomInput;
    }, [allowSelectEdit, employee.devicePickupPermission?.custom, showCustomInput]);

    const permissionEditable = React.useMemo(() => {
        return editable && (!belowPermissions && !abovePermissions && !forceDefault);
    }, [abovePermissions, belowPermissions, editable, forceDefault]);

    const permissionLabel = React.useMemo(() => {
        let label = '';

        if(abovePermissions) {
            label = getLocalizedString('employee.devicePickupPermission.notRestricted', 'Not Restricted')
        } else if (belowPermissions) {
            label = getLocalizedString('employee.devicePickupPermission.noAccess', 'No Access')
        } else if(forceDefault) {
            label = getLocalizedString('employee.devicePickupPermission.defaultGroups', 'Default Groups (see below)');
        } else if(employee.devicePickupPermission?.default) {
            label = getLocalizedString('employee.devicePickupPermission.defaultGroup', 'Default Group');
        }  else if (employee.devicePickupPermission?.permissionName) {
            label = employee.devicePickupPermission?.permissionName;
        }

        return label;
    }, [abovePermissions, belowPermissions, employee.devicePickupPermission?.default, employee.devicePickupPermission?.permissionName, forceDefault]);

    const permissionList = React.useMemo<DevicePickupPermissionDto[]>(() => {
        const list = permissions.slice();

        if(employee.devicePickupPermission && !employee.devicePickupPermission.custom && !list.find(perm => perm.devicePickupPermissionId === employee.devicePickupPermission?.devicePickupPermissionId)) {
            list.push(employee.devicePickupPermission);
        }

        if(!employee.devicePickupPermission?.custom) {
            list.push({
                devicePickupPermissionId: -1,
                allowMultipleCheckouts: allowMultipleCheckouts,
                custom: true,
                permissionName: getLocalizedString('employee.devicePickupPermission.customGroup', '**Custom Configuration**'),
                default: false,
                deviceTypes: deviceTypes.length > 0 ? [{
                    devicePickupPermissionDeviceTypeId: undefined,
                    deviceTypeId: undefined,
                    quantity: 1
                }] : []
            });
        }

        return list;
    }, [allowMultipleCheckouts, deviceTypes.length, employee.devicePickupPermission, permissions]);

    const permissionOptionsList = React.useMemo(() => {

        //Want the 'default' permission to be at the top of the list, and the custom one at the end,
        //otherwise order should be alphabetical
        return permissionList.sort((p1, p2) => {
                if (p1.default && !p2.default) {
                    return -1;
                } else if (!p1.default && p2.default) {
                    return 1;
                }

                if (p1.custom && !p2.custom) {
                    return 1;
                } else if (!p1.custom && p2.custom) {
                    return -1;
                }

                return p1.permissionName.localeCompare(p2.permissionName);
            }
        ).filter(perm => perm.devicePickupPermissionId !== (employee.devicePickupPermission?.devicePickupPermissionId ?? 0)).map((permission) => {
            return {
                value: permission.devicePickupPermissionId,
                label: permission.default ? getLocalizedString('employee.devicePickupPermission.defaultGroup', 'Default Group') : permission.permissionName
            }
        })
    }, [employee.devicePickupPermission?.devicePickupPermissionId, permissionList]);

    const devicePickupPermissionSummary = React.useMemo(() => {
        let summaryLabel = '';
        let summaryOptions : string[] = [];

        if(abovePermissions) {
            summaryLabel = getLocalizedString('employee.devicePickupPermission.managerLabel', 'As an ARC manager, ' + (employee.firstName !== '' ? employee.firstName : 'this user') + ' can pick up', [`${(employee.firstName !== '' ? employee.firstName : 'this user')}`]);

            summaryOptions = [getLocalizedString('employee.devicePickupPermission.unlimited', 'Unlimited Devices'), getLocalizedString('employee.devicePickupPermission.allDeviceTypes', 'All Device Types')];
        } else {
            summaryLabel = getLocalizedString('employee.devicePickupPermission.summary', (employee.firstName !== '' ? employee.firstName : 'This user') + ' can pick up', [`${(employee.firstName !== '' ? employee.firstName : 'This user')}`]);

            if(forceDefault) {
                summaryOptions = employee.employeeLocations.map(loc => {
                    if(loc.active) {
                        const authLoc = authLocations?.find(authLoc => authLoc.locationId === loc.location.locationId);

                        if(authLoc && authLoc.defaultDevicePickupPermission) {
                            let permDeviceTypes : string[] = [];
                            const typesForLocation = authLoc.defaultDevicePickupPermission.deviceTypes.filter(type => !type.deviceTypeId || authLoc.deviceTypes?.find(dt => dt.deviceTypeId === type.deviceTypeId));
                            typesForLocation.forEach((deviceType, index) => {
                                let deviceTypeString = '';
                                const deviceTypeDto = authLoc.deviceTypes?.find(dt => dt.deviceTypeId === deviceType.deviceTypeId);

                                deviceTypeString = deviceType.quantity + 'x ' + ((deviceTypeDto?.deviceTypeName || (deviceType.quantity > 1 ? getLocalizedString('employee.devicePickupPermission.devices', 'devices') : getLocalizedString('employee.devicePickupPermission.device', 'device')))) + ' ';

                                if (index + 1 < (typesForLocation.length ?? 0)) {
                                    deviceTypeString += authLoc.defaultDevicePickupPermission?.allowMultipleCheckouts ? getLocalizedString('and', 'and') : getLocalizedString('or', 'or');
                                }

                                permDeviceTypes.push(deviceTypeString);
                            });

                            return BOLD_DELIMITER + authLoc.locationName + BOLD_DELIMITER + ': ' + permDeviceTypes.join(' ');
                        }
                    }

                    return '';
                }).filter(result => result !== '');
            } else {

                //Only display the device types in the permission that are enabled for the employee's location
                const deviceTypesForLocation = authLocations?.find(l => l.locationId === employee.employeeLocations[0].location.locationId)?.deviceTypes ?? deviceTypes;
                const displayableDeviceTypes = employee.devicePickupPermission?.deviceTypes?.filter(permDt => !!deviceTypesForLocation.find(locDt => locDt.deviceTypeId === permDt.deviceTypeId));
                summaryOptions = displayableDeviceTypes?.map((deviceType, index) => {
                    let deviceTypeString = '';

                    const deviceTypeDto = deviceTypesForLocation.find(locDt => locDt.deviceTypeId === deviceType.deviceTypeId);
                    if(deviceTypeDto) {
                        deviceTypeString = deviceType.quantity + 'x ' + BOLD_DELIMITER + ((deviceTypeDto.deviceTypeName || (deviceType.quantity > 1 ? getLocalizedString('employee.devicePickupPermission.devices', 'devices') : getLocalizedString('employee.devicePickupPermission.device', 'device'))) + BOLD_DELIMITER) + ' ';

                        if (index + 1 < (displayableDeviceTypes.length ?? 0)) {
                            deviceTypeString += employee.devicePickupPermission?.allowMultipleCheckouts ? getLocalizedString('and', 'and') : getLocalizedString('or', 'or');
                        }
                    }

                    return deviceTypeString;
                }) ?? [];
            }
        }

        if(summaryOptions.length === 0) {
            return null;
        }

        return (
            (employee.devicePickupPermission || forceDefault || abovePermissions) && display && !belowPermissions &&
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '2vh'}}>
                <div className={classNames?.label}/>
                <div>
                    {forceDefault ?
                        <Tooltip placement={'top'} title={getLocalizedString('employee.devicePickupPermission.activeAtMultiple', 'This associate is active at more than one location. Associates active at multiple locations can only be assigned to each location\'s Default Device Permissions Group.')}>
                            <label style={{fontStyle: 'italic'}}>{summaryLabel}</label>
                        </Tooltip>
                        :
                        <label style={{fontStyle: 'italic'}}>{summaryLabel}</label>
                    }

                    <ul className={'summary-text'}>
                        {
                            summaryOptions.map((option, idx) => {
                               return option && (showFullSummary || idx < 5) && <li key={idx}>{addBoldStyleToKeyword(option)}</li>
                            })
                        }
                    </ul>

                    {summaryOptions.length > 5 &&
                        <div className={'Link'} onClick={() => setShowFullSummary(!showFullSummary)}>
                            {showFullSummary ? getLocalizedString('employee.devicePickupPermission.hide', 'Hide List') : getLocalizedString('employee.devicePickupPermission.viewAll', 'View All')}
                        </div>
                    }
                </div>
            </div>
        )
    }, [abovePermissions, authLocations, belowPermissions, classNames?.label, deviceTypes, display, employee.devicePickupPermission, employee.employeeLocations, employee.firstName, forceDefault, showFullSummary]);

    return (
        <>
            <RowFormControl>
                {display ?
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <InputLabel shrink={false}
                                    className={classNames?.label + ' employee-tooltip-label-text'}>
                            {getLocalizedString('employee.devicePickupPermission.permissions', 'Device Permissions:')}

                            {required && editable &&
                            <span style={{color: RED}}>*</span>
                            }
                        </InputLabel>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: '25px'}}>
                            <ColorSelect
                                label={permissionLabel}
                                value={employee.devicePickupPermission?.devicePickupPermissionId}
                                variant={'outline'}
                                disabled={!permissionEditable || !allowSelectEdit}
                                className={classNames?.input}
                                style={{fontSize: '1rem'}}
                                onChange={(event) => {
                                    const perm = permissionList.find(perm => perm.devicePickupPermissionId === (event.value ? parseInt(event.value) : undefined));

                                    if (perm) {
                                        onChange(perm);
                                    }
                                }}
                                options={permissionOptionsList}
                            >

                            </ColorSelect>

                            {permissionEditable && !allowSelectEdit &&
                                <div className={'Link'} style={{marginLeft: '15px'}}
                                     onClick={() => setAllowSelectEdit(true)}>
                                    {getLocalizedString('edit', 'Edit')}
                                </div>
                            }

                            {permissionEditable && allowSelectEdit && employee.devicePickupPermission?.custom &&
                                <>
                                    {showCustomInput ?
                                        <div className={'Link'} style={{marginLeft: '15px'}}
                                             onClick={() => setShowCustomInput(false)}>
                                            {getLocalizedString('employee.devicePickupPermission.hideDetails', 'Hide Details')}
                                        </div>
                                        :
                                        <div className={'Link'} style={{marginLeft: '15px'}}
                                             onClick={() => setShowCustomInput(true)}>
                                            {getLocalizedString('employee.devicePickupPermission.showDetails', 'Show Details')}
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    :
                    <></>
                }
            </RowFormControl>

            {!showInputContainer && devicePickupPermissionSummary}


            {employee.devicePickupPermission && showInputContainer &&
                <>
                    <br/>
                    <br/>
                    <DevicePickupPermissionInputContainer
                        permission={employee.devicePickupPermission}
                        configHideable={false}
                        deviceTypes={deviceTypes}
                        allowMultipleCheckoutsToggle={allowMultipleCheckouts}
                        employee={employee}
                        hideSummary={!employee.devicePickupPermission?.custom}
                        onChange={(dto) => {
                            onChange(dto);
                        }}
                    />
                </>
            }

        </>
    )
}