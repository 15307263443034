import {
    PersonAdd,
    GroupOutlined,
    PowerSettingsNew,
    Assessment,
    InfoOutlined,
    BatteryChargingFullOutlined,
    Mail,
    PhoneInTalk,
    KeyboardArrowDown,
    Clear,
    Smartphone,
    UpdateOutlined,
    Check,
    ArrowDownward,
    ArrowUpward,
    ArrowBack,
    ArrowForward,
    Search, WarningOutlined
} from '@material-ui/icons';
import * as React from 'react';


export type ARCIconSizes = 'sm' | 'md';
export type ARCIcons = 'add-user' |
                       'users' |
                       'power' |
                       'graph' |
                       'alert' |
                       'charge' |
                       'envelope' |
                       'phone' |
                       'chevron-down' |
                       'close' |
                       'add-device' |
                       'future' |
                       'checkmark' |
                       'arrow-down' |
                       'arrow-up' |
                       'arrow-left' |
                       'arrow-right' |
                       'magnify' |
                       'warning' |
                       'qr-code' |
                       'book';


export type ARCIconType = {
    icon: ARCIcons,
    size: ARCIconSizes,
    className?: string,
    color? : string
}

export const ARCIcon:React.FC<ARCIconType> = (props) => {

    const iconClass = React.useMemo(() =>{
        return `u-icon-${props.size} ${props.className ?? ''}`
    }, [props.className, props.size]);

    const iconColor = React.useMemo(() => {
        return props.color ?? 'currentColor';
    }, [props.color]);

    const iconComponent = React.useMemo(() => {
        switch (props.icon) {
            case 'add-user':
                return <PersonAdd className={iconClass} style={{color: iconColor}}/>;
            case 'users':
                return <GroupOutlined className={iconClass} style={{color: iconColor}}/>;
            case 'power':
                return <PowerSettingsNew className={iconClass} style={{color: iconColor}}/>;
            case 'graph':
                return <Assessment className={iconClass} style={{color: iconColor}}/>;
            case 'alert':
                return <InfoOutlined className={iconClass} style={{color: iconColor}}/>;
            case 'charge':
                return <BatteryChargingFullOutlined className={iconClass} style={{color: iconColor}}/>;
            case 'envelope':
                return <Mail className={iconClass} style={{color: iconColor}}/>;
            case 'phone':
                return <PhoneInTalk className={iconClass} style={{color: iconColor}}/>;
            case 'chevron-down':
                return <KeyboardArrowDown className={iconClass} style={{color: iconColor}}/>;
            case 'close':
                return <Clear className={iconClass} style={{color: iconColor}}/>;
            case 'add-device':
                return <Smartphone className={iconClass} style={{color: iconColor}}/>;
            case 'future':
                return <UpdateOutlined className={iconClass} style={{color: iconColor}}/>;
            case 'checkmark':
                return <Check className={iconClass} style={{color: iconColor}}/>;
            case 'arrow-down':
                return <ArrowDownward className={iconClass} style={{color: iconColor}}/>;
            case 'arrow-up':
                return <ArrowUpward className={iconClass} style={{color: iconColor}}/>;
            case 'arrow-left':
                return <ArrowBack className={iconClass} style={{color: iconColor}}/>;
            case 'arrow-right':
                return <ArrowForward className={iconClass} style={{color: iconColor}}/>;
            case 'magnify':
                return <Search className={iconClass} style={{color: iconColor}}/>;
            case 'warning':
                return <WarningOutlined className={iconClass} style={{color: iconColor}}/>;
            case 'qr-code':
                return <svg width="24px" height="24px" viewBox="0 0 22 22" className={iconClass} fill={iconColor} xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M22 0H12.375V9.625H22V0ZM15.125 6.875V2.75H19.25V6.875H15.125ZM9.625 12.375H0V22H9.625V12.375ZM2.75 19.25V15.125H6.875V19.25H2.75ZM0 0H3.4375V3.4375H0V0ZM6.1875 0H9.625V3.4375H6.1875V0ZM3.4375 6.1875H0V9.625H3.4375V6.1875ZM6.1875 6.1875H9.625V9.625H6.1875V6.1875ZM18.5625 12.375H15.8125V15.8125H12.375V18.5625H15.8125V22H18.5625V18.5625H22V15.8125H18.5625V12.375Z" fill={iconColor}/>
                       </svg>
            case 'book':
                return <svg width="24px" height="24px" viewBox="0 -960 960 960" className={iconClass} fill={iconColor} xmlns="http://www.w3.org/2000/svg">
                            <path d="M480-160q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740v484q51-32 107-48t113-16q36 0 70.5 6t69.5 18v-480q15 5 29.5 10.5T898-752q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59Zm80-200v-380l200-200v400L560-360Zm-160 65v-396q-33-14-68.5-21.5T260-720q-37 0-72 7t-68 21v397q35-13 69.5-19t70.5-6q36 0 70.5 6t69.5 19Zm0 0v-396 396Z"/>
                       </svg>
        }
    }, [iconClass, iconColor, props.icon]);

    return (
        <>
            {iconComponent}
        </>
    )
}

export function isArcIcon(obj: any): obj is ARCIconComponentType {
    return obj.icon !== undefined;
}

export type ARCIconComponentType = React.FC<ARCIconType>;
