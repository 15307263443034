import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {FormControl, FormControlLabel, IconButton, Radio, RadioGroup} from "@material-ui/core";
import {appContextSelector, sortedDeviceIssuesSelector} from "../../app-context/appContextSlice";
import {
    deactivateDevice,
    deviceContextActions,
    deviceContextSelector,
    getDeviceForEdit,
    getDeviceStats, updateDuplicateList
} from "../deviceSlice";
import {DEV_STATUS_INACTIVE, DeviceDto, DeviceStatDto, INACTIVE_REASONS} from "../../../dtos/Device";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {BLACK, RED} from "../../../themes/colors";
import {calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {makeStyles, styled} from "@material-ui/core/styles";
import {AppWithFooter} from "../../../App";
import {
    BoldText,
    BoldTextSm,
    CenteredDiv,
    DeviceStatsDiv,
    ErrorMessageSectionItem,
    MainContentContainer,
    SmText,
    WrappingContainer,
    XSmallSpacer
} from "../../ui-components/StyledComponents";
import {selectLocationThunk} from "../../app-context/thunks/SelectLocationThunk";
import {LocationState} from "../../../@types/types";
import {ColorCheckbox} from "../../ui-components/ColorCheckbox";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {ArrowBack} from "@material-ui/icons";
import {DeactivateDeviceNotes} from "./DeactivateDeviceNotes";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";


const useAutoSelectLocationByUrl = () => {
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const {locationId, deviceId} = useParams();

    React.useEffect(() => {
        if(locationId && context.currentLocation?.locationId !== parseInt(locationId)) {
            dispatch(selectLocationThunk(parseInt(locationId)));

            if(deviceId) {
                dispatch(getDeviceForEdit({locationId: parseInt(locationId), deviceId: parseInt(deviceId)}));
            }
        }
        else if(deviceId && context.currentLocation?.locationId) {
            dispatch(getDeviceForEdit({locationId: context.currentLocation.locationId, deviceId: parseInt(deviceId)}));
        }

    }, [context.currentLocation?.locationId, dispatch, deviceId, locationId]);
}

export const DeactivateDevice = () => {
    useAutoSelectLocationByUrl();
    const styles = useStyles();

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);
    const deviceStats: DeviceStatDto | undefined = deviceContext.selectedDeviceStats;
    const brandDeviceIssues = useAppSelector(sortedDeviceIssuesSelector);

    const [formDevice, setFormDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_INACTIVE,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const [navBackDeviceId, setNavBackDeviceId] = React.useState<number | undefined>(undefined);
    const [showNotes, setShowNotes] = React.useState<boolean>(false);

    const {state} = useLocation() as LocationState;
    React.useEffect(() => {
        if(state) {
            const { editingFromDeviceId } = state; // Read values passed on state
            if(editingFromDeviceId) {
                setNavBackDeviceId(editingFromDeviceId);
            }
        }
    }, [state]);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetDeactivateStatus())}
    }, [dispatch]);

    const stateDevice = deviceContext.selectedDevice;

    React.useEffect(() => {
        if(stateDevice) {
            setFormDevice(stateDevice);

            if(stateDevice.deviceId && context.currentLocation?.locationId) {
                dispatch(getDeviceStats({deviceId: stateDevice.deviceId, locationId: context.currentLocation.locationId}));
            }
        }
    }, [context.currentLocation?.locationId, dispatch, stateDevice]);

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(deactivateDevice({deviceDto: formDevice, locationId: context.currentLocation?.locationId}));
        }
    }, [context.currentLocation?.locationId, dispatch, formDevice]);
    
    const nextDisabled = React.useMemo(() => {
        if (!formDevice.inactiveReason || formDevice.inactiveReason?.length === 0) {
            return true;
        } else if (formDevice.inactiveReason === INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL) {
            if (!formDevice.deviceIssues || formDevice.deviceIssues?.length === 0) {
                if (brandDeviceIssues.length > 0) {
                    return true;
                }
            }
        }
        return false;
    },[formDevice.inactiveReason, formDevice.deviceIssues, brandDeviceIssues])

    React.useEffect(() => {
        if(deviceContext.deactivateSuccessful) {
            navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`, 
            {state: {editingFromDeviceId: formDevice.deviceId, editDeviceFrom: 'deactivate', editingFromDeviceIdNumber: formDevice.deviceIdNumber, editingFromDeviceTag: formDevice.deviceTag}});
            dispatch(deviceContextActions.resetDeactivateStatus());
            if (deviceContext.importResult && state?.portalUploadStatusId && state?.duplicateListUpdated) {
                dispatch(updateDuplicateList({
                    portalUploadStatusId: state?.portalUploadStatusId,
                    duplicateList: state?.duplicateListUpdated,
                }));
            }
        }
    }, [context.currentBrand?.brandId, 
              context.currentLocation?.locationId, 
              deviceContext.deactivateSuccessful, 
              dispatch, 
              formDevice.deviceId, 
              formDevice.deviceIdNumber, 
              formDevice.deviceTag, 
              navigate,
              deviceContext.importResult, 
              state?.duplicateListUpdated, 
              state?.portalUploadStatusId]);

    return  (
        <AppWithFooter>
            <AppBar/>
            <CenteredDiv>
            <SideBar/>

            <MainContentContainer style={{alignItems: 'unset', padding: 0}}>
                {deviceContext.deactivateErrorMessage &&
                    <ServerMessage variant={'error'}>
                        <ErrorMessageSectionItem>
                            {deviceContext.deactivateErrorMessage}
                        </ErrorMessageSectionItem>
                    </ServerMessage>
                }
                {showNotes ?
                    <DeactivateDeviceNotes
                        formDevice={formDevice}
                        setFormDevice={setFormDevice}
                        setShowNotes={setShowNotes}
                        submitForm={submitForm}
                        styles={styles}
                    />
                :
                    <div className={styles.form_root}>
                        <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                            if (navBackDeviceId) {
                                navigate(`/devices/edit/locationId=${context.currentLocation?.locationId}/deviceId=${navBackDeviceId}`);
                            } else {
                                navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
                            }
                        }}>
                            <ArrowBack className={'back-button-arrow'}/>
                        </IconButton>

                        <div style={{width: '80%'}}>
                            <div className={styles.form_title_div}>
                                <div className={styles.form_title_text}>
                                    {getLocalizedString('deactivateDevice.title', 'Deactivate Device')} {formDevice.deviceTag}
                                </div>
                            </div>


                            {deviceStats &&
                                <DeviceStatsDiv>
                                    <BoldText variant='h6'>{getLocalizedString('deactivateDevice.stats', 'Device Stats')}</BoldText>
                                
                                    <XSmallSpacer/>
                                
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                        <BoldTextSm style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastPickup', 'Last Pickup:')}</BoldTextSm>
                                        <SmText>
                                            {deviceStats.lastPickup}
                                        </SmText>
                                    </div>
                                
                                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                                        <BoldTextSm style={{marginRight: '1vw'}}>{getLocalizedString('deactivateDevice.stats.lastReturn', 'Last Return:')}</BoldTextSm>
                                        <SmText>
                                            {deviceStats.lastReturn}
                                        </SmText>
                                    </div>
                                
                                    <XSmallSpacer/>
                                
                                    <BoldTextSm>{getLocalizedString('deactivateDevice.stats.recentIssues', 'Most Recent Reported Issues:')}</BoldTextSm>
                                    {
                                        deviceStats.recentIssues?.map(issue => {
                                            return <SmText>{issue}</SmText>
                                        })
                                    }
                                
                                </DeviceStatsDiv>
                            }


                            <BoldText variant='h6' style={{display: 'flex'}}>
                                <span>
                                    {getLocalizedString('deactivateDevice.inactiveReason', 'Select Deactivation Reason:')}
                                    <RedBox>*</RedBox>
                                </span>
                            </BoldText>

                            <FormControl className={styles.inactive_reason}>
                                <RadioGroup className={styles.input_text}
                                            aria-label="inactive-reason"
                                            name="row-radio-buttons-group"
                                            value={formDevice.inactiveReason}
                                            onChange={(event: { target: { value: any; }; }) =>
                                                setFormDevice({
                                                    ...formDevice,
                                                    inactiveReason: event.target.value,
                                                    deviceIssues: INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL === event.target.value ? formDevice.deviceIssues : []
                                                })
                                            }>
                                    {
                                        Object.values(INACTIVE_REASONS).map((option) => {
                                            return (
                                                <div className={styles.reason_detail}>

                                                    {(INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL === option && (brandDeviceIssues !== undefined && brandDeviceIssues?.length) > 0) ?
                                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                                            <FormControlLabel style={{marginRight: '5px'}}
                                                                              checked={formDevice.inactiveReason === option}
                                                                              value={option}
                                                                              control={<ColorRadio color="default"/>}
                                                                              label={option}/>
                                                            <WrappingContainer>
                                                                {brandDeviceIssues?.map((issue) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            style={{width: '250px'}}
                                                                            label={issue.deviceIssueType}
                                                                            control={
                                                                                <ColorCheckbox
                                                                                    checked={!!formDevice.deviceIssues?.some(deviceIssue => deviceIssue.brandDeviceIssueId === issue.brandDeviceIssueId)}
                                                                                    onChange={event => {
                                                                                        if (event.target.checked) {
                                                                                            if (formDevice.deviceIssues) {
                                                                                                setFormDevice({
                                                                                                    ...formDevice,
                                                                                                    inactiveReason: INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL,
                                                                                                    deviceIssues: [...formDevice.deviceIssues, issue]
                                                                                                });
                                                                                            } else {
                                                                                                setFormDevice({
                                                                                                    ...formDevice,
                                                                                                    inactiveReason: INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL,
                                                                                                    deviceIssues: [issue]
                                                                                                });
                                                                                            }
                                                                                        } else {
                                                                                            setFormDevice({
                                                                                                ...formDevice,
                                                                                                inactiveReason: INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL,
                                                                                                deviceIssues: formDevice.deviceIssues?.filter(deviceIssue => deviceIssue.brandDeviceIssueId !== issue.brandDeviceIssueId)
                                                                                            })
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                />
                                                                            }/>
                                                                    )
                                                                })}
                                                            </WrappingContainer>
                                                        </div>

                                                        :

                                                        <FormControlLabel style={{marginRight: '5px'}}
                                                                          checked={formDevice.inactiveReason === option}
                                                                          value={option}
                                                                          control={<ColorRadio color="default"/>}
                                                                          label={option}/>
                                                    }

                                                </div>
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>

                            <div className={styles.buttonDiv}>
                                <ARCButton
                                    fill={'filled'}
                                    variant={'primary'}
                                    size={'md'}
                                    id="adduser-save"
                                    disabled={nextDisabled}
                                    onClick={() => setShowNotes(true)}
                                    label={getLocalizedString('deactivateDevice.submit', 'Next')}
                                >
                                </ARCButton>
                            </div>
                        </div>
                    </div>
                }
            </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%'
    },
    form_root: {
        display: 'flex',
        marginTop: '2%',
        flexGrow: 1,
        maxWidth: '70vw'
    },
    form_title_div: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        marginBottom: '5%',
    },
    form_title_text: {
        display: 'flex',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'flex-start',
        textAlign: 'left',
        fontSize: 'clamp(12px, (4vh + 4vw)/2, 37px)',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        opacity: '1',
    },
    input_root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 0,
        padding: 0,
    },
    input_label: {
        position: "initial",
        color: BLACK
    },
    input_text: {
        padding: '1vh 0 0 1vh',
        marginTop: theme.spacing(.5)
    },
    inactive_reason: {
        display: 'flex',
        width: '100%'
    },
    reason_detail: {
        display: 'flex',
        alignItems: 'center'
    },
    reason_detail_label: {
      fontSize: calculateResponsiveSizeWithMinMax('2', '20', '8')
    },
    reason_detail_text: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '11px',
        margin: 0,
        marginBottom: '20px'
    },
    reason_detail_text_disabled: {
        border: '2px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: '11px',
        paddingLeft: '10px'
    },
    reason_detail_required_text: {
        fontSize: 'clamp(6px, (1.5vh + 1.5vw)/2, 20px)',
        opacity: '.5'
    },
    topActionsDiv: {
        display: 'flex',
        width: '50%',
        justifyContent: 'space-evenly'
    },
    buttonDiv: {
        display: 'flex',
        marginTop: '5vh',
        maxWidth: '500px',
        justifyContent: 'flex-start',
        [`${theme.breakpoints.down('md')}`]: {
            maxWidth: 'unset',
        }
    }
}));

const ColorRadio = styled(Radio)(({theme}) => ({
    color: theme.palette.primary.main
}));

const RedBox = styled("span")({
    color: RED
});
