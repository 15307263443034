import React, { useState } from 'react';
import {LIGHTER_GREY, WHITE} from "../../themes/colors";
import {getLocalizedString} from "../../util/Localization";

interface Option {
    name: string,
    value: string
}

interface CustomSearchSelectProps {
    options: Option[];
    disabledOnSingleOption?: boolean;
    className?: string;
    placeHolder?: string;
    value?: string | undefined;
    disabled?: boolean;
    onSelect: (selectedOption: string) => void;
}

const CustomSearchSelect: React.FC<CustomSearchSelectProps> = ({
                                                                   options,
                                                                   disabledOnSingleOption,
                                                                   className,
                                                                   placeHolder,
                                                                   value,
                                                                   disabled,
                                                                   onSelect,
                                                               }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedOption, setSelectedOption] = useState<string | null>(options.find(opt => opt.value === value)?.name ?? null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const searchInputRef = React.useRef<HTMLInputElement>(null);

    const inputDisabled = React.useMemo(() => {
        return disabled || (disabledOnSingleOption && options.length === 1);
    }, [disabled, disabledOnSingleOption, options.length]);

    const handleSearchInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    }, []);

    const handleSearchInputClick = React.useCallback((event: React.MouseEvent<HTMLInputElement>) => {
        event.stopPropagation(); // Prevent the click from propagating to the outer div

        if(!inputDisabled) {
            setIsOpen(true);
        }
    }, [inputDisabled]);

    const handleOptionClick = React.useCallback((option: Option) => {
        setSelectedOption(option.name);
        setIsOpen(false);
        setSearchQuery('');
        onSelect(option.value);
    }, [onSelect]);

    const filteredOptions = React.useMemo(() => {
        return options.filter((option) =>
            option.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
    }, [options, searchQuery]);

    const handleDocumentClick = React.useCallback((event: MouseEvent) => {
        if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        } else {
            if(!inputDisabled) {
                setIsOpen(true);
            }
        }
    }, [inputDisabled]);

    React.useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    React.useEffect(() => {
        if(options.length === 1) {
            handleOptionClick(options[0]);
        }
    }, [handleOptionClick, options]);

    return (
        <div className={className ?? 'custom-search-select'} ref={containerRef}>
            {!isOpen ?
                <div className="custom-search-selected-option">
                    {selectedOption || (placeHolder ?? getLocalizedString('search.select', 'Select an option'))}
                </div>
                :
                <input
                type="text"
                className={'search-dropdown-input'}
                placeholder={getLocalizedString('search.search', 'Search...')}
                value={searchQuery}
                autoFocus
                onChange={handleSearchInputChange}
                onClick={handleSearchInputClick}
                ref={searchInputRef}
                />
            }

            {isOpen && (
                <div className="search-dropdown">
                    <ul>
                        {filteredOptions.map((option) => (
                            <li style={{overflow: 'hidden', textOverflow: 'ellipsis', backgroundColor: option.name === selectedOption ? LIGHTER_GREY : WHITE}} key={option.value} onClick={() => handleOptionClick(option)}>
                                {option.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomSearchSelect;