import { Search } from '@material-ui/icons';
import * as React from 'react';
import { ChangeEvent } from 'react';

type ARCTextFilterInputProps = {
    id: string,
    label?: string,
    placeholder?: string,
    disabled?: boolean;
    value: string | undefined,
    onChange: (event: ChangeEvent<HTMLInputElement>) => void,
    onClear: () => void,
    className?: string
}

export const ARCTextFilterInput : React.FC<ARCTextFilterInputProps> = (props) => {

    return (
        <div className={`o-textinput--filter__container ${props.className ? props.className : ''}`}>
            {props.label &&
            <div className={'u-display--flex u-align-items--center u-justify-content--space-between'}>
                <label className={`o-textinput--filter__label`}>
                    {props.label}
                </label>

                {props.value &&
                <div className={'o-textinput--filter__clear'} onClick={props.onClear}>
                    {'Clear'}
                </div>
                }
            </div>
            }

            <div style={{position: 'relative'}}>
                <input type={'text'}
                       disabled={props.disabled}
                       placeholder={props.placeholder ?? ''}
                       className={`o-textinput--filter`}
                       value={props.value}
                       onChange={(event) => {
                           props.onChange(event);
                       }}
                />
                <Search className={'o-textinput--filter__icon'}/>
            </div>


        </div>
    )
}