import React from 'react';
import './css/PrefixedInput.css';
import {useTheme} from "@material-ui/core";

interface PrefixedInputProps {
    prefix: string | undefined;
    disabled: boolean;
    value: string;
    id: string;
    divStyle?: React.CSSProperties;
    inputStyle?: React.CSSProperties;
    width?: 'wide';
    onChange: (value: string) => void;
}

const PrefixedInput: React.FC<PrefixedInputProps> = ({ prefix, disabled, value, id, divStyle, inputStyle, width, onChange }) => {
    const theme = useTheme();

    const renderValue = React.useMemo(() => {
        if(prefix && value?.startsWith(prefix)) {
            return value?.substring(prefix.length);
        }

        return value;
    }, [prefix, value]);

    const handleEditableChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange((prefix ?? '') + event.target.value);
    };

    return (
        <div id={id} style={{...divStyle, borderColor: divStyle?.borderColor ?? theme.palette.primary.main}} className={`prefixed-input-container${width === 'wide' ? '-wide' : ''} ${disabled ? 'disabled' : ''}`}>
            {prefix &&
                <div className="prefix" style={{...inputStyle}}>
                    {prefix}
                </div>
            }
            <input className="input-field" type="text" value={renderValue} disabled={disabled} 
                style={{...inputStyle, marginRight: 0}} 
                onChange={handleEditableChange}/>
        </div>
    );
};

export default PrefixedInput;
