import {EmployeeGroupDto} from "./EmployeeGroup";
import {LocationDto} from "./Location";
import {DevicePickupPermissionDto} from "./DevicePickupPermission";
import {
    KIOSK_EMPLOYEE,
    KIOSK_MANAGER,
    KIOSK_TEAM_LEAD,
    KioskGroupsType
} from "../features/app-context/containers/UserPermissions";
import {MASTER_BADGE_LENGTH, MASTER_BADGE_START} from "../constants/UserConstants";

export interface EmployeeDto {
    employeeId: number | undefined;
    firstName: string;
    middleName: string | undefined | null;
    lastName: string;
    email: string | undefined | null;
    organizationRole?: string;
    employeeNumber: string;
    badgeId: string;
    employeeLocations: EmployeeLocation[];
    activeAtMultipleLocations?: boolean;
    employeeGroups: EmployeeGroupDto[];
    employeePortalId: number | undefined;
    lastPortalInviteDate: string | undefined;
    sendInvite: boolean;
    devicePickupPermission: DevicePickupPermissionDto | undefined
    reasonForChange?: string;
    employeeHistoryList?: EmployeeHistoryDto[];
}

export const EmployeeValidationKeys = {
    firstName: 'First Name',
    lastName: 'Last Name',
    middleName: 'Middle Name',
    badgeId: 'Badge ID',
    newBadgeId: 'New Badge ID',
    employeeNumber: 'Employee ID'
}

export interface EmployeeHistoryDto {
    employeeHistoryId: number;
    employeeId: number;
    changeDateTime: string;
    reason: string;
    changeUserName: string;
}

export interface EmployeeLocation {
    employeeLocationId?: number;
    location: LocationDto;
    active: boolean;
}

export enum EMPLOYEE_ORDER_BY_TYPES {
    NAME = 'firstName',
    KIOSK_ROLE = 'kioskGroup',
    ClIENT_PORTAL_ROLE = 'portalGroup'
}

export enum EMPLOYEE_STATUS {
    ACTIVE = 'active',
    INACTIVE = 'inactive'
}

export type EmployeeFilterDto = {
    isAsc: boolean,
    nameOrId: string | undefined,
    kioskAccess: KioskGroupsType | undefined,
    orderByString: EMPLOYEE_ORDER_BY_TYPES | undefined,
    status: EMPLOYEE_STATUS | undefined,
    devicePickupPermissionId: number | undefined
}

export const hasPickupPermission = (employee: EmployeeDto) => {
    return employee.employeeGroups.find(group => group.kioskGroup && (group.groupName === KIOSK_EMPLOYEE));
}

export const hasFullPickupPermission = (employee: EmployeeDto) => {
    return employee.employeeGroups.find(group => group.kioskGroup && (group.groupName === KIOSK_TEAM_LEAD || group.groupName === KIOSK_MANAGER));
}

export const isFilterEqual = (f1: EmployeeFilterDto, f2: EmployeeFilterDto) => {
    let isEqual: boolean;
    isEqual = JSON.stringify(f1) === JSON.stringify(f2);
    return isEqual;
}

export const getAccessLevel = (employee: EmployeeDto): number => {
    for(let group of employee.employeeGroups) {
        if(!group.kioskGroup) {
            return group.accessLevel;
        }
    }

    return 0;
}

export const isEqual = (e1: EmployeeDto, e2: EmployeeDto): boolean => {
    let isEqual: boolean;
    const compareTopLayerE1 = {...e1, employeeLocations: [], employeeGroups: [], employeeHistoryList: []};
    const compareTopLayerE2 = {...e2, employeeLocations: [], employeeGroups: [], employeeHistoryList: []};

    isEqual =  JSON.stringify(compareTopLayerE1) === JSON.stringify(compareTopLayerE2) && JSON.stringify(e1.employeeLocations) === JSON.stringify(e2.employeeLocations) && JSON.stringify(e1.employeeGroups) === JSON.stringify(e2.employeeGroups) && JSON.stringify(e1.employeeHistoryList) === JSON.stringify(e2.employeeHistoryList);

    return isEqual;
}

export const isMasterBadgeIdPattern = (badgeId: string) => {
    if (!badgeId) {
        return false;
    }
    
    if (badgeId.startsWith(MASTER_BADGE_START)) {
        return badgeId.length === MASTER_BADGE_LENGTH;
    }
    return false;
}
