import {ARCIcon, ARCIcons} from "./ARCIcon";
import * as React from "react";

export type ARCTextLinkSizeType = 'sm' | 'md';
export type ARCTextLinkVariantType = 'primary' | 'secondary';

type ARCTextLinkType = {
    size: ARCTextLinkSizeType,
    variant: ARCTextLinkVariantType,
    label: string,
    disabled? : boolean,
    icon?: ARCIcons,
    onClick: () => void,
    id?: string
}

export const ARCTextLink : React.FC<ARCTextLinkType> = (props) => {

    const textSizeClass = React.useMemo(() =>{
        if (props.size === 'sm') {
            return 'o-textlink--s';
        } else {
            return 'o-textlink--m';
        }
    }, [props.size]);

    return (
        <div className={`o-textlink__container o-textlink__container--${props.size} o-textlink__container--${props.variant} ${props.disabled ? 'o-textlink__container--disabled' : ''}`}>
            {props.icon && <ARCIcon icon={props.icon} size={props.size}/>}
            <a className={`${textSizeClass} o-textlink--${props.variant}`} onClick={props.onClick}>
                {props.label}
            </a>

        </div>
    )
}

export type ARCTextLinkComponentType = React.FC<ARCTextLinkType>;
