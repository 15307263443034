import {Button, Tooltip} from "@material-ui/core";
import INFO_ICON from "../../../../assets/img/Icon_Material_Info.svg";
import ErrorWrapper, {ErrorWrapperProps} from "../../../ui-components/ErrorWrapper";
import PrefixedInput from "../../../ui-components/PrefixedInput";
import {
    calculateResponsiveSizeWithMinMax,
    isAlphanumeric
} from "../../../../util/TextUtils";
import * as React from "react";
import {DEV_STATUS_PENDING, DeviceDto, DeviceValidationKeys} from "../../../../dtos/Device";
import {useAppSelector} from "../../../../store/hooks";
import {appContextSelector} from "../../../app-context/appContextSlice";
import {Dispatch, SetStateAction} from "react";
import {deviceTypeSelector} from "../../deviceTypeSlice";
import {styled} from "@material-ui/core/styles";
import {DeviceDialog} from "../DeviceDialog";
import ColorSelect, {Option} from "../../../ui-components/ColorSelect";
import {getLocalizedString} from "../../../../util/Localization";
import {ARCButton} from "../../../ui-components/design-system/ARCButton";

type CreateDeviceFormProps = {
    formDevice: DeviceDto,
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    fieldErrors: {[fieldName: string]: {
        error: string | undefined,
        shown: boolean,
    }},
    validateDeviceIdNumber: (input: string) => string,
    validateDeviceTag: (input: string) => string,
    createErrorProps: (field: string) => ErrorWrapperProps,
    setFieldError: (field: string, error: string) => void,
    clearFieldError: (field: string) => void,
    lockDeviceIdNumber: (input: string) => string | undefined,
    deviceIdTooltip: string
}

export const CreateDeviceForm:React.ComponentType<CreateDeviceFormProps> = (props) => {
    const context = useAppSelector(appContextSelector);
    const deviceTypeContext = useAppSelector(deviceTypeSelector);
    const {formDevice, 
            setFormDevice, 
            fieldErrors,
            validateDeviceIdNumber,
            validateDeviceTag,
            createErrorProps,
            setFieldError,
            clearFieldError,
            lockDeviceIdNumber,
            deviceIdTooltip} = props;

    const serialIsDeviceId = React.useMemo(() => {
        if (context.currentLocation?.serialIsDevice) {
            if (deviceTypeContext.deviceTypes.length > 0 && !formDevice.deviceType?.serialIsDevice) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [context.currentLocation, deviceTypeContext.deviceTypes, formDevice.deviceType])
    
    const [editableDevice, setEditableDevice] = React.useState<DeviceDto>({
        deviceId: undefined,
        deviceTag: '',
        deviceIdNumber: '',
        status: DEV_STATUS_PENDING,
        locationId: undefined,
        inactiveReason: '',
        inactiveReasonDetail: '',
        deviceType: undefined
    });

    const DeviceTypeOptions = React.useMemo(() => {
        let options : Option[] = deviceTypeContext.deviceTypes.map(deviceType => {
            return {
                value: deviceType.deviceTypeId,
                label: deviceType.deviceTypeName
            }
        });

        return [{value: undefined, label: getLocalizedString('createDevice.selectDeviceType', 'Select Device Type')}, ...options];
    }, [deviceTypeContext.deviceTypes]);
    
    React.useEffect(() => {
        if(formDevice) {
            setEditableDevice(formDevice);
        }
    }, [formDevice]);
    
    const [showSerialIsDeviceDialog, setShowSerialIsDeviceDialog] = React.useState<boolean>(false);
    
    const [deviceIdNumberLocked, setDeviceIdNumberLocked] = React.useState<boolean>(true);
    const [deviceTagLocked, setDeviceTagLocked] = React.useState<boolean>(true);
    const [deviceTypeLocked, setDeviceTypeLocked] = React.useState<boolean>(true);
    
    const showDeviceTagEdit = React.useMemo(() => {
        if (deviceTypeLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTypeLocked, deviceIdNumberLocked]);
    
    const showDeviceTypeEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceIdNumberLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceIdNumberLocked]);
    
    const showDeviceIdEdit = React.useMemo(() => {
        if (deviceTagLocked && deviceTypeLocked) {
            return true;
        }
    }, [deviceTagLocked, deviceTypeLocked]);
    
    const deviceIdSaveDisabled = React.useMemo(() => {
        let deviceIdNumber = editableDevice.deviceIdNumber;
        let prefix = context.currentBrand?.deviceNumberPrefix; 
        if (prefix && deviceIdNumber === prefix) {
            return true; 
        } else {
            return editableDevice.deviceIdNumber === '';
        }
    }, [editableDevice.deviceIdNumber, context.currentBrand?.deviceNumberPrefix])
    
    const deviceTagSaveDisabled = React.useMemo(() => {
        return editableDevice.deviceTag === '';
    }, [editableDevice.deviceTag])
    
    const deviceTypeOnSave = React.useCallback(() => {
        let newFormDevice = {
            ...formDevice,
            deviceType: editableDevice.deviceType
        }
        
        let showDialog = false;
        if (context.currentLocation?.serialIsDevice) {
            if (formDevice.deviceType && newFormDevice.deviceType?.serialIsDevice) {
                setShowSerialIsDeviceDialog(true);
                showDialog = true;
            }
        }
        if (!showDialog) {
            setFormDevice(newFormDevice)
            setDeviceTypeLocked(!deviceTypeLocked);
        }
    },[context.currentLocation, formDevice, deviceTypeLocked, setFormDevice, editableDevice.deviceType])
    
    const computedTagIdNumber = React.useMemo(() => {
        if (context.currentBrand?.deviceNumberPrefix) {
            if (formDevice.deviceTag?.startsWith(context.currentBrand.deviceNumberPrefix)) {
                return formDevice.deviceTag;
            } else {
                return context.currentBrand?.deviceNumberPrefix + formDevice.deviceTag;
            }
        }
        return formDevice.deviceTag;
    }, [context.currentBrand?.deviceNumberPrefix, formDevice.deviceTag])
    
    const serialIsDeviceDialogSubmit = React.useCallback(() => {
        let newFormDevice = {
            ...formDevice,
            deviceIdNumber: computedTagIdNumber,
            deviceTag: computedTagIdNumber,
            deviceType: editableDevice.deviceType,
        }
        setFormDevice(newFormDevice)
        setDeviceTypeLocked(!deviceTypeLocked);
        setShowSerialIsDeviceDialog(false)
    }, [formDevice, setFormDevice, deviceTypeLocked, computedTagIdNumber, editableDevice.deviceType])
    
    const serialIsDeviceDialogCancel = React.useCallback(() => {
        setShowSerialIsDeviceDialog(false);
    }, [setShowSerialIsDeviceDialog])
    
    const isDeviceIdValid = React.useMemo(() => {
        return validateDeviceIdNumber(computedTagIdNumber).length > 0;
    }, [computedTagIdNumber, validateDeviceIdNumber])
    
    const dialogTitle = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return (
                <div className={'dialog-alert-title'}>
                        {'Changing device type will update the Device ID to '}
                        <span style={{color: 'var(--primary-color)'}}>{computedTagIdNumber}</span>
                </div>
            )
        } else {
            return 'Device type could not be changed';
        }
    }, [isDeviceIdValid, computedTagIdNumber])
    
    const dialogMessages = React.useMemo(() => {
        if (!isDeviceIdValid) {
            return [getLocalizedString('createDevice.serialIsDevice.explain', 'This is because ' + formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as Device ID', [`${formDevice.deviceType?.deviceTypeName}`])];
        } else {
            return [getLocalizedString('createDevice.serialIsDevice.unableToUpdate', formDevice.deviceType?.deviceTypeName + ' uses its Serial Number as the Device ID, but this device\'s Serial Number does not meet the Device ID requirements. Please contact ARC support for help', [`${formDevice.deviceType?.deviceTypeName}`])]
        }
    }, [isDeviceIdValid, formDevice.deviceType?.deviceTypeName])
    
    return (
        <>
            <DeviceDialog
                showDialog={showSerialIsDeviceDialog}
                dialogSubmit={serialIsDeviceDialogSubmit}
                dialogCancel={serialIsDeviceDialogCancel}
                title={dialogTitle}
                messages={dialogMessages}
                isError={isDeviceIdValid}
            >
            </DeviceDialog>
            <div className={'createdevice-confirm-title'}>
                {getLocalizedString('createDevice.conflict.confirm', 'Confirm Device Details')}
            </div>
            <div className={'editdevice-device-id-div'}>
                <div className={'editdevice-device-id-column'} style={{
                    backgroundColor: deviceIdNumberLocked ? 'transparent' : 'rgba(191, 191, 191, 0.2)',
                    paddingLeft: deviceIdNumberLocked ? '0' : '10px',
                    paddingBottom: deviceIdNumberLocked ? '0' : '20px',
                }}>
                    <div className={'editdevice-device-id-row'}>
                        <div className={'editdevice-device-id-title-div'}>
                            <div className={'editdevice-device-id-title'} style={{
                                color: deviceIdNumberLocked ? 'var(--primary-color)' : 'black'
                            }}>
                                {getLocalizedString('createDevice.deviceIdNumber', 'Device ID')}
                                {deviceIdNumberLocked &&
                                    <span className={'editdevice-red-star'}>
                                        {'*'}
                                    </span>
                                }
                            </div>
                            <Tooltip placement={'bottom-start'}
                                     title={deviceIdTooltip}>
                                <img src={INFO_ICON} alt={'Info Icon'} className={'editdevice-info-icon'}/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={'editdevice-device-id-row'}>
                        <ErrorWrapper {...createErrorProps(deviceIdNumberLocked ? `${DeviceValidationKeys.deviceIdNumber}` : '')}>
                            <div className={'editdevice-input-div'}>
                                <PrefixedInput
                                    width={'wide'}
                                    id="adddevice-deviceid"
                                    value={formDevice.deviceIdNumber ?? ''}
                                    disabled={true}
                                    prefix={context.currentBrand?.deviceNumberPrefix}
                                    divStyle={{
                                        border: '0 solid transparent',
                                        borderRadius: '5px',
                                        marginBottom: '2% !important',
                                        marginRight: '20px',
                                        minHeight: '30px',
                                        width: '100%'
                                    }}
                                    inputStyle={{
                                        fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
                                        color: 'black !important',
                                        paddingLeft: '15px',
                                        paddingRight: '3px',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        border: 'none',
                                        marginRight: '2px',
                                        backgroundColor: 'rgba(191, 191, 191, .5)'
                                    }}
                                    onChange={(value) => {
                                    }}
                                />
                            </div>
                        </ErrorWrapper>
                        {deviceIdNumberLocked &&
                            <EditDeviceButton
                                id="editdeviceid"
                                disabled={!showDeviceIdEdit}
                                style={{marginLeft: '20px'}}
                                onClick={() => {
                                    setDeviceIdNumberLocked(false)
                                    setEditableDevice({
                                        ...formDevice,
                                        deviceIdNumber: ''
                                    });
                                }}
                            >
                                {getLocalizedString('edit', 'Edit')}
                            </EditDeviceButton>
                        }
                    </div>
                    {
                        <div style={{marginTop: '16px', display: deviceIdNumberLocked ? 'none' : 'block'}}>
                            <div className={'editdevice-device-id-row'}>
                                <div className={'editdevice-device-id-title-div'}>
                                    <div className={'editdevice-device-id-title'}>
                                        {getLocalizedString('createDevice.newDeviceIdNumber', 'New Device ID')}
                                    </div>
                                </div>
                            </div>
                            <div className={'editdevice-device-id-row'}>
                                <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceIdNumber}`)}>
                                    <div className={'editdevice-input-div'}>
                                        <PrefixedInput
                                            width={'wide'}
                                            id="adddevice-deviceid"
                                            value={editableDevice.deviceIdNumber}
                                            disabled={deviceIdNumberLocked}
                                            prefix={context.currentBrand?.deviceNumberPrefix}
                                            divStyle={{
                                                borderRadius: '5px !important',
                                                borderWidth: '1px solid',
                                                borderColor: 'black',
                                                marginBottom: '2% !important',
                                                marginRight: '20px'
                                            }}
                                            inputStyle={{
                                                fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
                                                color: 'black !important',
                                                paddingLeft: '15px',
                                                paddingRight: '3px',
                                                paddingTop: '3px',
                                                paddingBottom: '3px',
                                            }}
                                            onChange={(value) => {
                                                const error = lockDeviceIdNumber(value);
                                                if (error) {
                                                    const showCharacter = !isAlphanumeric(value) && (fieldErrors[`${DeviceValidationKeys.deviceIdNumber}`]?.error?.length ?? 0) === 0;
                                                    if (showCharacter) {
                                                        setEditableDevice({
                                                            ...formDevice,
                                                            deviceIdNumber: value.toUpperCase(),
                                                            deviceTag: serialIsDeviceId ? value.toUpperCase() : editableDevice.deviceTag
                                                        })
                                                    }
                                                    setFieldError(DeviceValidationKeys.deviceIdNumber, error)
                                                } else {
                                                    setEditableDevice({
                                                        ...formDevice,
                                                        deviceIdNumber: value.toUpperCase(),
                                                        deviceTag: serialIsDeviceId ? value.toUpperCase() : editableDevice.deviceTag
                                                    })
                                                    clearFieldError(DeviceValidationKeys.deviceIdNumber)
                                                }
                                            }}
                                        />
                                    </div>
                                </ErrorWrapper>
                                <span className={'editdevice-edit-element'}
                                      onClick={
                                          () => {
                                              setDeviceIdNumberLocked(!deviceIdNumberLocked);
                                              setEditableDevice({
                                                  ...formDevice
                                              });
                                          }
                                      }>
                                    {getLocalizedString('cancel', 'Cancel')}
                                </span>
                            </div>

                            <div className={'editdevice-device-id-row'} style={{
                                marginTop: '40px'
                            }}>
                                <SaveDeviceButton
                                    id="editdeviceid"
                                    variant='contained'
                                    onClick={() => {
                                            setFormDevice({
                                                ...formDevice,
                                                deviceIdNumber: editableDevice.deviceIdNumber,
                                                deviceTag: serialIsDeviceId ? editableDevice.deviceIdNumber : formDevice.deviceTag
                                            })
                                            setDeviceIdNumberLocked(!deviceIdNumberLocked);
                                        }
                                    }
                                    disabled={deviceIdSaveDisabled}
                                >
                                    {getLocalizedString('createDevice.deviceIdNumber.save', 'Save Device ID')}
                                </SaveDeviceButton>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <div className={'createdevice-other-div'}>
                <div className={'createdevice-device-tag-title-div'}>
                    <div className={'createdevice-device-tag-title'}>
                        {getLocalizedString('createDevice.deviceTag.title', 'Device Serial Number')}
                        <span className={'createdevice-red-star'}>
                            {'*'}
                        </span>
                    </div>
                    <Tooltip placement={'bottom-start'}
                             title={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}>
                        <img src={INFO_ICON} alt={'Info Icon'}/>
                    </Tooltip>
                </div>

                <div className={'editdevice-device-tag-row'}>
                    <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                        <div className={'editdevice-input-div'}>
                            <input
                                autoComplete={"device tag"}
                                id="addDevice-deviceTag"
                                type="text"
                                required={true}
                                value={editableDevice.deviceTag}
                                disabled={deviceTagLocked || serialIsDeviceId}
                                className={'editdevice-device-tag-textfield'}
                                onChange={(event) => {
                                    const error = validateDeviceTag(event.target.value);
                                    if (error) {
                                        const showCharacter = !isAlphanumeric(event.target.value) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                        if (showCharacter) {
                                            setEditableDevice({
                                                ...formDevice,
                                                deviceTag: event.target.value
                                            })    
                                        }
                                        setFieldError(DeviceValidationKeys.deviceTag, error);
                                    } else {
                                        setEditableDevice({
                                            ...formDevice,
                                            deviceTag: event.target.value
                                        })
                                        clearFieldError(DeviceValidationKeys.deviceTag);
                                    }
                                }}
                            />
                        </div>
                    </ErrorWrapper>
                    {!serialIsDeviceId &&
                        <button
                            className={'editdevice-edit-element'}
                            type={'button'}
                            style={{marginLeft: '20px'}}
                            onClick={
                                () => {
                                    setDeviceTagLocked(!deviceTagLocked);
                                    !deviceTagLocked &&
                                    setEditableDevice({
                                        ...formDevice,
                                    })
                                }
                            }
                            disabled={!showDeviceTagEdit}>
                            {deviceTagLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel','Cancel')}
                        </button>
                    }
                </div>
                {!deviceTagLocked &&
                    <div className={'editdevice-device-id-row'} style={{
                        marginBottom: '10px'
                    }}>
                        <SaveDeviceButton
                            id="createDeviceId"
                            variant='contained'
                            onClick={
                                () => {
                                    setFormDevice({
                                        ...formDevice,
                                        deviceTag: editableDevice.deviceTag
                                    })
                                    setDeviceTagLocked(!deviceTagLocked);
                                }
                            }
                            disabled={deviceTagSaveDisabled}
                        >
                            {getLocalizedString('createDevice.deviceTag.save', 'Save Serial Number')}
                        </SaveDeviceButton>
                    </div>
                }
                {(deviceTypeContext.deviceTypes && deviceTypeContext.deviceTypes.length > 0) &&
                    <>
                        <div className={'createdevice-device-type-title-div'}>
                            <div className={'createdevice-device-type-title'}>
                                {getLocalizedString('createDevice.deviceType', 'Device Type')}
                                <span className={'createdevice-red-star'}>
                                    {'*'}
                                </span>
                            </div>
                        </div>
                        <div className={'editdevice-device-tag-row'}>
                            <div className={'editdevice-input-div'}>
                                <ColorSelect
                                    className={'createdevice-device-type-select'}
                                    variant="outline"
                                    value={editableDevice.deviceType?.deviceTypeId}
                                    options={DeviceTypeOptions}
                                    disabled={deviceTypeLocked}
                                    onChange={(option) => {
                                        setEditableDevice({
                                            ...formDevice,
                                            deviceType: option.value !== '' ? deviceTypeContext.deviceTypes.find(dType => dType.deviceTypeId === option.value) : undefined
                                        });
                                    }}
                                />
                            </div>
                            <button
                                className={'editdevice-edit-element'}
                                type={'button'}
                                style={{marginLeft: '20px'}}
                                onClick={
                                    () => {
                                        setDeviceTypeLocked(!deviceTypeLocked);
                                        !deviceTypeLocked &&
                                            setEditableDevice({
                                                ...formDevice,
                                            })
                                    }
                                }
                                disabled={!showDeviceTypeEdit}>
                                {deviceTypeLocked ? getLocalizedString('edit', 'Edit') : getLocalizedString('cancel', 'Cancel')}
                            </button>
                            
                        </div>
                        {!deviceTypeLocked &&
                            <div className={'editdevice-device-id-row'} style={{
                                marginBottom: '10px'
                            }}>
                                <SaveDeviceButton
                                    id="createDeviceType"
                                    variant='contained'
                                    onClick={
                                        () => {
                                            deviceTypeOnSave()
                                        }
                                    }
                                >
                                    {getLocalizedString('createDevice.deviceType.save', 'Save Device Type')}
                                </SaveDeviceButton>
                            </div>
                        }
                    </>
                }
            </div>
            <ButtonDiv>
                <ARCButton
                    id="adddevice-save"
                    variant={'primary'}
                    size={'md'}
                    fill={'filled'}
                    submit={true}
                    onClick={() => {}}
                    label={getLocalizedString('createDevice.submit', 'Save')}
                >
                </ARCButton>
            </ButtonDiv>
        </>
    )
}

const ButtonDiv = styled('div')(({theme}) => ({
    display: 'flex',
    width: '100%',
    maxWidth: '800px',
    marginTop: '30px',
    justifyContent: 'flex-start'
}));

const SaveDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '10px 20px 10px 20px !important',
    minWidth: '0px !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const EditDeviceButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal normal 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '3px 20px 3px 20px !important',
    minWidth: '0px !important',
    marginLeft: '20px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});
