import * as React from 'react';
import AppBar from "../../appbar/AppBar";
import '../../../css/EmergencyPortal.css';
import {GridSize, GridSpacing} from "@material-ui/core";

type EmergencyPortalAppBarProps = {
    left?: React.ReactNode,
    right?: React.ReactNode,
    title?: React.ReactNode,
    logoLabel?: string,
    leftGridWidth?: GridSize,
    midGridWidth?: GridSize,
    rightGridWidth?: GridSize,
    gridSpacing?: GridSpacing,
    alwaysShowLabel?: boolean,
    sx?: {},
    className?: string,
    static?: boolean,
    logoNonInteractive?: boolean,
    hideBgImage?: boolean,
    hideGlobalMessages?: boolean
};

export const EmergencyPortalAppBar:React.ComponentType<EmergencyPortalAppBarProps> = (props) => {
    return (
        <AppBar
                title={props.title ?? <></>}
                className={'emergency-app-bar'}
                hideGlobalMessages={props.hideGlobalMessages ?? true}
                logoNonInteractive={props.logoNonInteractive ?? true}
                leftGridWidth={props.leftGridWidth}
                midGridWidth={props.midGridWidth}
                rightGridWidth={props.rightGridWidth}
                gridSpacing={props.gridSpacing}
        />
    )
}
