import { styled } from "@material-ui/core/styles";
import {Button, Checkbox, Container, Grid, FormControl, InputLabel, Link, Table, TextareaAutosize, TextField, TableCell, Typography} from "@material-ui/core";
import {BLACK, GREEN, DARK_GREY, BLUE, WHITE, RED, LIGHTER_GREY, LIGHT_RED} from "../../themes/colors";
import {
    calculatedFontSize,
    calculatedSizeMinBasedOnWidthHeightScaler,
    calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {
    Check, Clear,
    Close,
    EditOutlined,
    HelpOutlineRounded, IndeterminateCheckBoxRounded, PriorityHigh
} from "@material-ui/icons";

export const LogoDiv = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15vh'
});

export const LogoDivHover = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '15vh',
    "&:hover": {
        cursor: "pointer"
    }
});

export const MainContentContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    float: 'left',
    width: '84vw',
    margin: '2vh 6vw 0 6vw',
    minWidth: 'unset',
    maxWidth: 'unset'
}));

export const SuccessMessageSection = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#C5F6E2',
    marginBottom: '10px',
    padding: '16px',
    borderTop: '2px solid #00975B',
    borderBottom: '2px solid #00975B'
});

export const SuccessMessageSectionItem = styled(Typography)({
    width: '100%',
    fontWeight: 'bold',
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: DARK_GREY
});

export const ErrorMessageSection = styled(Typography)({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#FFE4A7',
    marginBottom: '10px',
    padding: '16px',
    borderTop: '2px solid #FDBE31',
    borderBottom: '2px solid #FDBE31'
});

export const ErrorMessageSectionItem = styled(Typography)({
    width: '100%',
    fontWeight: 'bold',
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: DARK_GREY
});

export const SuccessMessageText = styled(Typography)({
    width: '100%',
    fontWeight: 600,
    fontSize: calculatedFontSize('1em', '1.2em'),
    color: GREEN,
    marginBottom: '10px'
});

export const ErrorMessageText = styled(Typography)(({theme}) => ({
    width: '100%',
    fontWeight: 600,
    fontSize: calculatedFontSize('1.1em', '1.4em'),
    color: theme.palette.warning.main,
    marginBottom: '10px'
}));

export const BoldText = styled(Typography)(({theme}) => ({
    fontWeight: 650,
    fontSize: calculatedFontSize('1.1em', '1.4em')
}));

export const BoldTextSm = styled(Typography)(({theme}) => ({
    fontWeight: 650,
    fontSize: calculatedFontSize('.7em', '1.1em')
}));

export const ResponsiveInputLabelThinSm = styled(InputLabel) (({theme}) => ({
    display: 'flex',
    minWidth: '200px',
    [theme.breakpoints.down('xs')]: {
        minWidth: '70px',
        maxWidth: '70px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    fontWeight: 550,
    color: BLACK
}));

export const NextButton = styled(Button)({
    maxHeight: '40px',
    borderRadius: '10px',
    borderWidth: '0',
    width: '40%',
    fontSize: calculateResponsiveSizeWithMinMax('2', '19', '8'),
    color: '#FFFFFF',
    background: '#517D9E',
    letterSpacing: '0',
    padding: '5px 20px 5px 20px !important',
    minWidth: '0px !important',
    textTransform: 'none',
    marginTop: '40px',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

export const ResponsiveInputLabelThin = styled(InputLabel) (({theme}) => ({
    display: 'flex',
    minWidth: '200px',
    fontSize: calculatedFontSize('1.2em', '1.25em'),
    [theme.breakpoints.down('xs')]: {
        minWidth: '125px',
        maxWidth: '125px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    fontWeight: 550,
    color: BLACK
}));

export const ResponsiveInputLabel = styled(InputLabel) (({theme}) => ({
    display: 'flex',
    minWidth: '250px',
    fontSize: calculatedFontSize('1.2em', '1.25em'),
        [theme.breakpoints.down('xs')]: {
        minWidth: '125px',
        maxWidth: '125px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    fontWeight: 550,
    color: BLACK
}));

export const ResponsiveTextField =  styled(TextField) (({theme}) => ({
    margin: '2vh 0 0 1vh',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '11px',
    width: '30vw',
    maxWidth: '450px',
    minWidth: '150px',
    [theme.breakpoints.down('md')]: {
        width: '80vw',
        maxWidth: '450px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
}));

export const ResponsiveTextFieldWide = styled(TextField) (({theme}) => ({
    margin: '2vh 0 0 1vh',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '11px',
    width: '60vw',
    maxWidth: '600px',
    minWidth: '150px',
    [theme.breakpoints.down('md')]: {
        width: '80vw',
        maxWidth: '450px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
}));

export const ResponsiveTextAreaTall = styled(TextareaAutosize) (({theme}) => ({
    margin: '1vh 0 0 1vh',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '11px',
    minHeight: '8vh',
    width: '60vw',
    maxWidth: '800px',
    minWidth: '150px',
    "@media (max-width:600px)": {
        width: '80vw',
        maxWidth: '300px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
}));

export const CheckBoxColorBorder = styled(Checkbox) (({theme}) => ({
    color: theme.palette.primary.main
}));

export const UndeterminedCheckboxIcon = styled(IndeterminateCheckBoxRounded)(({theme}) => ({
    color: theme.palette.primary.main,
    backgroundColor: WHITE,
    border: 'none'
}));

export const WrappingContainer = styled('div') ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginLeft: '4vw',
    marginBottom: '2vh',
    width: '80%',
    maxWidth: '700px'
});

export const DeviceStatsDiv = styled('div') ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '850px',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#F5DBA3',
    marginBottom: '3vh'
});

export const LoginButton = styled(Button) (({theme}) => ({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 550 0 "Proxima Nova"',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('2.25'),
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    width: '100%',
    padding: '2% 0 2% 0 !important',
    minWidth: '0px !important',
    height: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#FBBC41',
      opacity: '.5'
    }
}));

export const ActionButton = styled(Button) (({theme}) => ({
    color: 'white',
    padding: '20px',
    margin: '15px 0px 45px 0px',
    width: 'fit-content',
    maxWidth: '250px',
    fontSize: calculatedFontSize('1.15em', '1.3em'),
    height: '7%',
    minHeight: '30px',
    maxHeight: '50px',
    backgroundColor: theme.palette.primary.dark,
    borderRadius: '10px',
    textTransform: 'none'
}));


export const HiddenOnMobileDiv = styled('div')(({theme}) => ({
    [theme.breakpoints.down('xs')]: {
        display: 'none'
    },
}));

export const CancelButton = styled(Button)(({theme}) => ({
    color: 'white',
    width: '15vw',
    minWidth: '120px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.4em'),
    height: '10vh',
    maxHeight: '55px',
    minHeight: '40px',
    backgroundColor: DARK_GREY,
    borderRadius: '49px',
    marginLeft: '40px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        marginLeft: '7vw',
        minHeight: '30px',
        height: '5vh',
        minWidth: '100px',
    }
}));

export const SaveButton = styled(Button) (({theme}) => ({
    color: 'white',
    width: '15vw',
    minWidth: '120px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.4em'),
    height: '10vh',
    maxHeight: '55px',
    minHeight: '40px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '49px',
    marginLeft: '40px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        marginLeft: '7vw',
        minHeight: '30px',
        height: '5vh',
        minWidth: '100px',
    }
}));

export const SquareSaveButton = styled(Button) (({theme}) => ({
    color: 'white',
    width: '15vw',
    minWidth: '175px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.3em'),
    height: '7vh',
    maxHeight: '45px',
    minHeight: '30px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        marginLeft: '7vw',
        minHeight: '30px',
        height: '5vh',
        minWidth: '100px',
    }
}));

export const SquareSaveButtonSm = styled(SquareSaveButton) (({theme}) => ({
    width: '10vw',
    minWidth: '100px',
    maxWidth: '150px',
    fontSize: calculatedFontSize('.75em', '1.1em'),
    padding: '5px',
    height: '5vh',
    maxHeight: '40px',
    minHeight: '30px',
    borderRadius: '5px',
}));

export const EditButton = styled(Button) (({theme}) => ({
    color: WHITE,
    width: '15vw',
    minWidth: '175px',
    maxWidth: '200px',
    fontSize: calculatedFontSize('.75em', '1.3em'),
    height: '7vh',
    maxHeight: '45px',
    minHeight: '30px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
    marginTop: '10px',
    textTransform: 'none',
    [`${theme.breakpoints.down('md')}`]: {
        minHeight: '30px',
        height: '5vh',
    }
}));

export const EditButtonSm = styled(EditButton) (({theme}) => ({
    width: '5vw',
    minWidth: '50px',
    maxWidth: '80px',
    height: '5vh',
    maxHeight: '35px',
    minHeight: '30px',
    fontSize: calculatedFontSize('.75em', '1.1em'),
    fontWeight: 'bold',
    marginTop: '0px',
    borderRadius: '5px'
}));

export const CircleLabelMd = styled(Typography) ({
    border: '2px solid',
    width: calculateResponsiveSizeWithMinMax('3.5', '35', '8'),
    height: calculateResponsiveSizeWithMinMax('3.5', '35', '8'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: BLACK,
    borderRadius: '50px',
    fontSize: calculateResponsiveSizeWithMinMax('1.5', '35', '8'),
    fontWeight: 500
});

export const SuccessCircleIcon = styled(Check) ({
    border: '2px solid',
    width: '5vw',
    maxWidth: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: BLUE,
    borderColor: BLUE,
    borderRadius: '50px',
    margin: '0 20px 0 0',
    fontSize: calculatedFontSize('.8em', '.95em'),
    fontWeight: 500
});

export const ErrorCircleIcon = styled(PriorityHigh) ({
    border: '2px solid',
    width: '5vw',
    maxWidth: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: RED,
    borderColor: RED,
    borderRadius: '50px',
    margin: '0 20px 0 0',
    fontSize: calculatedFontSize('.8em', '.95em'),
    fontWeight: 500
});

export const BrowseFileButton = styled(Button)(({theme}) => ({
    color: WHITE,
    zIndex: 100,
    textTransform: 'none',
    width: '5vw',
    minWidth: '150px',
    maxWidth: '200px',
    borderRadius: '10px',
    margin: '10px 0 10px 0',
    height: '20vh',
    maxHeight: '40px',
    fontSize: calculatedFontSize('.75em', '.9em'),
    minHeight: '25px',
    backgroundColor: theme.palette.primary.main
}));

export const BrowseFileButtonSm = styled(Button)({
    color: BLACK,
    minWidth: '50px',
    maxWidth: '100px',
    height: '10%',
    fontSize: calculatedFontSize('.75em', '.9em'),
    minHeight: '25px',
    backgroundColor: LIGHTER_GREY,
    marginLeft: '40px'
});

export const Spacer = styled(Typography)({
    marginTop: '7vh'
});

export const SmallSpacer = styled(Typography)({
    marginTop: '4vh'
});

export const SmallBr = styled(Typography)({
    marginTop: '2vh'
})

export const XSmallSpacer = styled(Typography)({
    marginTop: '1vh'
})

export const SmText = styled(Typography)({
    fontSize: calculatedFontSize('.75em', '1.15em')
})

export const CenteredDiv = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));


export const CenterDiv = styled('div') ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
});

export const RowDiv = styled('div') ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
})

export const WrappingDiv = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    "@media (max-width:600px)": {
        flexDirection: 'column'
    }
});

export const UpdateLogHeaderCell = styled(TableCell)(({theme}) => ({
    fontWeight: 550,
    fontSize: calculatedFontSize('.9em', '1em'),
    minWidth: '200px',
    [theme.breakpoints.down('xs')]: {
        minWidth: '70px',
        maxWidth: '70px',
        fontSize: calculatedFontSize('.9em', '1.25em')
    },
    position: "initial",
    color: BLACK
}));

export const UpdateLogCell = styled(TableCell)(({theme}) => ({
    fontWeight: 500,
    fontSize: calculatedFontSize('.9em', '1em'),
    [theme.breakpoints.down('xs')]: {
        fontSize: calculatedFontSize('.8em', '1em'),
    }
}));

export const ScalableTable = styled(Table)(({theme}) => ({
    [`${theme.breakpoints.down('md')}`]: {
        tableLayout: 'fixed'
    }
}));

export const ScalableTableCell = styled(TableCell) ({
    fontWeight: 500,
    fontSize: calculatedFontSize('1em', '1.25em'),
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('.75em', '1.25em'),
        padding: '5px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word'
    }
});

export const ScalableTableCellDesktopOnly = styled(ScalableTableCell) ({
    "@media (max-width:600px)": {
        display: 'none'
    }
});

export const ScalableTableCellSm = styled(TableCell) ({
    fontWeight: 500,
    fontSize: calculatedFontSize('1em', '1.25em'),
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('.75em', '1.25em'),
        padding: '5px',
        width: '40px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word'
    }
});

export const ScalableTableCellSmCentered = styled(ScalableTableCellSm) ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
});

export const ScalableTableHeaderCellMd = styled(TableCell) (({theme}) => ({
    fontWeight: 'bold',
    fontSize: calculatedFontSize('1em', '1em'),
    [`${theme.breakpoints.down('xs')}`]: {
        padding: '10px',
        width: '70px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        wordWrap: 'break-word'
    }
}));

export const TableHeaderDesktopOnlyMd = styled(ScalableTableHeaderCellMd) ({
    "@media (max-width:600px)": {
        display: 'none'
    }
});

export const ScalableGrid = styled(Grid)(({theme}) => ({
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center'
    }
}));

export const ScalableForm = styled('form')(({theme}) => ({
    width: '100%',
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center',
    },
    [`${theme.breakpoints.down('xs')}`]: {
        width: '60vw'
    }
}));

export const ScalableFormControl = styled(FormControl)(({theme}) => ({
    width: '100%',
    [`${theme.breakpoints.down('md')}`]: {
        justifyContent: 'center',
    },
    [`${theme.breakpoints.down('xs')}`]: {
        width: 'unset'
    }
}));

export const FormTitle = styled(Typography)(({theme}) => ({
    width: '100%',
    fontSize: calculateResponsiveSizeWithMinMax('3', '32', '24'),
    color: theme.palette.primary.main,
    fontWeight: 550,
    paddingBottom: '15px'
}));

export const LoginTitle = styled(Typography)(({theme}) => ({
    width: '100%',
    textAlign: 'center',
    color: '#272727',
    fontWeight: 550,
    marginBottom: '10%',
    marginTop: '10%',
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('1.4em', '1.8em')
    }
}));

export const PasswordViewToggle = styled(Link) ({
    color: BLACK,
    margin: '0 20px 0 20px',
    fontSize: calculatedFontSize('1.1em', '1.4em')
})

export const ColoredLink = styled(Link) ({
    cursor: 'pointer',
    color: BLUE,
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '16'),
});

export const ColorHelpIcon = styled(HelpOutlineRounded) (({theme}) => ({
    color: theme.palette.primary.main,
    marginRight: '5px'
}));

export const ColorHelpIconInfo = styled(HelpOutlineRounded) (({theme}) => ({
    color: theme.palette.info.main,
    marginRight: '5px'
}));

export const ToggleFieldEditableIcon = styled(EditOutlined) (({theme}) => ({
    color: theme.palette.primary.main,
    marginTop: '2.3vh',
    marginLeft: '1.5vw',
    border: '2px solid',
    borderRadius: '5px',
    borderColor: theme.palette.primary.main,
    "&:hover": {
        cursor: 'pointer'
    }
}));

export const TopActionButton = styled(Button)(({theme}) => ({
    display: 'flex',
    cursor: 'pointer',
    margin: '10px 10% 10px 0',
    color: WHITE + ' !important',
    borderRadius: 0,
    backgroundColor: DARK_GREY,
    width: '20vw',
    minWidth: '150px',
    maxWidth: '274px',
    height: '5vh',
    maxHeight: '50px',
    minHeight: '30px',
    padding: '2px 0 2px 0',
    fontSize: calculatedFontSize('.85em', '1.6em'),
    fontWeight: 700,
    textDecoration: 'none',
    textTransform: 'unset',
    "@media (max-width:1280px)": {
        margin: '10px 5% 10px 5%',
        fontSize: calculatedFontSize('1em', '1.6em'),
    },
    "@media (max-width:600px)": {
        fontSize: calculatedFontSize('1em', '1.2em'),
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.main
    }
}));


export const UserActionButton = styled(Button)(({theme}) => ({
    width: '25vw',
    maxWidth: '350px',
    minWidth: '200px',
    maxHeight: '65px',
    borderRadius: '0px',
    padding: '11.5px 15px 11.5px 15px',
    boxShadow: '5px 5px 5px lightgrey',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    textTransform: 'none',
    backgroundColor: DARK_GREY,
    color: WHITE,
    fontSize: calculatedFontSize('.8em', '1.75em'),
    fontWeight: 'bold',
    [`${theme.breakpoints.down('sm')}`]: {
        minWidth: '200px'
    },
    "&:hover": {
        backgroundColor: theme.palette.primary.main
    },
    "&:disabled": {
        opacity: '.6',
        color: LIGHTER_GREY
    }
}));

export const UserActionButtonDiv = styled('div')(({theme}) => ({
    width: calculateResponsiveSizeWithMinMax('15', '300', '100'),
    maxWidth: '300px',
    minWidth: '300px',
    padding: '15px',
    boxShadow: '5px 5px 5px lightgrey',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: DARK_GREY,
    color: WHITE,
    [`${theme.breakpoints.down('sm')}`]: {
        minWidth: '150px'
    },
    "&:hover": {
        backgroundColor: theme.palette.secondary.light
    }
}));

export const FaqUserActionButtonDiv = styled('div')(({theme}) => ({
    width: calculateResponsiveSizeWithMinMax('22', '325', '100'),
    height: '78px',
    padding: '15px 15px 15px 15px',
    boxShadow: '5px 5px 5px lightgrey',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: DARK_GREY,
    color: WHITE,
    [`${theme.breakpoints.down('md')}`]: {
        width: calculateResponsiveSizeWithMinMax('18', '250', '100'),
    },
    [`${theme.breakpoints.down('sm')}`]: {
        width: calculateResponsiveSizeWithMinMax('30', '350', '100'),
    },
    "&:hover": {
        backgroundColor: theme.palette.secondary.light
    }
}));

const cancelDimensions = 20;
export const CancelIcon = styled(Clear) ({
    width: `${cancelDimensions}px`,
    height: `${cancelDimensions}px`,
    color: 'white',
    backgroundColor: 'grey',
    cursor: 'pointer',
    marginLeft: '5px',
    borderRadius: '3px'
});

export const CancelIconContainer = styled('div') ({
    width: `${cancelDimensions}px`,
    height: `${cancelDimensions}px`
})

export const SectionHeader = styled(Typography)({
    fontSize: calculatedFontSize('1.2em', '1.3em'),
    fontWeight: 550,
    marginTop: '1vh',
    marginBottom: '.5vh'
});

export const SectionHeaderLg = styled(Typography)({
    fontSize: calculatedFontSize('1.4em', '1.6em'),
    fontWeight: 550,
    marginTop: '4vh',
    marginBottom: '.5vh'
});

export const InputDiv = styled('div') ({
    width: '30vw',
    display: 'flex',
    maxWidth: '450px',
    minWidth: '150px',
    marginLeft: '10px',
    "@media (max-width:1280px)": {
        width: '80vw',
    }
});

export const RowFormControl = styled(FormControl) ({
    display: 'flex',
    flexDirection: 'row'
});

export const InputViewOnlyValue = styled(Typography) ({
    margin: '2vh 0 0 1vh',
    width: '30vw',
    maxWidth: '300px',
    minWidth: '150px',
    "@media (max-width:1280px)": {
        width: '80vw',
        maxWidth: '300px',
        minWidth: '150px'
    },
    paddingLeft: '10px'
})

export const CheckGoodIcon = styled(Check) ({
    color: GREEN,
    marginRight: '10px',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('1.75')
});

export const CheckBadIcon = styled(Close) ({
    color: LIGHT_RED,
    marginRight: '10px',
    fontSize: calculatedSizeMinBasedOnWidthHeightScaler('1.75')
})