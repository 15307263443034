import {ReportOption, TooltipSection} from "../features/reports/ReportTypes";
import {getLocalizedString} from "../util/Localization";

export const DEFAULT_TOOLTIP : TooltipSection[] = [
        {
            title: getLocalizedString('reports.tooltip.description', 'Description:'),
            description: getLocalizedString('reports.tooltip.default', 'This dashboard has recently been added to the ARC Client Portal and a description will be added shortly. We apologize for the inconvenience.')
        }
    ]

export const DAILY_DASHBOARD : ReportOption = {
    displayName: getLocalizedString('reports.dailyDashboard.title', 'Daily Dashboard'),
    lookerId: 182,
    viewable: true,
    tooltipText: [
        {
            title: getLocalizedString('reports.tooltip.description', 'Description:'),
            description: getLocalizedString('reports.dailyDashboard.tooltip1', 'The current status of your devices and lockers being managed by ARC. Filter by Location Name to see results for a single location.')
        },
        {
            title: getLocalizedString('reports.dailyDashboard.tooltip2Title', 'Overdue Devices:'),
            description: getLocalizedString('reports.dailyDashboard.tooltip2', 'Devices that have been checked out for over 24 hrs')
        },
        {
            title: getLocalizedString('reports.dailyDashboard.tooltip3Title', 'Tardy Devices:'),
            description: getLocalizedString('reports.dailyDashboard.tooltip3', 'Devices that have been checked out for over 8 hrs but less than 24 hrs')
        },
        {
            title: getLocalizedString('reports.dailyDashboard.tooltip4Title', 'Locker Tasks:'),
            description: getLocalizedString('reports.dailyDashboard.tooltip4', 'Lockers currently Out of Service and requiring manager attention')
        }
    ]
};

export const KPI_DASHBOARD : ReportOption = {
    displayName: getLocalizedString('reports.kpiDashboard.title', 'KPI Dashboard'),
    lookerId: 256,
    viewable: true,
    tooltipText:
        [
            {
                title: getLocalizedString('reports.tooltip.description', 'Description:'),
                description: getLocalizedString('reports.kpiDashboard.tooltip1', 'Metrics of system and user performance')
            },
            {
                title: getLocalizedString('reports.kpiDashboard.tooltip2Title', 'Overdue Checkout Rate:'),
                description: getLocalizedString('reports.kpiDashboard.tooltip2', 'The percentage of transactions that are checked out for over 24 hrs')
            },
            {
                title: getLocalizedString('reports.kpiDashboard.tooltip3Title', 'Device Issue Rate:'),
                description: getLocalizedString('reports.kpiDashboard.tooltip3', 'The percentage of transactions that end with a device issue')
            },
            {
                title: getLocalizedString('reports.kpiDashboard.tooltip4Title', 'Red Locker Rate:'),
                description: getLocalizedString('reports.kpiDashboard.tooltip4', 'The percentage of transactions that end in Out of Service lockers')
            }
        ]
};

export const KPI_DASHBOARD_2_6 : ReportOption = {
    displayName: getLocalizedString('reports.kpiDashboard2To6Locations.title', 'KPI Dashboard'),
    lookerId: 264,
    viewable: true,
    tooltipText: [
        {
            title: getLocalizedString('reports.description', 'Description:'),
            description: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip1', 'Metrics of system and user performance')
        },
        {
            title: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip2Title', 'Overdue Checkout Rate:'),
            description: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip2', 'The percentage of transactions that are checked out for over 24 hrs')
        },
        {
            title: getLocalizedString('reports.kpiDashboard2To6Locations.toolTip3Title', 'Device Issue Rate:'),
            description: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip3', 'The percentage of transactions that end with a device issue')
        },
        {
            title: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip4Title', 'Red Locker Rate:'),
            description: getLocalizedString('reports.kpiDashboard2To6Locations.tooltip4', 'The percentage of transactions that end in Out of Service lockers')
        }
    ]
};

export const KPI_DASHBOARD_7_PLUS : ReportOption = {
    displayName: getLocalizedString('reports.kpiDashboard7OrMoreLocations.title', 'KPI Dashboard'),
    lookerId: 265,
    viewable: true,
    tooltipText: [
        {
            title: getLocalizedString('reports.description', 'Description:'),
            description: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip1', 'Metrics of system and user performance')
        },
        {
            title: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip2Title', 'Overdue Checkout Rate:'),
            description: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip2', 'The percentage of transactions that are checked out for over 24 hrs')
        },
        {
            title: getLocalizedString('reports.kpiDashboard7OrMoreLocations.toolTip3Title', 'Device Issue Rate:'),
            description: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip3', 'The percentage of transactions that end with a device issue')
        },
        {
            title: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip4Title', 'Red Locker Rate:'),
            description: getLocalizedString('reports.kpiDashboard7OrMoreLocations.tooltip4', 'The percentage of transactions that end in Out of Service lockers')
        }
    ]
};

export const EMPLOYEE_DASHBOARD : ReportOption = {
    displayName: getLocalizedString('reports.employeeDashboard.title', 'User Dashboard'),
    lookerId: 240,
    viewable: true,
    tooltipText: [
        {
            title: getLocalizedString('reports.tooltip.description', 'Description:'),
            description: getLocalizedString('reports.employeeDashboard.tooltip1', 'The current status and history of a single user. Filter by User Name or Badge ID to see results for a single user.')
        }
    ]
};

export const DEVICE_DASHBOARD : ReportOption = {
    displayName: getLocalizedString('reports.deviceDashboard.title', 'Device Dashboard'),
    lookerId: 234,
    viewable: true,
    tooltipText: [
        {
            title: getLocalizedString('reports.tooltip.description', 'Description:'),
            description: getLocalizedString('reports.deviceDashboard.tooltip1', 'The current status and history of a single device. Filter by Serial Number or Device ID to see results for a single device.')
        }
    ]
}

export const REPORT_OPTIONS : ReportOption[] = [
    DAILY_DASHBOARD,
    {
        displayName: getLocalizedString('reports.deviceSummaryDashboard.title', 'Device Summary'),
        lookerId: 231,
        viewable: true,
        tooltipText: [
            {
                title: getLocalizedString('reports.tooltip.description', 'Description:'),
                description: getLocalizedString('reports.deviceSummaryDashboard.tooltip1', 'The current status and history of your device fleet')
            },
            {
                title: getLocalizedString('reports.deviceSummaryDashboard.tooltip2Title', 'Active Devices:'),
                description: getLocalizedString('reports.deviceSummaryDashboard.tooltip2', 'Devices added to a kiosk that are currently monitored by ARC')
            },
            {
                title: getLocalizedString('reports.deviceSummaryDashboard.tooltip3Title', 'Deactivated Devices:'),
                description: getLocalizedString('reports.deviceSummaryDashboard.tooltip3', 'Devices that have been intentionally excluded from ARC')
            },
            {
                title: getLocalizedString('reports.deviceSummaryDashboard.tooltip4Title', 'Devices Never Added to Kiosk:'),
                description: getLocalizedString('reports.deviceSummaryDashboard.tooltip4', 'Devices that are registered in the Client Portal but not yet added to a kiosk')
            }
        ]
    },
    {
        displayName: getLocalizedString('reports.employeeSummaryDashboard.title', 'User Summary'),
        lookerId: 238,
        viewable: true,
        tooltipText: [
            {
                title: getLocalizedString('reports.tooltip.description', 'Description:'),
                description: getLocalizedString('reports.employeeSummaryDashboard.tooltip1', 'The current status and history of users with access to ARC')
            },
            {
                title: getLocalizedString('reports.employeeSummaryDashboard.tooltip2Title', 'Active ARC Users:'),
                description: getLocalizedString('reports.employeeSummaryDashboard.tooltip2', 'Users who currently have access to ARC at one or more locations')
            }
        ]
    },
    {
        displayName: getLocalizedString('reports.transactionHistoryDashboard.title', 'Transactions History'),
        lookerId: 233,
        viewable: true,
        tooltipText: [
            {
                title: getLocalizedString('reports.tooltip.description', 'Description:'),
                description: getLocalizedString('reports.transactionHistoryDashboard.tooltip1', 'A record of transactions for the last six months')
            },
            {
                title: getLocalizedString('reports.transactionHistoryDashboard.tooltip2Title', 'Transaction'),
                description: getLocalizedString('reports.transactionHistoryDashboard.tooltip2', 'A transaction opens with a Pick Up and is closed when the device is seen again in the kiosk or disabled')
            }
        ]
    },
];

export const SCORECARD_DASHBOARD : ReportOption = {
    displayName: 'ARC Scorecard',
    lookerId: 348,
    viewable: true,
    tooltipText: [
        {
            title: 'ARC Score',
            description: 'A number that reflects performance within ARC based on five factors for the last 30 days and is set on a scale of zero to ten.'
        },
        {
            title: 'Penalty Points',
            description: 'Penalty Points are points accrued due to unfavorable ARC usage.'
        }
    ]
};

export const ALL_LOOKER_DASHBOARDS : ReportOption[] = [
    KPI_DASHBOARD,
    KPI_DASHBOARD_2_6,
    KPI_DASHBOARD_7_PLUS,
    EMPLOYEE_DASHBOARD,
    DEVICE_DASHBOARD,
    ...REPORT_OPTIONS,
    SCORECARD_DASHBOARD
]