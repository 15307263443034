import * as React from 'react';
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextSelector} from "../../app-context/appContextSlice";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {makeStyles, styled} from "@material-ui/core/styles";
import {calculatedFontSize, calculateResponsiveSizeWithMinMax} from "../../../util/TextUtils";
import {deviceContextActions, deviceContextSelector, exportDevices} from "../deviceSlice";
import {AppWithFooter} from "../../../App";
import {CenteredDiv, FormTitle, ErrorMessageSectionItem, MainContentContainer} from "../../ui-components/StyledComponents";
import {ServerMessage} from "../../ui-components/ServerMessage";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";

export const ExportDevices = () => {
    const styles = useStyles();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const context = useAppSelector(appContextSelector);
    const deviceContext = useAppSelector(deviceContextSelector);

    const [activeOnly, setActiveOnly] = React.useState<boolean>(true);

    React.useEffect(() => {
        return () => {dispatch(deviceContextActions.resetExportDevice())}
    }, [dispatch]);

    React.useEffect(() => {
        if(deviceContext.exportFile) {
            const link = document.createElement('a');
            link.href = deviceContext.exportFile;
            link.setAttribute('download', 'DeviceExportFile.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }
    }, [deviceContext.exportFile])

    const submitForm = React.useCallback(() => {
        if(context.currentLocation?.locationId) {
            dispatch(exportDevices({locationId: context.currentLocation.locationId, activeOnly: activeOnly}));
        }
    }, [activeOnly, context.currentLocation?.locationId, dispatch]);

    return (
        <AppWithFooter>
            <AppBar/>
            <CenteredDiv>
                <SideBar/>

                <MainContentContainer style={{alignItems: 'unset'}}>
                    {deviceContext.exportErrorMessage &&
                        <ServerMessage variant={'error'}>
                            <ErrorMessageSectionItem>
                                {deviceContext.exportErrorMessage}
                            </ErrorMessageSectionItem>
                        </ServerMessage>}

                    <FormTitle variant="h5" className={'o-heading--xl'}>{getLocalizedString('exportDevices.title', 'Export Devices')}</FormTitle>

                    <FormControl>
                        <RadioGroup
                            aria-label="export-active"
                            name="radio-buttons-group"
                            value={activeOnly}
                            onChange={(event) =>
                                setActiveOnly(event.target.value === 'true')
                            }>

                            <FormControlLabel 
                                checked={activeOnly} 
                                value={'true'} 
                                control={<ColorRadio color="default"/>} 
                                label={<span style={{fontSize: calculateResponsiveSizeWithMinMax('2', '18','16')}}>{getLocalizedString('exportDevices.activeOnly', 'All active devices')}</span>}
                            />
                            <FormControlLabel 
                                checked={!activeOnly} 
                                value={'false'} 
                                control={<ColorRadio color="default"/>} 
                                label={<span style={{fontSize: calculateResponsiveSizeWithMinMax('2', '18','16')}}>{getLocalizedString('exportDevices.all', 'All active, pending activation, and deactivated devices')}</span>}
                            />

                        </RadioGroup>
                    </FormControl>
                    <br/>

                    <div className={styles.buttonDiv}>
                        <ARCButton
                            fill={'filled'}
                            variant={'primary'}
                            size={'md'}
                            onClick={submitForm}
                            label={getLocalizedString('exportDevices.submit', 'Export')}
                        >
                        </ARCButton>
                        <ARCButton
                            fill={'outlined'}
                            variant={'secondary'}
                            size={'md'} 
                            onClick={() => {navigate(`/devices/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`)}}
                            label={getLocalizedString('cancel', 'Cancel')}
                        >
                        </ARCButton>
                    </div>
                </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}

const useStyles = makeStyles((theme) => ({
    form: {
        width: '100%'
    },
    help_text: {
        fontSize: calculatedFontSize('.6em', '1.3em')
    },
    buttonDiv: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        marginTop: theme.spacing(4),
        width: '100%'
    }
}));

const ColorRadio = styled(Radio)(({theme}) => ({
    color: theme.palette.primary.main
}));
