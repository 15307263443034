import * as React from 'react';
import {Option} from "../ColorSelect";
import {ARCIcon, ARCIconComponentType, isArcIcon} from "./ARCIcon";
import {KeyboardArrowDown, SvgIconComponent } from '@material-ui/icons';

type ARCSelectInputProps = {
    id: string,
    size: 'sm' | 'md',
    label?: string,
    placeholder?: string,
    required: boolean,
    disabled?: boolean;
    options: Option[];
    value: any | undefined;
    onChange: (option: Option) => void;
    validate?: (value: any) => string | undefined;
    className?: string;
    style?: React.CSSProperties;
}

export const ARCSelectInput : React.FC<ARCSelectInputProps> = (props) => {
    const [inputError, setInputError] = React.useState<string | undefined>(undefined);
    const [isOpen, setIsOpen] = React.useState(false);

    const optionIcon = React.useCallback((option: Option) => {
        const icon = option.icon;

        if(icon) {
            if (isArcIcon(icon)) {
                const arcIcon = icon as ARCIconComponentType;

                if (arcIcon.defaultProps?.icon) {
                    return <ARCIcon icon={arcIcon.defaultProps?.icon} size={'sm'}/>
                }
            } else {
                return icon as SvgIconComponent;
            }
        }
    }, []);

    const selectedValue = React.useMemo(() => {
        return props.options.find(opt => opt.value === props.value)?.label ?? props.placeholder;
    }, [props.options, props.placeholder, props.value]);

    const handleOptionClick = (option : Option) => {
        setIsOpen(false);
        props.onChange(option);

        if(props.validate) {
            setInputError(props.validate(option.value));
        }
    };

    const selectRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (event : MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div id={props.id} className={`o-select__container ${props.className || ''}`} ref={selectRef}>
            {props.label &&
            <label className={`o-select__label--${props.size}`}>
                {props.label}

                {props.required &&
                <div className={'required-star'}>
                    {'*'}
                </div>
                }
            </label>
            }

            <div
                className={`o-select o-select--${props.size} ${inputError ? 'o-input__error' : ''} ${props.disabled ? 'o-select--disabled' : ''}`}
                onClick={() => {
                    if(!props.disabled) {
                        setIsOpen(!isOpen);
                    }
                }
                }
            >
                <div className={`o-select__selected__option o-select__selected__option--${props.size}`}>
                    {(
                        <span className={`o-select${props.value ? '__value' : '__placeholder'}--${props.size}`}>{selectedValue || props.placeholder || ''}</span>
                    )}
                </div>

                <KeyboardArrowDown className={'o-select__dropdown__icon'}/>

                {isOpen && (
                    <div className="o-select__dropdown__menu">
                        {props.options.map((option, idx) => (
                            <div
                                key={idx}
                                className="o-select__option"
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                                {optionIcon(option) && (
                                    <span className="option-icon">{optionIcon(option)}</span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>


            {inputError &&
            <div className={'o-select__error'}>
                {inputError}
            </div>
            }
        </div>
    );
}