import {DeviceDto, DeviceValidationKeys} from "../../../../dtos/Device";
import * as React from "react";
import {Dispatch, SetStateAction} from "react";
import {Tooltip} from "@material-ui/core";
import INFO_ICON from "../../../../assets/img/Icon_Material_Info.svg";
import ErrorWrapper, {ErrorWrapperProps} from "../../../ui-components/ErrorWrapper";
import {CreateDeviceChildComponentNames} from "../../pages/CreateDevice";
import {NextButton} from "../../../ui-components/StyledComponents";
import {getLocalizedString} from "../../../../util/Localization";
import {isAlphanumeric} from "../../../../util/TextUtils";

type DeviceTagProps = {
    formDevice: DeviceDto,
    setFormDevice: Dispatch<SetStateAction<DeviceDto>>,
    goForward: (name: string) => void,
    fieldErrors: {[fieldName: string]: {
        error: string | undefined,
        shown: boolean,
    }},
    createErrorProps: (field: string) => ErrorWrapperProps,
    setFieldError: (field: string, error: string) => void,
    clearFieldError: (field: string) => void,
    validate: (value: string) => string,
}

export const DeviceTag:React.ComponentType<DeviceTagProps> = (props) => {
    const {formDevice, 
            setFormDevice, 
            goForward,
            fieldErrors,
            createErrorProps,
            setFieldError,
            clearFieldError,
            validate
    } = props;
            
    const onNext = React.useCallback(() => {
        if (formDevice.deviceTag && formDevice.deviceTag.length > 0) {
            goForward(CreateDeviceChildComponentNames.createDeviceForm)
        }
    }, [formDevice.deviceTag, goForward]) 
    
    React.useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                onNext();
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onNext]);

    return (
        <div className={'createdevice-other-div'}>
            <div className={'createdevice-device-tag-title-div'}>
                <div className={'createdevice-device-tag-title'}>
                    {getLocalizedString('createDevice.deviceTag', 'Device Serial Number')}
                    <span className={'createdevice-red-star'}>
                        {'*'}
                    </span>
                </div>
                <Tooltip placement={'bottom-start'}
                         title={getLocalizedString('createDevice.deviceTag.tooltip', 'This is the unique identifier associated with the device.')}>
                    <img src={INFO_ICON} alt={'Info Icon'}/>
                </Tooltip>
            </div>

            <ErrorWrapper {...createErrorProps(`${DeviceValidationKeys.deviceTag}`)}>
                <div className={'editdevice-input-div'}>
                    <input
                        autoComplete={"device tag"}
                        id="addDevice-deviceTag"
                        required={true}
                        value={formDevice.deviceTag}
                        className={'editdevice-device-tag-textfield'}
                        onChange={(event) => {
                            const error = validate(event.target.value);

                            if (error) {
                                const showCharacter = !isAlphanumeric(event.target.value) && (fieldErrors[`${DeviceValidationKeys.deviceTag}`]?.error?.length ?? 0) === 0;
                                if (showCharacter) {
                                    setFormDevice({
                                        ...formDevice,
                                        deviceTag: event.target.value
                                    })
                                }
                                setFieldError(DeviceValidationKeys.deviceTag, error);
                            } else {
                                setFormDevice({
                                    ...formDevice,
                                    deviceTag: event.target.value
                                })
                                clearFieldError(DeviceValidationKeys.deviceTag);
                            }
                        }}
                    />
                </div>
            </ErrorWrapper>
            <NextButton
                id="device-tag-next"
                variant='contained'
                disabled={!formDevice.deviceTag || formDevice.deviceTag.length === 0}
                onClick={() => {
                    goForward(CreateDeviceChildComponentNames.createDeviceForm)
                }}
            >
                {getLocalizedString('next', 'Next')}
            </NextButton>
        </div>
    )
}
