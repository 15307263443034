import * as React from 'react';
import {Button, IconButton, InputAdornment, Snackbar, TextField, useTheme} from "@material-ui/core";
import {styled} from "@material-ui/core/styles";
import {
    calculatedSizeMinBasedOnWidthHeightScaler, calculateResponsiveSizeWithMinMax
} from "../../util/TextUtils";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {
    appContextActions,
    appContextSelector, authorizeAsync, resendResetPassword,
    resetPassword,
    validateResetPasswordToken
} from "../app-context/appContextSlice";
import {
    getPasswordStrength,
    isPasswordStrong,
    passwordHasLetter,
    passwordHasNumber, passwordMeetsLength
} from "../../util/PasswordUtil";
import {PasswordUpdateDto} from "../../dtos/PasswordUpdateDto";
import {
    SuccessMessageText
} from "../ui-components/StyledComponents";
import ARCLOGO_BLACK from "../../assets/img/ARC_Logo_Black.png";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import "../../css/ResetPassword.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {PasswordChecklist} from "../ui-components/PasswordChecklist";
import {getLocalizedString} from "../../util/Localization";
import {HELP_EMAIL, HELP_PHONE} from "../../constants/StringConstants";

export const ResetPassword = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isSmOrBelow = useMediaQuery(theme.breakpoints.down('sm'));
    const isXsOrBelow = useMediaQuery(theme.breakpoints.down('xs'));
    const context = useAppSelector(appContextSelector);
    const [showPassword, setShowPassword] = React.useState<boolean>(false);
    const [showVerifyPassword, setShowVerifyPassword] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const {token} = useParams();

    const [resetPasswordForm, setResetPasswordForm] = React.useState<PasswordUpdateDto>({
        userName: context.authorizationDto?.username,
        oldPassword: undefined,
        newPassword: '',
        confirmNewPassword: '',
        passwordStrength: 0
    });
    
    const passwordMismatch = React.useMemo(() => {
        return resetPasswordForm.confirmNewPassword?.length > 0 ? resetPasswordForm.newPassword !== resetPasswordForm.confirmNewPassword : false;
    }, [resetPasswordForm.confirmNewPassword, resetPasswordForm.newPassword])
    
    const allowSubmit = React.useMemo(() => {
        return passwordHasNumber(resetPasswordForm.newPassword) && 
                passwordHasLetter(resetPasswordForm.newPassword) && 
                passwordMeetsLength(resetPasswordForm.newPassword) && 
                !passwordMismatch && 
                !!resetPasswordForm.confirmNewPassword;
    }, [passwordMismatch, resetPasswordForm.newPassword, resetPasswordForm.confirmNewPassword])

    const [formErrors, setFormErrors] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        if(token) {
            dispatch(validateResetPasswordToken({resetToken: token}));
        }
    }, [token, dispatch]);

    React.useEffect(() => {
        return () => {dispatch(appContextActions.resetResetPassword())}
    }, [dispatch]);

    const submitForm = React.useCallback( async (event : React.FormEvent) => {
        event.preventDefault();

        setFormErrors('');

        const updatedResetPasswordForm = {
            ...resetPasswordForm,
            passwordStrength: getPasswordStrength(resetPasswordForm.newPassword)
        };

        if(isPasswordStrong(updatedResetPasswordForm.newPassword) && token) {
            await dispatch(resetPassword({passwordUpdateDto: updatedResetPasswordForm, resetToken: token}))
        }
        else {
            setFormErrors(getLocalizedString('resetpassword.weakPassword', 'Password must meet all requirements'));
        }

    }, [dispatch, resetPasswordForm, token]);

    const login = React.useCallback(async (username: string) => {
        await dispatch(authorizeAsync({username: username, password: resetPasswordForm.newPassword, failedAttempts: 0}));
    }, [dispatch, resetPasswordForm.newPassword]);

    React.useEffect(() => {
        if(context.resetPasswordSuccessful && context.resetPasswordUsername) {
            login(context.resetPasswordUsername).then(() => navigate('/home'));
            dispatch(appContextActions.resetAllPasswordMessageStates());
        }
    }, [dispatch, navigate, context.resetPasswordSuccessful, login, context.resetPasswordUsername]);
    
     React.useEffect(() => {
        if (formErrors) {
            setErrorMessage(formErrors)
        } else if (context.resetPasswordErrorMessage) {
            setErrorMessage(context.resetPasswordErrorMessage)
        }
    }, [context.resetPasswordErrorMessage, formErrors])
    
    const ResetPasswordErrorAlert = React.useMemo(() => {
        return (
            !isSmOrBelow ?
            (errorMessage) &&
            <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true} className={'login-snackbar'}>
                <div className={'login-snackbar-div'}>
                    <div style={{display: 'flex'}}>
                        <div className={'login-snackbar-text'}>
                            {errorMessage}
                        </div>
                    </div>
                </div>
            </Snackbar>
            :
            (errorMessage) &&
            <div style={{marginBottom: isXsOrBelow ? '3em' : '5.5em'}} className={'div-login-snackbar'}>
                <Snackbar anchorOrigin={{vertical: 'top', horizontal: 'center'}} open={true} className={'login-snackbar'}>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <div style={{display: 'flex'}}>
                            <div className={'login-snackbar-text'}>
                                {errorMessage}
                            </div>
                        </div>
                    </div>
                </Snackbar>
            </div>
            
        );
    }, [isSmOrBelow, isXsOrBelow, errorMessage]);

    return (
            <div className={'container'}>
                {ResetPasswordErrorAlert}
                <div className={'login-body'}>
                    <div className={'login-header'}>
                        <div className={'login-logo-div login-logo-div-hover'} onClick={() => {navigate('/login')}}>
                            <img className={'login-logo'} src={ARCLOGO_BLACK}
                                 alt="ARC Logo"/>
                            <div className={'logo-typography'}>{getLocalizedString('title', 'Client Portal')}</div>
                        </div>
                    </div>

                    <form onSubmit={submitForm} className={'form'}>
                        <div className={'input-root'}>
                            {!context.resetPasswordTokenValid ?
                                <div className={'reset-title'}>
                                    <div className={'row-div wrap-div center-div'}>
                                        {getLocalizedString('resetpassword.tokenInvalidEmailSent1', 'This link is invalid. Click')}
                                        <div className={'Link'} onClick={() => navigate('/forgotpassword')}>
                                            {getLocalizedString('resetpassword.tokenInvalidEmailSent2', 'here')}
                                        </div>
                                        {getLocalizedString('resetpassword.tokenInvalidEmailSent3', ' to try resetting your password again.')}
                                    </div>
                                </div>
                                :
                                context.resetTokenUsed || context.resetTokenExpired ?
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

                                    {context.resendResetPasswordSuccessful &&
                                    <SuccessMessageText style={{textAlign: 'center'}}>
                                        {getLocalizedString('resetpassword.newTokenSent', 'Success! A new password reset link has been sent.')}
                                    </SuccessMessageText>}

                                    <div className={'reset-title'} style={{marginBottom: '50px'}}>
                                        {context.resetTokenExpired &&
                                        <div className={'row-div wrap-div center-div'}>
                                            {getLocalizedString('resetpassword.tokenInvalidEmailSent1', 'This link is invalid. Click')}
                                            <div className={'Link'} onClick={() => navigate('/forgotpassword')}>
                                                {getLocalizedString('resetpassword.tokenInvalidEmailSent2', 'here')}
                                            </div>
                                            {getLocalizedString('resetpassword.tokenInvalidEmailSent3', ' to try resetting your password again.')}
                                        </div>
                                        }


                                        {context.resetTokenUsed &&
                                        <div className={'row-div wrap-div center-div'}>
                                            {getLocalizedString('resetpassword.tokenUsed1', 'This link has already been used. Click')}
                                            <div className={'Link'} onClick={() => navigate('/forgotpassword')}>
                                                {getLocalizedString('resetpassword.tokenUsed2', 'here')}
                                            </div>
                                            {getLocalizedString('resetpassword.tokenUsed3', ' to try resetting your password again.')}
                                        </div>
                                        }
                                    </div>

                                    {context.resetTokenExpired &&
                                    <div className={'reset-help-text'}>
                                        {getLocalizedString('resetpassword.validity', 'Links expire after 15 days.')}
                                    </div>
                                    }


                                    <div className={'reset-help-text'}>
                                        {getLocalizedString('resetpassword.sendNewEmailHelp', 'Click below to receive a new password reset link:')}
                                    </div>

                                    <ResetButtonSendLink onClick={() => {
                                        if(token) {
                                            dispatch(resendResetPassword({resetToken: token}))
                                        }
                                    }
                                    }>
                                        {getLocalizedString('resetpassword.sendNewEmail', 'Send New Link')}
                                    </ResetButtonSendLink>

                                    <div className={'reset-help-text'}>
                                        {getLocalizedString('resetpassword.orLogin', 'or login to your ARC Client Portal account:')}
                                    </div>

                                    <ResetButtonSendLink onClick={() => navigate('/login')}>
                                        {getLocalizedString('resetpassword.goToLogin', 'Go to Login Page')}
                                    </ResetButtonSendLink>
                                </div>
                                :
                                <>
                                    <div className={'reset-title'}>{getLocalizedString('resetpassword.title', 'Reset Your Password')}</div>

                                    <div className={'reset-input-form-control'}>
                                        <TextField
                                            autoComplete={"new password"}
                                            placeholder={getLocalizedString('resetpassword.newPassword', 'New Password')}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        style={{height: '50%', width: '20%'}}
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                            edge="end"
                                                            style={{height: '50%', width: '50%', fontSize: calculatedSizeMinBasedOnWidthHeightScaler('4')}}
                                                        >
                                                            {!showPassword ? (
                                                                <Visibility fontSize="inherit"/>
                                                            ) : (
                                                                <VisibilityOff fontSize="inherit"/>
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    marginTop: 0,
                                                    height: '100%',
                                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                                    paddingLeft: '15px',
                                                    borderRadius: '12px'
                                                }
                                            }}
                                            id="changepass-newpass"
                                            type={showPassword ? 'text' : 'password'}
                                            className={'input-text'}
                                            required={true}
                                            onChange={(event) => {
                                                setResetPasswordForm({
                                                    ...resetPasswordForm,
                                                    newPassword: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    
                                    <div className={'reset-input-form-control'}>
                                        <TextField
                                            autoComplete={"new password"}
                                            placeholder={getLocalizedString('resetpassword.confirmPassword', 'Verify New Password')}
                                            InputProps={{
                                                disableUnderline: true,
                                                endAdornment: (
                                                    <InputAdornment
                                                        position="end"
                                                        style={{height: '50%', width: '20%'}}
                                                    >
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={() => setShowVerifyPassword(!showVerifyPassword)}
                                                            edge="end"
                                                            style={{height: '50%', width: '50%', fontSize: calculatedSizeMinBasedOnWidthHeightScaler('4')}}
                                                        >
                                                            {!showVerifyPassword ? (
                                                                <Visibility fontSize="inherit"/>
                                                            ) : (
                                                                <VisibilityOff fontSize="inherit"/>
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    marginTop: 0,
                                                    height: '100%',
                                                    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
                                                    paddingLeft: '15px',
                                                    border: passwordMismatch ? '2px solid #C3483E' : '0',
                                                    borderRadius: '12px'
                                                }
                                            }}
                                            id="changepass-newpass"
                                            type={showVerifyPassword ? 'text' : 'password'}
                                            className={'input-text'}
                                            required={true}
                                            onChange={(event) => {
                                                setResetPasswordForm({
                                                    ...resetPasswordForm,
                                                    confirmNewPassword: event.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className={'password-mismatch-div'}>
                                        {passwordMismatch ?
                                            <div className={'password-mismatch-text'}>
                                                {getLocalizedString('resetpassword.passwordMismatch', 'Passwords do not match')}
                                            </div>
                                            :
                                            <span className={'password-mismatch-text'}>
                                                <br/>
                                            </span>
                                        }
                                    </div>
                                    <div className={'help-text-div'}>
                                        <PasswordChecklist password={resetPasswordForm.newPassword}/>

                                        <div className={'reset-help-text'} style={{textAlign: 'start', marginTop: '3%'}}>
                                            {getLocalizedString('resetpassword.oldPassword', 'Password cannot match last 4 passwords')}
                                        </div>
                                    </div>
                                    <div className={'button-div'}>
                                        <ResetButtonCancel
                                            id="updatebadge-cancel"
                                            variant='contained'
                                            className={'buttons button-cancel'}
                                            onClick={() => {
                                                dispatch(appContextActions.unauth())
                                                navigate('/login')
                                            }}
                                        >
                                            {getLocalizedString('resetpassword.cancel', 'Cancel')}
                                        </ResetButtonCancel>
                                        <ResetButtonSubmit
                                            id="updatebadge-save"
                                            variant='contained'
                                            type='submit'
                                            className={'buttons button-submit'}
                                            disabled={!allowSubmit}
                                        >
                                            {getLocalizedString('resetpassword.submit', 'Reset and Login')}
                                        </ResetButtonSubmit>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </div>
                <div className={'tool-tip'} style={isSmOrBelow ? {position: 'relative', bottom: '0', marginTop: '5%'} : {}}>
                    {getLocalizedString('support', 'ARC Support') + ' | '+ getLocalizedString('support.email', HELP_EMAIL) + ' | ' + getLocalizedString("support.phone", HELP_PHONE)}
                </div>
            </div>
    )
}

const ResetButtonCancel = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 600 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
    color: '#FFFFFF',
    background: '#444444',
    letterSpacing: '0',
    width: '60%',
    padding: '4% 0 4% 0 !important',
    marginRight: '5%',
    minWidth: '0px !important',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: '.5'
    }
});

const ResetButtonSubmit = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 550 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
    color: '#000000',
    background: '#FBBC41',
    letterSpacing: '0',
    width: '100%',
    padding: '4% 0 4% 0 !important',
    textTransform: 'none',
    minWidth: '0px !important',
    '&:hover': {
      backgroundColor: '#FBBC41',
      opacity: '.5'
    }
});

const ResetButtonSendLink = styled(Button)({
    maxHeight: '60px',
    borderRadius: '30px',
    borderWidth: '0',
    maxWidth: '100% !important',
    font: 'normal normal 550 0 "Proxima Nova"',
    fontSize: calculateResponsiveSizeWithMinMax('2', '18', '8'),
    color: '#FFFFFF',
    background: '#444444',
    letterSpacing: '0',
    width: '100%',
    padding: '2% 0 2% 0 !important',
    margin: '5% 0 10% 0',
    textTransform: 'none',
    minWidth: '0px !important',
    '&:hover': {
      background: '#444444',
      opacity: '.5'
    }
});
