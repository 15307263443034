import * as React from 'react';
import {ARCIcon} from "./ARCIcon";

type AccordionSection = {
    title: string,
    content: React.ReactNode
}

type ARCAccordionProps = {
    title: string | undefined,
    sections: AccordionSection[],
    autoOpenSection?: string | undefined
}

export const ARCAccordion:React.FC<ARCAccordionProps> = (props) => {
    const [activeIndex, setActiveIndex] = React.useState<number | null>(null);
    const contentRefs = React.useRef<(HTMLDivElement | null)[]>([]);

    const toggleSection = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const isSectionOpen = React.useCallback((index: number) => {
        return activeIndex === index;
    }, [activeIndex]);
    
    React.useEffect(() => {
        props.sections.forEach((section, i) => {
            if (section.title === props.autoOpenSection) {
                setActiveIndex(i)
                if (contentRefs.current[i]) {
                    contentRefs.current[i]?.previousElementSibling?.scrollIntoView({
                        behavior: 'smooth',
                    })
                }
            }
        })
    }, [props.autoOpenSection, props.sections])

    React.useEffect(() => {
        contentRefs.current.forEach((ref, index) => {
            if (ref) {
                if (activeIndex === index) {
                    ref.style.height = `${ref.scrollHeight}px`;
                } else {
                    ref.style.height = '0px';
                }
            }
        });
    }, [activeIndex]);

    return (
        <div className={'l-container--md'} style={{width: '100%'}}>
            <h2 className={'o-accordion__title'}>{props.title}</h2>

            {props.sections.map((section, index) => (
                <div className={'o-accordion__section'} key={index} onClick={() => toggleSection(index)} >
                    <div className={`u-display--flex u-justify-content--space-between u-align-items--center o-accordion__section__header ${isSectionOpen(index) ? 'open' : 'closed'}`}>
                        <div className={`o-accordion__section__title o-accordion__section__title`}>
                            {section.title}
                        </div>

                        <ARCIcon icon={'arrow-down'} size={'md'} className={`o-accordion__section__icon ${isSectionOpen(index) ? 'open' : ''}`}/>
                    </div>

                    <div className={`o-accordion__section__content`} ref={(el) => (contentRefs.current[index] = el)}>
                        <div className={'o-accordion__section__content--inner'}>
                            {section.content}
                        </div>
                    </div>

                </div>
            ))}
        </div>
    );
}