import * as React from 'react';
import {useEffect, useState} from "react";
import AppBar from "../appbar/AppBar";
import {AppWithFooter} from "../../App";
import {CenteredDiv, MainContentContainer} from "../ui-components/StyledComponents";
import {useLocation, useNavigate} from "react-router-dom";
import {EmergencyPortalAppBar} from "../emergency-portal/components/EmergencyPortalAppBar";
import "../../css/PrivacyPage.css";
import {ARCButton} from "../ui-components/design-system/ARCButton";
export const TermsPage = () => {
    const [htmlContent, setHtmlContent] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    
    useEffect(() => {
        const fetchHtmlContent = async () => {
            const response = await fetch('/termsText.html');
            const html = await response.text();
            // Need to replace comment with href since they want a link to the privacy page for either client portal or emergency
            let url = location.pathname;
            let urlReplaced = url.replace('terms', 'privacy')
            let href = `<a href="${urlReplaced}">Privacy Policy</a>`
            const updatedHtmlContent = html.replace('<!-- LINK -->', href);
            setHtmlContent(updatedHtmlContent);
        };
        
        fetchHtmlContent();
    }, [location.pathname]);
    
    const backButtonPress = React.useCallback(() => {
        navigate(-1);
    },[navigate]);
    
    const MainContainer = React.useMemo(() => {
        return (
            location.pathname.includes("/terms/clientportal") ?
                <>
                    <AppBar title={<></>} right={!localStorage.getItem("token") ? <div className={'privacy-login-text'} onClick={() => navigate('/login')}>{'Log In'}</div> : undefined} logoNonInteractive={true}/>
                    <CenteredDiv>
                        <MainContentContainer style={{alignItems: 'unset'}}>
                            <div className={'privacy-back-button-div'}>
                                {htmlContent &&
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="terms-back-top"
                                        onClick={backButtonPress}
                                        label={'Back'}
                                    >
                                    </ARCButton>
                                }
                            </div>
                            <div
                                dangerouslySetInnerHTML={{__html: htmlContent}}
                            >
                            </div>
                            <div className={'privacy-back-button-div'}>
                                {htmlContent &&
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="terms-back-bottom"
                                        onClick={backButtonPress}
                                        label={'Back'}
                                    >
                                    </ARCButton>
                                }
                            </div>
                        </MainContentContainer>
                    </CenteredDiv>
                </>
                :
                <>
                    <EmergencyPortalAppBar
                        logoNonInteractive={true} 
                        leftGridWidth={7}
                        midGridWidth={1}
                        rightGridWidth={4}
                        gridSpacing={0}
                        right={!localStorage.getItem("emergencyToken") ? <div className={'privacy-login-text'} onClick={() => navigate('/emergency/login')}>{'Log In'}</div> : undefined}
                    />
                    <CenteredDiv>
                        <MainContentContainer style={{alignItems: 'unset'}}>
                            <div className={'privacy-back-button-div'}>
                                {htmlContent &&
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="terms-back-top"
                                        onClick={backButtonPress}
                                        label={'Back'}
                                    >
                                    </ARCButton>
                                }
                            </div>
                            <div
                                dangerouslySetInnerHTML={{__html: htmlContent}}
                            >
                            </div>
                            <div className={'privacy-back-button-div'}>
                                {htmlContent &&
                                    <ARCButton
                                        fill={'filled'}
                                        variant={'primary'}
                                        size={'md'}
                                        id="terms-back-bottom"
                                        onClick={backButtonPress}
                                        label={'Back'}
                                    >
                                    </ARCButton>
                                }
                            </div>
                        </MainContentContainer>
                    </CenteredDiv>
                </>
            
        )
    },[htmlContent, location.pathname, backButtonPress, navigate]) 
    return (
     <AppWithFooter>
         {MainContainer}
     </AppWithFooter>
    )
}
