
const defaultScalingSize = '1.5vw';
/* Will use scaling size unless it becomes too big or too small, then it will use max or min respectively */
export function calculatedFontSize(minSize: string, maxSize: string, scalingSize?: string) {
    return `min(max(${scalingSize ?? defaultScalingSize}, ${minSize}), ${maxSize})`;
}

export function calculateResponsiveSizeWithMinMax(scaler: string, max: string, min: string) {
    return `clamp(${min}px, ((${scaler}vh + ${scaler}vw)/2), ${max}px)`;
}

export function calculatedSizeMinBasedOnWidthHeightScaler(scaler: string) {
    return `min(${scaler}vh, ${scaler}vw)`;
}

export function calculatedSizeMaxBasedOnWidthHeightScaler(scaler: string) {
    return `max(${scaler}vh, ${scaler}vw)`;
}

export function calculatedSizeWidthHeightScalerWithMaxMin(scaler: string, max: string, min: string) {
    return `max(min(max(${scaler}vh, ${scaler}vw), ${max}px), ${min}px)`;
}

export function compareStrings(str1: string, str2: string): boolean {
    const normalizedStr1 = normalizeString(str1);
    const normalizedStr2 = normalizeString(str2);

    return normalizedStr1.startsWith(normalizedStr2);
}

export function isEmailValid(email: string) : boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function normalizeString(str: string): string {
    return str
        .toLowerCase()
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace(/'/g, '');
}

export function isNumeric(value: string): boolean {
    return /^[0-9]+(\.[0-9]+)?$/.test(value);
}

export function isNumericStrict(input: string): boolean {
    if (!input) {
        return false;
    }
    const pattern = /^[0-9]+$/;
    return pattern.test(input);
}

export function isAlphanumeric(value: string): boolean {
    const alphanumericRegex: RegExp = /^[a-zA-Z0-9]+$/;
    return alphanumericRegex.test(value);
}
export function isAlphabeticStrict(input: string): boolean {
    if (!input) {
        return false;
    }
    const pattern = /^[a-zA-Z]+$/;
    return pattern.test(input);
}

export function nameContainsInvalidCharacters(input: string): boolean {
    const pattern = new RegExp("[^A-Za-z\\-'\\s]");
    return pattern.test(input);
}

export function formatNameListGrammatically<T>(items: T[], field: keyof T): string | undefined {
    if (items.length === 0) {
        return undefined;
    } else if (items.length === 1) {
        return String(items[0][field]);
    } else if (items.length === 2) {
        return `${items[0][field]} or ${items[1][field]}`;
    } else {
        let result = '';
        for (let i = 0; i < items.length - 1; i++) {
            result += `${items[i][field]}, `;
        }
        result += `or ${items[items.length - 1][field]}`;
        return result;
    }
}
