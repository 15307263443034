import * as React from 'react';
import {BLACK, WHITE} from "../../themes/colors";
import {KeyboardArrowDown, SvgIconComponent} from "@material-ui/icons";
import {ARCIcon, ARCIconComponentType, isArcIcon} from "./design-system/ARCIcon";

export interface Option {
    value: any;
    label: string;
    icon?: SvgIconComponent | ARCIconComponentType | React.ReactNode;
    disabled?: boolean;
}

interface ColorSelectProps {
    id?: string,
    options: Option[];
    className?: string;
    variant: 'filled' | 'outline';
    disabled?: boolean;
    label?: string | undefined;
    value: any | undefined;
    onChange: (option: Option) => void;
    style?: React.CSSProperties;
}

const ColorSelect: React.ComponentType<ColorSelectProps> = ({id, className, variant, disabled, options, label, value, onChange}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const optionIcon = React.useCallback((option: Option) => {
        const icon = option.icon;

        if(icon) {
            if (isArcIcon(icon)) {
                const arcIcon = icon as ARCIconComponentType;

                if (arcIcon.defaultProps?.icon) {
                    return <ARCIcon icon={arcIcon.defaultProps?.icon} size={'sm'}/>
                }
            } else {
                return icon as SvgIconComponent;
            }
        }
    }, []);

    const selectedValue = React.useMemo(() => {
        return options.find(opt => opt.value === value)?.label ?? label;
    }, [label, options, value]);

    const handleOptionClick = (option : Option) => {
        setIsOpen(false);
        onChange(option);
    };

    const selectRef = React.useRef<HTMLDivElement>(null);

    const handleClickOutside = (event : MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div id={id} className={`select-container ${disabled ? 'disabled' : ''} ${className}`} style={{border: isOpen ? '2px solid var(--primary-color)' : ''}} ref={selectRef}>
            <div
                className={`select ${disabled ? 'disabled' : isOpen ? 'open' : ''}`}
                style={{backgroundColor: variant === 'filled' ? 'var(--primary-color)' : 'transparent', color: variant === 'filled' ? WHITE : 'var(--primary-color)'}}
                onClick={() => {
                    if(!disabled) {
                        setIsOpen(!isOpen);
                    }
                }
                }
            >
                <div className="selected-option">
                    {(
                        <span className="placeholder" style={{color: variant === 'filled' ? WHITE : BLACK}}>{selectedValue ?? ''}</span>
                    )}
                </div>

                {!disabled &&
                    <KeyboardArrowDown/>
                    // <div className="dropdown-arrow" style={{borderColor: variant === 'filled' ? WHITE : theme.palette.primary.main}}/>
                }

            </div>
            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option, idx) => (
                        <div
                            key={idx}
                            className="option"
                            onClick={() => handleOptionClick(option)}
                        >
                            {option.label}
                            {optionIcon(option) && (
                                <span className="option-icon">{optionIcon(option)}</span>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ColorSelect;
