import {Action, createAsyncThunk, createDraftSafeSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IAuthorizationDTO} from "../../dtos/Authorization";
import {createDeepEqualSelector, RootState} from "../../store/types";
import {appContextSliceName} from "./appContextTypes";
import {BrandDto} from "../../dtos/Brand";
import {get, post, postWithCancel} from "./thunks/API_Thunks";
import {AllLocationsDto, LocationDto} from "../../dtos/Location";
import {PasswordUpdateDto} from "../../dtos/PasswordUpdateDto";
import {PortalAccountDto} from "../../dtos/EmployeePortalDto";
import {ARC_SUPER_ADMIN, ARC_TECH_SUPPORT} from "./containers/UserPermissions";
import {PortalGlobalMessageDto} from "../../dtos/PortalGlobalMessageDto";
import {DevicePickupPermissionDto} from "../../dtos/DevicePickupPermission";
import {LoginFailureResponseDto} from "../../dtos/Response/LoginFailureResponseDto";
import {getLocalizedString} from "../../util/Localization";

export interface AppContextState {
    networkError: boolean
    networkErrorMessage?: string
    unauthorizedAccess?: boolean
    authStatus: 'loading' | 'idle' | 'error'
    brandDataLoading: boolean
    brandDataLoadSuccessful?: boolean
    brandDataErrorMessage?: string
    tokenValid: boolean
    authorizationDto?: Omit <IAuthorizationDTO, 'token'>
    tokenIssueTime?: number,
    authErrorMessage?: string
    hideDeviceLimitAlert?: boolean
    lastFetchedBrandId: number | undefined
    currentBrand?: BrandDto;
    portalGlobalMessages: PortalGlobalMessageDto[]
    currentLocation?: Omit<LocationDto, 'brand'>
    message?: string
    errorMessage?: string
    firstTimeLogin: boolean
    resetTokenExpired?: boolean
    resetTokenUsed?: boolean
    inviteTokenExpired?: boolean
    inviteTokenUsed?: boolean
    inviteTokenInvalid?: boolean
    changePasswordSuccessful?: boolean
    changePasswordErrorMessage?: string
    resetPasswordSuccessful?: boolean
    resetPasswordUsername?: string
    resendResetPasswordSuccessful?: boolean
    resetPasswordErrorMessage?: string
    resetPasswordTokenValid?: boolean
    updatePasswordSuccessful?: boolean
    updatePasswordErrorMessage?: string
    forgotPasswordSuccessful?: boolean
    forgotPasswordErrorMessage?: string
    forgotPasswordProcessing?: boolean
    addPortalAccountSuccessful?: boolean
    addPortalAccountErrorMessage?: string
    resendPortalInviteSuccessful?: boolean
    validatePortalInviteTokenSuccessful?: boolean
    demoMode: boolean
    loginFailure: LoginFailureResponseDto
}

const initialState: AppContextState = {
    networkError: false,
    authStatus: 'idle',
    brandDataLoading: false,
    tokenValid: false,
    authorizationDto: undefined,
    firstTimeLogin: false,
    hideDeviceLimitAlert: false,
    forgotPasswordErrorMessage: undefined,
    forgotPasswordSuccessful: undefined,
    resetPasswordSuccessful: undefined,
    lastFetchedBrandId: undefined,
    currentBrand: undefined,
    portalGlobalMessages: [],
    currentLocation: undefined,
    demoMode: false,
    loginFailure: {
        accountLocked: undefined,
        passwordExpired: undefined,
        failedAttempts: undefined,
        ipBlocked: undefined,
        existingResetToken: undefined,
        noPortalAccess: undefined
    }
};

export const authorizeAsync = createAsyncThunk(
    `${appContextSliceName}/authorization`,
    async ({username, password, failedAttempts} : {username:string, password:string, failedAttempts: number}, {dispatch}) => {
        // console.log(`${appContextSliceName}/authorization`, {username, password});

        try {
            const auth = await dispatch(post({urlSuffix: `/api/auth/signin`, params:undefined, body:{username: username, password: password, failedAttempts: failedAttempts}, useToken: false}));
            // console.log({auth});

            return auth.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
);

export const logout = createAsyncThunk(
    `${appContextSliceName}/logout`,
    async (_, {dispatch}) => {
        // console.log(`${appContextSliceName}/authorization`, {username, password});

        try {
            const logout = await dispatch(post({urlSuffix: `/clientportal/logout`, params:undefined, body: {}}));
            // console.log({logout});

            return logout.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
);


export const getLocation = createAsyncThunk(
    `${appContextSliceName}/getLocation`,
    async ({locationId} : {locationId: number}, {dispatch}) => {

        try {
            const auth = await dispatch(get({urlSuffix: `/clientportal/location/get`, params: {locationId}}));

            return auth.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const getBrand = createAsyncThunk(
    `${appContextSliceName}/getBrand`,
    async ({brandId} : {brandId: number}, {dispatch}) => {

        try {
            const auth = await dispatch(get({urlSuffix: `/clientportal/brand/get`, params: {brandId}}));

            return auth.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const brandLogin = createAsyncThunk(
    `${appContextSliceName}/brandLogin`,
    async ({brandId} : {brandId: number}, {dispatch}) => {

        try {
            const auth = await dispatch(get({urlSuffix: `/clientportal/location/getall`, params: {brandId}}));

            return auth.payload;
        }
        catch(err) {
            throw err;
        }
    }
);


export const getPortalGlobalMessages = createAsyncThunk(
    `${appContextSliceName}/getPortalGlobalMessages`,
    async ({brandId} : {brandId: number | undefined}, {dispatch}) => {

        try {
            const messages = await dispatch(get({urlSuffix: `/clientportal/brand/globalmessages`, params: brandId ? {brandId} : undefined}));

            return messages.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const generateAuth = createAsyncThunk(
    `${appContextSliceName}/generateAuth`,
    async ({token} : {token: string}, {dispatch}) => {

        try {
            const auth = await dispatch(post({urlSuffix: `/api/auth/generateauth`, params:undefined, body:{token: token}, useToken: false}));

            return auth.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const forgotPassword = createAsyncThunk(
    `${appContextSliceName}/forgotpassword`,
    async ({username, email, abortController} : {username: string, email: string, abortController?: AbortController} , {dispatch}) => {
        try {
            if (abortController) {
                const forgotPassword = await dispatch(postWithCancel({
                    urlSuffix: `/clientportal/forgotpassword`,
                    params: {username, email},
                    body: undefined,
                    signal: abortController.signal
                }));

                if (abortController.signal.aborted) {
                    throw new Error("rejected")
                }
                return forgotPassword.payload;
            } else {
                const forgotPassword = await dispatch(post({
                    urlSuffix: `/clientportal/forgotpassword`,
                    params: {username, email},
                    body: undefined
                }));

                return forgotPassword.payload;
            }
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const changePassword = createAsyncThunk(
    `${appContextSliceName}/changepassword`,
    async ({passwordUpdateDto} : {passwordUpdateDto: PasswordUpdateDto} , {dispatch}) => {
        // console.log(`${appContextSliceName}/changepassword`);

        try {
            const changePassword = await dispatch(post({urlSuffix: `/clientportal/changepassword`, params:undefined, body:passwordUpdateDto}));
            // console.log({changePassword});

            return changePassword.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const updatePassword = createAsyncThunk(
    `${appContextSliceName}/updatepassword`,
    async ({passwordUpdateDto} : {passwordUpdateDto: PasswordUpdateDto} , {dispatch}) => {
        // console.log(`${appContextSliceName}/updatepassword`);

        try {
            const resetPassword = await dispatch(post({urlSuffix: `/clientportal/updatepassword`, params: undefined, body:passwordUpdateDto}));
            // console.log({resetPassword});

            return resetPassword.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const validateResetPasswordToken = createAsyncThunk(
    `${appContextSliceName}/resetpassword/validate`,
    async ({resetToken} : {resetToken: string} , {dispatch}) => {
        // console.log(`${appContextSliceName}/resetpassword/validate`);

        try {
            const validateToken = await dispatch(post({urlSuffix: `/clientportal/resetpassword/validate`, params: {resetToken}, body:undefined}));
            // console.log({resetPassword});

            return validateToken.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
)

export const validatePortalInviteToken = createAsyncThunk(
    `${appContextSliceName}/validateinvite`,
    async ({inviteToken} : {inviteToken: string}, {dispatch}) => {
        try {
            const validateToken = await dispatch(post({urlSuffix: `/clientportal/employee/validateinvite`, params: {inviteToken}, body:undefined}));

            return validateToken.payload;
        }
        catch(err) {
            throw err;
        }
    }
)

export const resetPassword = createAsyncThunk(
    `${appContextSliceName}/resetpassword`,
    async ({passwordUpdateDto, resetToken} : {passwordUpdateDto: PasswordUpdateDto, resetToken: string} , {dispatch}) => {
        // console.log(`${appContextSliceName}/resetpassword`);

        try {
            const resetPassword = await dispatch(post({urlSuffix: `/clientportal/resetpassword`, params: {resetToken}, body:passwordUpdateDto}));
            // console.log({resetPassword});

            return resetPassword.payload;
        }
        catch (err) {
            // console.log(err);
            throw err;
        }
    }
);

export const resendResetPassword = createAsyncThunk(
    `${appContextSliceName}/resendresetpassword`,
    async ({resetToken} : {resetToken: string}, {dispatch}) => {

        try {
            const resendResetPassword = await dispatch(post({urlSuffix: '/clientportal/resetpassword/resendtoken', params: {resetToken}, body: undefined}));

            return resendResetPassword.payload;
        }
        catch(err) {
            throw err;
        }
    }
);

export const addNewPortalAccount = createAsyncThunk(
    `${appContextSliceName}/addnewaccount`,
    async ({portalAccountDto, inviteToken} : {portalAccountDto: PortalAccountDto, inviteToken: string}, {dispatch}) => {
        // console.log(`${appContextSliceName}/addnewaccount`);

        try {
            const addNewPortalAccount = await dispatch(post({urlSuffix: `/clientportal/employee/addportalaccount`, params: {inviteToken}, body: portalAccountDto}));

            // console.log({addNewPortalAccount});

            return addNewPortalAccount.payload;
        }
        catch(err) {
            // console.log(err);
            throw err;
        }
    }
)

export const resendPortalInvite = createAsyncThunk(
    `${appContextSliceName}/employee/resendportalinvite`,
    async ({inviteToken} : {inviteToken : string}, {dispatch}) => {

        try {
            const resendPortalInvite = await(dispatch(post({urlSuffix: `/clientportal/employee/resendportalinvite`, params: {inviteToken}, body: undefined})));

            return resendPortalInvite.payload;
        }
        catch(err) {
            throw err;
        }
    }
)


export const appContextSlice = createSlice({
    name: appContextSliceName,
    initialState,
    reducers: {
        clearState: () => initialState,
        setFirstTimeLogin: (state) => {
            state.firstTimeLogin = false;
        },
        setHideDeviceLimitAlert: (state, action: PayloadAction<boolean>) => {
            state.hideDeviceLimitAlert = action.payload;
        },
        networkIssue: (state, action: PayloadAction<string>) => {
            state.networkError = true;
            state.networkErrorMessage = action.payload;
        },
        resetNetworkIssue: (state) => {
           state.networkError = false;
           state.networkErrorMessage = undefined;
        },
        setTokenIssueTime: (state, action: PayloadAction<number>) => {
           state.tokenIssueTime = action.payload;
        },
        resetBrandDataLoading: (state) => {
            state.brandDataErrorMessage = undefined;
            state.brandDataLoading = false;
            state.brandDataLoadSuccessful = undefined;
        },
        unauth: (state, action: Action) => {
            state.authorizationDto = undefined;
            state.authStatus = 'idle';
            state.tokenValid = false;
            state.tokenIssueTime = undefined;
            state.currentBrand = undefined;
            state.hideDeviceLimitAlert = false;
            state.demoMode = false;
            state.loginFailure = initialState.loginFailure;

            localStorage.removeItem('token');
        },
        updateDefaultPermission: (state, action: PayloadAction<{locationId: number, permission: DevicePickupPermissionDto | undefined}>) => {
            if(state.authorizationDto?.authorizedLocations && action.payload.permission) {
                const location = state.authorizationDto.authorizedLocations.find(loc => loc.locationId === action.payload.locationId);

                if(location) {
                    state.authorizationDto = {
                        ...state.authorizationDto,
                        authorizedLocations: [
                            ...state.authorizationDto.authorizedLocations.map(loc => {
                                if(loc.locationId === location.locationId) {
                                    return {
                                        ...loc,
                                        defaultDevicePickupPermission: action.payload.permission
                                    }
                                } else {
                                    return loc;
                                }
                            }),
                        ]
                    }
                }
            }
        },
        setUnauthorizedAccess: (state, action: PayloadAction<boolean>) => {
            state.unauthorizedAccess = action.payload;
        },
        resetAuthError: (state) => {
          state.authErrorMessage = undefined;
          state.loginFailure = initialState.loginFailure;
        },
        setLastFetchedBrandId: (state, action: PayloadAction<number | undefined>) => {
            state.lastFetchedBrandId = action.payload;
        },
        setCurrentBrand: (state, action: PayloadAction<BrandDto | undefined>) => {
            state.currentBrand = action.payload;
        },
        setCurrentLocation: (state, action: PayloadAction<Omit<LocationDto, 'brand'>>) => {
            state.currentLocation = action.payload;
        },
        setMessage: (state, action:PayloadAction<string>) => {
            state.message = action.payload;
        },
        setErrorMessage: (state, action:PayloadAction<string>) => {
            state.errorMessage = action.payload;
        },
        resetChangePassword: (state) => {
            state.changePasswordErrorMessage = undefined;
            state.changePasswordSuccessful = undefined;
        },
        resetUpdatePassword: (state) => {
            state.updatePasswordErrorMessage = undefined;
            state.updatePasswordSuccessful = undefined;
            state.authErrorMessage = undefined;
            state.loginFailure = initialState.loginFailure;
        },
        resetForgotPassword: (state) => {
            state.forgotPasswordErrorMessage = undefined;
            state.forgotPasswordSuccessful = undefined;
        },
        resetResetPassword: (state) => {
            state.resetPasswordErrorMessage = undefined;
            state.resetPasswordSuccessful = undefined;
            state.resetPasswordUsername = undefined;
            state.resendResetPasswordSuccessful = undefined;
        },
        resetAllPasswordMessageStates: (state) => {
            state.changePasswordErrorMessage = undefined;
            state.changePasswordSuccessful = undefined;
            state.updatePasswordErrorMessage = undefined;
            state.updatePasswordSuccessful = undefined;
            state.authErrorMessage = undefined;
            state.loginFailure = initialState.loginFailure;
            state.forgotPasswordErrorMessage = undefined;
            state.forgotPasswordSuccessful = undefined;
            state.resetPasswordErrorMessage = undefined;
            state.resetPasswordSuccessful = undefined;
            state.resetPasswordUsername = undefined;
            state.resendResetPasswordSuccessful = undefined;
        },
        resetAddNewPortal: (state) => {
            state.addPortalAccountErrorMessage = undefined;
            state.addPortalAccountSuccessful = undefined;
            state.inviteTokenInvalid = undefined;
            state.inviteTokenUsed = undefined;
            state.inviteTokenExpired = undefined;
            state.resendPortalInviteSuccessful = undefined;
            state.validatePortalInviteTokenSuccessful = undefined;
        },
        setDemoMode: (state, action: PayloadAction<boolean>) => {
            state.demoMode = action.payload;
        },
        resetStatus: (state) => {
            state.authStatus = 'idle'
        }
    },

    // The `extraReducers` field lets the slice handle actions defined elsewhere [including actions generated by createAsyncThunk or in other slices]
    extraReducers: (builder) => {
        builder
            // .addCase(post.rejected, (state) => {
            //     console.log('post.rejected');
            //     state.tokenValid = false;
            //     localStorage.removeItem('token');
            // })
            // .addCase(get.rejected, (state) => {
            //     console.log('get.rejected');
            //     state.tokenValid = false;
            //     localStorage.removeItem('token');
            // })
            .addCase(authorizeAsync.pending, (state) => {
                // console.log('authorizeAsync.pending');
                state.authStatus = 'loading';
            })
            .addCase(authorizeAsync.rejected, (state, action) => {
                // console.log('authorizeAsync.rejected');
                state.authStatus = 'error';
                state.authErrorMessage = getLocalizedString('login.error', 'Unable to Authenticate');

                localStorage.removeItem('token');
            })
            .addCase(authorizeAsync.fulfilled, (state, action) => {
                // console.log('authorizeAsync.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.authErrorMessage = action.payload.errorMessage;
                    state.authStatus = 'error';
                    state.authorizationDto = undefined;

                    localStorage.removeItem('token');
                }
                else if(action.payload?.hasOwnProperty("accountLocked")) {
                    state.loginFailure = {
                        accountLocked: action.payload.accountLocked,
                        passwordExpired: action.payload.passwordExpired,
                        failedAttempts: action.payload.failedAttempts,
                        ipBlocked: action.payload.ipBlocked,
                        existingResetToken: action.payload.existingResetToken,
                        noPortalAccess: action.payload.noPortalAccess
                    }
                    state.authorizationDto = undefined;
                    state.authErrorMessage = undefined;
                    state.authStatus = 'error';
                }
                else if(action.payload) {

                    const {token, ...rest} = action.payload;

                    state.authStatus = 'idle';
                    state.authErrorMessage = undefined;
                    state.authorizationDto = rest;
                    state.firstTimeLogin = rest.firstTimeLogin;

                    //user enters correct credentials but needs to reset their password
                    if(!rest.passwordExpired) {
                        state.tokenValid = true;
                        state.tokenIssueTime = Date.now();
                        localStorage.setItem('token', token);
                    }
                    state.loginFailure = initialState.loginFailure;

                    if(state.authorizationDto?.authorizedBrands.length === 1 && state.authorizationDto?.authorizedLocations.length > 1) {
                        state.currentBrand = state.authorizationDto.authorizedBrands[0];
                        state.currentLocation = AllLocationsDto;
                    }
                    else if (state.authorizationDto?.authorizedLocations.length === 1) {
                        state.currentLocation = state.authorizationDto.authorizedLocations[0];
                        state.currentBrand = state.authorizationDto.authorizedBrands[0];
                    }

                }
                else {
                    state.authErrorMessage = getLocalizedString('server.error', 'Unable to communicate with server')
                    state.authStatus = 'error'
                }
            })
            .addCase(generateAuth.fulfilled, (state, action) => {
                // console.log('authorizeAsync.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.authErrorMessage = action.payload.errorMessage;
                    state.authStatus = 'error';
                    state.authorizationDto = undefined;

                    localStorage.removeItem('token');
                }
                else if(action.payload?.hasOwnProperty("accountLocked")) {
                    state.loginFailure = {
                        accountLocked: action.payload.accountLocked,
                        passwordExpired: action.payload.passwordExpired,
                        failedAttempts: action.payload.failedAttempts,
                        ipBlocked: action.payload.ipBlocked,
                        existingResetToken: action.payload.existingResetToken,
                        noPortalAccess: action.payload.noPortalAccess
                    }
                    state.authorizationDto = undefined;
                    state.authErrorMessage = undefined;
                    state.authStatus = 'error';
                }
                else if(action.payload) {

                    const {token, ...rest} = action.payload;

                    state.authStatus = 'idle';
                    state.authErrorMessage = undefined;
                    state.authorizationDto = rest;

                    state.firstTimeLogin = rest.firstTimeLogin;

                    //user enters correct credentials but needs to reset their password
                    if(!rest.passwordExpired) {
                        state.tokenValid = true;
                        state.tokenIssueTime = Date.now();
                        localStorage.setItem('token', token);
                    }
                    state.loginFailure = initialState.loginFailure;
                    if(state.authorizationDto?.authorizedBrands.length === 1 && state.authorizationDto?.authorizedLocations.length > 1) {
                        state.currentBrand = state.authorizationDto.authorizedBrands[0];
                        state.currentLocation = AllLocationsDto;
                    }
                    else if (state.authorizationDto?.authorizedLocations.length === 1) {
                        state.currentLocation = state.authorizationDto.authorizedLocations[0];
                        state.currentBrand = state.authorizationDto.authorizedBrands[0];
                    }

                }
                else {
                    state.authErrorMessage = getLocalizedString('server.error', 'Unable to communicate with server')
                    state.authStatus = 'error'
                }
            })
            .addCase(brandLogin.pending, (state) => {
                // console.log('authorizeAsync.pending');
                state.brandDataLoading = true;
                state.brandDataErrorMessage = undefined;
            })
            .addCase(brandLogin.rejected, (state, action) => {
                // console.log('authorizeAsync.rejected');
                state.brandDataLoading = false;
                state.brandDataErrorMessage = getLocalizedString('login.brand.error', 'Unable to fetch location data for brand');
                state.brandDataLoadSuccessful = false;
            })
            .addCase(brandLogin.fulfilled, (state, action) => {
                state.brandDataLoading = false;

                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.networkError = true;
                    state.networkErrorMessage = action.payload.errorMessage;
                } else {
                    state.brandDataErrorMessage = undefined;

                    if(state.authorizationDto) {
                        state.authorizationDto = {
                            ...state.authorizationDto,
                            authorizedLocations: action.payload
                        }
                    }

                    if(action.payload?.length > 1) {
                        state.currentLocation = AllLocationsDto;
                    } else if (action.payload?.length === 1) {
                        state.currentLocation =  action.payload[0];
                    }

                    state.brandDataLoadSuccessful = true;
                }
            })
            .addCase(getLocation.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.networkError = true;
                    state.networkErrorMessage = action.payload.errorMessage;
                } else {
                    if(state.authorizationDto) {
                        const locIdx = state.authorizationDto.authorizedLocations.findIndex(loc => loc.locationId === action.payload.locationId);

                        if (locIdx >= 0) {
                            const updatedLocationList = [...state.authorizationDto.authorizedLocations ?? []];
                            updatedLocationList[locIdx] = action.payload;

                            state.authorizationDto = {
                                ...state.authorizationDto,
                                authorizedLocations: updatedLocationList
                            }
                        } else {
                            const updatedLocationList = [...state.authorizationDto.authorizedLocations ?? []];
                            updatedLocationList.push(action.payload);

                            state.authorizationDto = {
                                ...state.authorizationDto,
                                authorizedLocations: updatedLocationList
                            }
                        }
                    }
                }
            })
            .addCase(getBrand.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.networkError = true;
                    state.networkErrorMessage = action.payload.errorMessage;
                } else {
                    const brandIdx = state.authorizationDto?.authorizedBrands.findIndex(brand => brand.brandId === action.payload.brandId);

                    if(brandIdx && brandIdx !== -1) {
                        const updatedBrandList = [...state.authorizationDto?.authorizedBrands ?? []];
                        updatedBrandList[brandIdx] = action.payload;

                        if(state.authorizationDto) {
                            state.authorizationDto = {
                                ...state.authorizationDto,
                                authorizedBrands: updatedBrandList
                            }
                        }
                    }
                }
            })
            .addCase(changePassword.fulfilled, (state, action) => {
                // console.log('changePassword.fulfilled');
                if (action.payload?.hasOwnProperty("successMessage")) {
                    state.changePasswordSuccessful = true;
                    state.changePasswordErrorMessage = undefined;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.changePasswordErrorMessage = action.payload.errorMessage;
                    state.changePasswordSuccessful = false;
                }
                else {
                    state.changePasswordSuccessful = false;
                    state.changePasswordErrorMessage = getLocalizedString('request.error', 'Unable to submit request')
                }
            })
            .addCase(validateResetPasswordToken.fulfilled, (state, action) => {
                // console.log('resetPassword.fulfilled');
                state.resendResetPasswordSuccessful = undefined;
                state.resetPasswordErrorMessage = undefined;

                if(action.payload?.hasOwnProperty("tokenExists") && !action.payload?.tokenExists) {
                    state.resetPasswordTokenValid = false;
                }
                else if(action.payload?.hasOwnProperty("tokenUsed") && action.payload?.tokenUsed) {
                    state.resetTokenUsed = true;
                    state.resetTokenExpired = false;
                    state.resetPasswordTokenValid = true;
                }
                else if(action.payload?.hasOwnProperty("tokenExpired") && action.payload?.tokenExpired) {
                    state.resetTokenExpired = true;
                    state.resetTokenUsed = false;
                    state.resetPasswordTokenValid = true;
                }
                else if(action.payload?.hasOwnProperty("tokenExists") && action.payload?.tokenExists) {
                    state.resetPasswordTokenValid = true;
                    state.resetTokenExpired = false;
                    state.resetTokenUsed = false;
                }
                else {
                    state.resetPasswordErrorMessage = getLocalizedString('request.error', 'Unable to submit request');
                }
            })
            .addCase(validatePortalInviteToken.fulfilled, (state, action) => {
                state.addPortalAccountErrorMessage = undefined;
                state.resendPortalInviteSuccessful = undefined;

                if(action.payload?.hasOwnProperty("tokenExists") && !action.payload?.tokenExists) {
                    state.inviteTokenInvalid = true;
                }
                else if(action.payload?.hasOwnProperty("tokenUsed") && action.payload?.tokenUsed) {
                    state.inviteTokenUsed = true
                    state.inviteTokenInvalid = false;
                    state.inviteTokenExpired = false;
                }
                else if(action.payload?.hasOwnProperty("tokenExpired") && action.payload?.tokenExpired) {
                    state.inviteTokenExpired = true;
                    state.inviteTokenInvalid = false;
                    state.inviteTokenUsed = false;
                }
                else if(action.payload?.hasOwnProperty("tokenExists") && action.payload?.tokenExists) {
                    state.inviteTokenInvalid = false;
                    state.inviteTokenExpired = false;
                    state.inviteTokenUsed = false;
                    state.validatePortalInviteTokenSuccessful = true;
                }
                else {
                    state.addPortalAccountErrorMessage = getLocalizedString('newPortalAccount.validateInvite.error', 'Error while validating invite link, please try again later.');
                }
            })
            .addCase(resendPortalInvite.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("successMessage")) {
                    state.resendPortalInviteSuccessful = true;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.resendPortalInviteSuccessful = false;
                    state.addPortalAccountErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.resendPortalInviteSuccessful = false;
                    state.addPortalAccountErrorMessage = getLocalizedString('newPortalAccount.sendInvite.error', 'There was an error sending a new invite to your email, please contact support.');
                }
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                // console.log('resetPassword.fulfilled');
                if (action.payload?.hasOwnProperty("successMessage")) {
                    state.resetPasswordSuccessful = true;
                    state.resetPasswordUsername = action.payload.successMessage;
                    state.resetPasswordErrorMessage = undefined;
                    state.authErrorMessage = undefined;
                    state.loginFailure = initialState.loginFailure;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.resetPasswordSuccessful = false;
                    state.resetPasswordErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.resetPasswordSuccessful = false;
                    state.resetPasswordErrorMessage = getLocalizedString('request.error', 'Unable to submit request');
                }
            })
            .addCase(resendResetPassword.fulfilled, (state, action) => {
               if(action.payload?.hasOwnProperty("successMessage")) {
                   state.resetPasswordErrorMessage = undefined;
                   state.resendResetPasswordSuccessful = true;
                   state.resetPasswordUsername = action.payload.successMessage;
               }
               else if(action.payload?.hasOwnProperty("errorMessage")) {
                   state.resetPasswordErrorMessage = action.payload.errorMessage;
                   state.resendResetPasswordSuccessful = false;
               }
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                if (action.payload?.hasOwnProperty("successMessage")) {
                    state.updatePasswordSuccessful = true;
                    state.updatePasswordErrorMessage = undefined;
                }
                else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.updatePasswordSuccessful = false;
                    state.updatePasswordErrorMessage = action.payload.errorMessage;
                }
                else {
                    state.updatePasswordSuccessful = false;
                    state.updatePasswordErrorMessage = getLocalizedString('request.error', 'Unable to submit request');
                }
            })
            .addCase(forgotPassword.pending, (state, action) => {
                // console.log('forgotPassword.pending')
              state.forgotPasswordProcessing = true;
            })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                if (action.payload?.hasOwnProperty("successMessage")) {
                    state.forgotPasswordSuccessful = true;
                    state.forgotPasswordErrorMessage = undefined;
                } else if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.forgotPasswordSuccessful = false;
                    state.forgotPasswordErrorMessage = action.payload.errorMessage;
                } else {
                    state.forgotPasswordSuccessful = false;
                    state.forgotPasswordErrorMessage = getLocalizedString('request.error', 'Unable to submit request');
                }

                state.forgotPasswordProcessing = undefined;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.forgotPasswordProcessing = undefined;
                state.forgotPasswordSuccessful = undefined;
                state.forgotPasswordErrorMessage = undefined;
            })
            
            .addCase(addNewPortalAccount.fulfilled, (state, action) => {
                // console.log('addNewPortalAccount.fulfilled');
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.addPortalAccountErrorMessage = action.payload.errorMessage;
                    state.addPortalAccountSuccessful = false;
                }
                else {
                    state.addPortalAccountErrorMessage = undefined;
                    state.addPortalAccountSuccessful = true;
                }
            })
            .addCase(getPortalGlobalMessages.fulfilled, (state, action) => {
                if(action.payload?.hasOwnProperty("errorMessage")) {
                    state.portalGlobalMessages = [];
                }
                else {
                    state.portalGlobalMessages = action.payload;
                }
            })
    },
});

export const appContextSelector = (state: RootState) => state[appContextSliceName];
export const authDtoSelector = (state: RootState) => state[appContextSliceName].authorizationDto;
export const currentBrandSelector = (state: RootState) => state[appContextSliceName].currentBrand;
export const currentLocationSelector = (state: RootState) => state[appContextSliceName].currentLocation;
export const tokenIssueTimeSelector = (state: RootState) => state[appContextSliceName].tokenIssueTime;
export const employeeGroupsSelector = (state: RootState) => (authDtoSelector(state)?.employeeGroups);
export const userGroupsSelector = (state: RootState) => (authDtoSelector(state)?.userGroups);
export const isEmployeeSelector = (state: RootState) => (authDtoSelector(state)?.employee);
export const deviceIssuesSelector = (state: RootState) => (currentBrandSelector(state)?.brandDeviceIssues);
export const authorizedLocationsSelector = (state: RootState) => (authDtoSelector(state)?.authorizedLocations);
export const sortedDeviceIssuesSelector = createDeepEqualSelector(
    deviceIssuesSelector,
    (deviceIssues) => {
        return (deviceIssues?.slice() ?? []).sort((d1, d2) => {
            if (d1.deviceIssueOrder < d2.deviceIssueOrder) {
                return -1;
            }
            if (d1.deviceIssueOrder > d2.deviceIssueOrder) {
                return 1;
            }

            return 0;
        })
    }
)

export const sortedAuthorizedLocationsSelector = createDeepEqualSelector(
    authorizedLocationsSelector,
    currentBrandSelector,
    (locations, currentBrand) => {
        return (locations?.slice() ?? []).filter(l => l.brand.brandId === currentBrand?.brandId).sort((l1, l2) => {
            return l1.locationName.localeCompare(l2.locationName);
        })
    }
)

export const currentUserLocationOptionsSelector = createDeepEqualSelector(
    authorizedLocationsSelector,
    currentBrandSelector,
    currentLocationSelector,
    (locations, currentBrand, currentLocation) => {
        return (locations?.slice() ?? []).filter(l => l.brand.brandId === currentBrand?.brandId && l.locationId !== currentLocation?.locationId).sort((l1, l2) => {
            return l1.locationName.localeCompare(l2.locationName);
        })
    }
)



export const brandViewSelector = () => createDeepEqualSelector(
    currentLocationSelector,
    (currentLocation) => {
        return currentLocation?.locationId === AllLocationsDto.locationId;
    }
)

export const employeePermissionSelector = (group : string) => createDeepEqualSelector(
    employeeGroupsSelector,
    (employeeGroups) => {
        let foundGroup = false;

        employeeGroups?.forEach((authGroup) => {

            if (authGroup.groupName === group) {
                foundGroup = true;
            }
        })

        return foundGroup;
    }
);

export const userPermissionSelector = (group : string) => createDeepEqualSelector(
    userGroupsSelector,
    (userGroups) => {
        let foundGroup = false;

        userGroups?.forEach((authGroup) => {
            if (authGroup === group) {
                foundGroup = true;
            }
        })

        return foundGroup;
    }
);

export const userAccessLevelSelector = () => createDeepEqualSelector(
    employeeGroupsSelector,
    userGroupsSelector,
    isEmployeeSelector,
    (employeeGroups, userGroups, employee) => {
        let accessLevel = 0;

        if(!employee) {
            userGroups?.find(group => {
                if(group === ARC_SUPER_ADMIN) {
                    accessLevel = 1000;
                } else if(group === ARC_TECH_SUPPORT) {
                    accessLevel = -1;
                }

                return group;
            });
        }


        employeeGroups?.forEach(group => {
            if(group.accessLevel > 0) {
                accessLevel = group.accessLevel;
            }
        });

        return accessLevel;
    }
)


export const locationByIdSelector = (locationId: number) => (
    createDraftSafeSelector(authDtoSelector,
        (authDto) => {
            if(authDto && authDto.authorizedLocations) {
                return authDto.authorizedLocations.find(location => location.locationId === locationId);
            }
        }
    )
);

export const brandByIdSelector = (brandId: number) => (
    createDraftSafeSelector(authDtoSelector,
        (authDto) => {
            if(authDto && authDto.authorizedBrands) {
                return authDto.authorizedBrands.find(brand => brand.brandId === brandId);
            }
        }
    )
);

export const locationsForBrandSelector = (brandId: number) => (
    createDraftSafeSelector(authDtoSelector,
        (authDto) => {
            if(authDto && authDto.authorizedLocations) {
                return authDto.authorizedLocations.filter(location => location.brand.brandId === brandId);
            }
            else {
                return [];
            }
        }
    )
);

export const appContextActions = appContextSlice.actions;
export default appContextSlice.reducer;

