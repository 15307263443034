import * as React from 'react';

type VanillaCheckboxProps = {
    onClick: () => void,
    checked: boolean,
    style?: React.CSSProperties
}

export const VanillaCheckbox:React.ComponentType<VanillaCheckboxProps> = ({onClick, checked, style, children}) => {

    return (
        <label className={`custom-checkbox ${checked ? 'checked' : ''}`}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onClick}
                style={{cursor: 'pointer'}}
            />
            <span style={style} className="checkmark">&#10003;</span>

            {children}
        </label>
    )
}
