import * as React from 'react';
import {createStyles, makeStyles, styled, Theme} from "@material-ui/core/styles";
import {BLACK} from "../../../themes/colors";
import {calculatedFontSize} from "../../../util/TextUtils";
import {Box, Link, Menu, MenuItem, Typography} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextActions, appContextSelector, logout} from "../../app-context/appContextSlice";
import {useCurrentUserContext} from "../../app-context/CurrentUserContextContainer";
import {getLocalizedString} from "../../../util/Localization";

type UserProfilePropsType = {
    firstname: string | undefined;
    lastname: string | undefined;
}

export const UserProfile:React.ComponentType<UserProfilePropsType> = (props) => {
    const styles = useStyles();
    const navigate = useNavigate();
    const context = useAppSelector(appContextSelector);
    const dispatch = useAppDispatch();
    const currentUserContext = useCurrentUserContext();

    const [anchorUser, setAnchorUser] = React.useState<null | HTMLElement>(null);

    const initials = React.useMemo(() => {
        if(currentUserContext.groups.isSSOHQUser) {
            return 'HQ';
        } else if (props.firstname && props.lastname) {
            return props.firstname.charAt(0) + props.lastname.charAt(0);
        } else {
            return 'acc';
        }
    }, [currentUserContext.groups.isSSOHQUser, props.firstname, props.lastname]);

    const name = React.useMemo(() => {
        if(currentUserContext.groups.isSSOHQUser) {
            return props.lastname;
        } else if (props.firstname && props.lastname) {
            return props.firstname + ' ' + props.lastname;
        } else {
            return 'acc';
        }
    }, [currentUserContext.groups.isSSOHQUser, props.firstname, props.lastname]);


    const handleOpenUserMenu = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorUser(event.currentTarget);
    }, []);

    const handleCloseUserMenu = React.useCallback(() => {
        setAnchorUser(null);
    }, []);

    const unauth = React.useCallback(async () => {
        await dispatch(appContextActions.unauth());
        dispatch(logout());
        navigate('/login');
    }, [dispatch, navigate]);

    const showChangePassword = React.useMemo(() => {
        return !(context.authorizationDto?.employee && context.currentBrand?.employeeAPIEnabled);
    }, [context.authorizationDto?.employee, context.currentBrand?.employeeAPIEnabled]);

    return (
            <Box style={{ flexGrow: 0 }}>
                <Link onClick={() => {}} style={{textDecoration: 'none', cursor: 'pointer'}}>
                    <div className={styles.circle} onClick={handleOpenUserMenu}>
                        {initials}
                    </div>
                </Link>
                <Menu
                    style={{ marginTop: '45px', width:'45%' }}
                    id="menu-appbar"
                    anchorEl={anchorUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorUser)}
                    onClose={handleCloseUserMenu}
                >
                    <CenterText className={styles.user_full_name}>{name}</CenterText>

                    {context.authorizationDto?.authorizedBrands && context.authorizationDto.authorizedBrands.length > 1 &&
                    <ProfileMenuItem onClick={() => {
                        navigate('/changebrand');
                    }}>
                        <ScaledText >{getLocalizedString('profile.changeBrand', 'Change Brand')}</ScaledText>
                    </ProfileMenuItem>
                    }

                    {showChangePassword &&
                    <ProfileMenuItem onClick={() => {
                        navigate('/changepassword')
                    }}>
                        <ScaledText>{getLocalizedString('profile.changePassword', 'Change Password')}</ScaledText>
                    </ProfileMenuItem>
                    }

                    <ProfileMenuItem onClick={unauth}>
                        <ScaledText >{getLocalizedString('profile.logout', 'Logout')}</ScaledText>
                    </ProfileMenuItem>
                </Menu>
            </Box>
    )
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle: {
            width: '70px',
            height: '70px',
            lineHeight: '70px',
            borderRadius: '50%',
            border: '2px solid black',
            fontSize: calculatedFontSize('1.75em', '1.9em'),
            fontWeight: 525,
            color: BLACK,
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
            background: '#00',
            [`${theme.breakpoints.down('xs')}`]: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '55px',
                height: '55px',
            }
            },
        user_full_name: {
            borderBottom: '1px solid black',
            fontWeight: 'bold',
            fontSize: calculatedFontSize('1.1em', '1.25em')
        }
    }),

);

const ScaledText = styled(Typography)({
    fontSize: calculatedFontSize('.8em', '1.25em')
});

const CenterText = styled(Typography)({
    textAlign: 'center'
});

const ProfileMenuItem = styled(MenuItem)({
    height: '20vh',
    minHeight: '20px',
    maxHeight: '30px'
})
