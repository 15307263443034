import {DeviceDto, INACTIVE_REASONS} from "../../../dtos/Device";
import * as React from "react";
import {Box, IconButton, TextareaAutosize, Typography} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {styled} from "@material-ui/core/styles";
import {getLocalizedString} from "../../../util/Localization";
import {ARCButton} from "../../ui-components/design-system/ARCButton";

type DeactivateDeviceNotesProps = {
    formDevice: DeviceDto, 
    setFormDevice: React.Dispatch<React.SetStateAction<DeviceDto>>,
    setShowNotes: React.Dispatch<React.SetStateAction<boolean>>,
    submitForm: () => void,
    styles: any
}

export const DeactivateDeviceNotes:React.ComponentType<DeactivateDeviceNotesProps> = (props) => {
    const maxTextCount = 250;
    const required = React.useMemo(() => {
        const formDevice = props.formDevice;
        const missing = formDevice.inactiveReason === INACTIVE_REASONS.INACTIVE_REASON_MISSING;
        const other = formDevice.inactiveReason === INACTIVE_REASONS.INACTIVE_REASON_OTHER;
        const nonFunctional = formDevice.inactiveReason === INACTIVE_REASONS.INACTIVE_REASON_NONFUNCTIONAL;
        if (missing || other) {
            return true;
        }
        if (nonFunctional && formDevice.deviceIssues && formDevice.deviceIssues?.length > 0) {
            let hasOther = false;
            formDevice.deviceIssues.forEach((issue) => {
                if (issue.deviceIssueType === 'Other') {
                    hasOther = true;
                }
            })
            if (hasOther) {
                return true;
            }
        }
        return false;
    }, [props.formDevice])
    
    const disableSave = React.useMemo(() => {
        const formDevice = props.formDevice;
        if (formDevice.inactiveReasonDetail === null || formDevice.inactiveReasonDetail?.length < 10) {
            return required;
        }
        return false;
    },[props.formDevice, required])
    
    const redCount = React.useMemo(() => {
        return disableSave || (maxTextCount === (props.formDevice.inactiveReasonDetail?.length ?? 0))
    }, [disableSave, maxTextCount, props.formDevice.inactiveReasonDetail])
    
    const notesOnChange = React.useCallback((event) => {
        if (event.target.value?.length <= maxTextCount) {
            props.setFormDevice({
                ...props.formDevice,
                inactiveReasonDetail: event.target.value
            })
        }
    }, [props])
    return (
        <div className={props.styles.form_root}>
            <IconButton color="primary" className={'back-button'} aria-label="back" onClick={() => {
                props.setShowNotes(false)
            }}>
                <ArrowBack className={'back-button-arrow'}/>
            </IconButton>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div className={props.styles.form_title_div}>
                    <div className={props.styles.form_title_text}>{getLocalizedString('deactivateDevice.title', 'Deactivate Device')} {props.formDevice.deviceTag}</div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', maxWidth: '70vw'}}>
                    <Typography className={props.styles.reason_detail_label} style={{paddingTop: '10px'}}>
                        {getLocalizedString('deactivateDevice.notes', 'Please share more information. Details really help! ')}
                        <span className={props.styles.reason_detail_label} style={{color: 'red', fontStyle: 'italic'}}>
                            {required ? getLocalizedString('deactivateDevice.notes.required', '(required)') : ''}
                        </span>
                    </Typography>
                </div>
                <div style={{display: 'flex', width: '100%', position: 'relative', height: '20vh', maxWidth: '800px', minWidth: '150px', maxHeight: '300px'}}>
                    <DeactivateNotes 
                        className={props.styles.reason_detail_text} 
                        value={props.formDevice.inactiveReasonDetail} 
                        onChange={notesOnChange}/>
                    <Box
                        position="absolute"
                        bottom={0}
                        right={0}
                        p={1}
                        color={redCount ? 'red' : 'inherit'}
                    >
                        <Typography variant="caption">
                            {props.formDevice.inactiveReasonDetail?.length ?? 0}/{maxTextCount}
                        </Typography>
                    </Box>
                </div>
                <div className={props.styles.reason_detail_required_text}>
                    {required ? getLocalizedString('deactivateDevice.notes.required.length', 'enter at least 10 characters') : ''}
                </div>
                <div className={props.styles.buttonDiv}>
                    <ARCButton
                        fill={'filled'}
                        variant={'primary'}
                        size={'md'}
                        id="adduser-save"
                        onClick={props.submitForm}
                        disabled={disableSave}
                        label={getLocalizedString('deactivateDevice.notes.submit', 'Deactivate Device')}
                    >
                    </ARCButton>
                </div>
            </div>
        </div>
    )
}

export const DeactivateNotes = styled(TextareaAutosize) (({theme}) => ({
    margin: 0,
    marginTop: '10px',
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '11px',
    height: '100% !important',
    width: '70vw !important',
    paddingLeft: '5px',
    paddingTop: '5px',
    '&:focus': {
        outline: 0
    }
}));
