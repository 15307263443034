import * as React from 'react';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {appContextActions, appContextSelector, brandLogin} from "../../app-context/appContextSlice";
import {useLocation, useNavigate} from "react-router-dom";
import {BrandDto} from "../../../dtos/Brand";
import {AppWithFooter} from "../../../App";
import AppBar from "../../appbar/AppBar";
import {SideBar} from "../../sidebar/SideBar";
import {BrandListItem} from "../../ui-components/BrandItem";
import {FormTitle, CenteredDiv, SuccessMessageText, MainContentContainer} from "../../ui-components/StyledComponents";
import {LocationState} from "../../../@types/types";
import {selectBrandThunk} from "../../app-context/thunks/SelectBrandThunk";
import {useCurrentUserContext} from "../../app-context/CurrentUserContextContainer";
import {CircularProgress, Switch, Typography} from "@material-ui/core";
import {calculatedFontSize} from "../../../util/TextUtils";
import {getLocalizedString} from "../../../util/Localization";

export const BrandSelect = () => {
    const context = useAppSelector(appContextSelector);
    const currentUserContext = useCurrentUserContext();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const authDto = context.authorizationDto;
    let sortedBrands;
    
    const [selectedBrandId, setSelectedBrandId] = React.useState<number | undefined>(undefined);

    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);
    const {state} = useLocation() as LocationState;

    React.useEffect(() => {
        dispatch(appContextActions.resetBrandDataLoading());
    }, [dispatch]);

    React.useEffect(() => {
        return () => {
            dispatch(appContextActions.resetBrandDataLoading());
        }
    }, [dispatch]);

    React.useEffect(() => {
        if(state) {
            const { successmessage } = state; // Read values passed on state

            if(successmessage) {
                setSuccessMessage(successmessage);
            }
        }
    }, [state]);

    if(authDto?.authorizedBrands) {
        const brands: BrandDto[] = [...authDto?.authorizedBrands];

        sortedBrands = brands?.sort((b1, b2) => b1.brandName.localeCompare(b2.brandName));
    }

    React.useEffect(() => {
        if(context.brandDataLoadSuccessful && selectedBrandId) {
            setSelectedBrandId(undefined);

            dispatch(appContextActions.resetBrandDataLoading());

            if(state?.navToEmergency) {
                navigate(`/faq/emergency/keypads/view`);
            } else if (currentUserContext.groups.isArcTechSupport) {
                navigate(`/employees/view/locationId=${context.currentLocation?.locationId}/brandId=${context.currentBrand?.brandId}`);
            } else {
                navigate(`/reports/view/locationId=${context.currentLocation?.locationId}`);
            }
        }
    }, [context.brandDataLoadSuccessful, context.currentBrand?.brandId, context.currentLocation?.locationId, currentUserContext.groups.isArcTechSupport, dispatch, navigate, selectedBrandId, state?.navToEmergency]);
    
    React.useEffect(() => {
        if (selectedBrandId) {
            dispatch(selectBrandThunk(selectedBrandId));
            dispatch(brandLogin({brandId: selectedBrandId}));
        }
    }, [selectedBrandId, dispatch])

    return (
        <AppWithFooter>
            <AppBar title={<></>} logoNonInteractive={true}/>
            <CenteredDiv>
            <SideBar links={<></>}/>
            <MainContentContainer style={{alignItems: 'unset'}}>

                {successMessage &&
                <SuccessMessageText>
                    {successMessage}
                </SuccessMessageText>}

                    <div style={{maxWidth: '600px', display: 'flex', flexDirection: 'column'}}>
                        <FormTitle variant='h5'>{getLocalizedString('brandselect.title', 'SELECT A BRAND')}</FormTitle>

                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px'}}>
                            <Typography style={{marginRight: '10px', fontSize: calculatedFontSize('1em', '1.4em')}}>
                                {getLocalizedString('brandselect.demoMode', 'Demo Mode')}
                            </Typography>
                            <Switch color={'primary'} checked={context.demoMode} onClick={() => dispatch(appContextActions.setDemoMode(!context.demoMode))}/>
                        </div>
                        {
                        sortedBrands?.map((brand, idx) => {
                            return (
                                    <div key={idx} style={{display: 'flex', alignContent: 'baseline'}}>
                                        <BrandListItem brand={brand} onClick={() => {setSelectedBrandId(brand.brandId)}} key={brand.brandId}>
                                        </BrandListItem>

                                        {selectedBrandId && selectedBrandId === brand.brandId &&
                                            <CircularProgress/>
                                        }
                                    </div>
                            );
                            })
                        }
                    </div>
            </MainContentContainer>
            </CenteredDiv>
        </AppWithFooter>
    )
}
