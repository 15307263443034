import '../css/design-system/main.scss';
import '../css/design-system/_text.scss';
import {
    ARCButton,
    ARCButtonFillType,
    ARCButtonSizeType,
    ARCButtonVariantType
} from "../features/ui-components/design-system/ARCButton";
import * as React from "react";
import {ARCIcon, ARCIcons, ARCIconSizes} from "../features/ui-components/design-system/ARCIcon";
import {ARCTextInput} from "../features/ui-components/design-system/ARCTextInput";
import {ARCTextArea} from "../features/ui-components/design-system/ARCTextArea";
import {ARCSelectInput} from "../features/ui-components/design-system/ARCSelectInput";
import {ARCRadioInput} from "../features/ui-components/design-system/ARCRadioInput";
import {ARCCheckbox} from "../features/ui-components/design-system/ARCCheckbox";
import {ARCFormError} from "../features/ui-components/design-system/ARCFormError";
import {ARCSelectFilterInput} from "../features/ui-components/design-system/ARCSelectFilterInput";
import {ARCTextFilterInput} from "../features/ui-components/design-system/ARCTextFilterInput";
import {ARCBadge} from "../features/ui-components/design-system/ARCBadge";
import {ARCRuler} from "../features/ui-components/design-system/ARCRuler";
import {ARCAccordion} from "../features/ui-components/design-system/ARCAccordion";
import {ARCFooter} from "../features/ui-components/design-system/ARCFooter";
import {
    ARCTextLink,
    ARCTextLinkSizeType,
    ARCTextLinkVariantType
} from "../features/ui-components/design-system/ARCTextLink";


export const DesignSystemDemo = () => {


    //Icons
    const iconSizes : ARCIconSizes[] = ['sm', 'md'];
    const icons : ARCIcons[] = ['add-user',
                                'users',
                                'power',
                                'graph',
                                'alert',
                                'charge',
                                'envelope',
                                'phone',
                                'chevron-down',
                                'close',
                                'add-device',
                                'future',
                                'checkmark',
                                'arrow-down',
                                'arrow-up',
                                'arrow-left',
                                'arrow-right',
                                'magnify',
                                'qr-code',
                                'book'];

    //Buttons
    const buttonSizes : ARCButtonSizeType[] = ['sm', 'md', 'lg'];
    const buttonVariants : ARCButtonVariantType[] = ['primary', 'secondary', 'tertiary', 'danger'];
    const buttonStyles : ARCButtonFillType[] = ['filled', 'outlined'];
    const [buttonText, setButtonText] = React.useState<string | undefined>(undefined);
    const [buttonIcon, setButtonIcon] = React.useState<ARCIcons | undefined>(undefined);
    const [buttonsDisabled, setButtonsDisabled] = React.useState<boolean>(false);
    
    // Text Links
    const textLinkSizes : ARCTextLinkSizeType[] = ['sm', 'md'];
    const textLinkVariants : ARCTextLinkVariantType[] = ['primary', 'secondary'];
    const [textLinkText, setTextLinkText] = React.useState<string | undefined>(undefined);
    const [textLinkIcon, setTextLinkIcon] = React.useState<ARCIcons | undefined>(undefined);
    const [textLinksDisabled, setTextLinksDisabled] = React.useState<boolean>(false);

    type formInputSize = 'sm' | 'md';
    type fullFormInputSize = 'sm' | 'md' | 'lg';
    const formInputSizes : formInputSize[] = ['sm', 'md'];
    const fullFormInputSizes : fullFormInputSize[] = ['sm', 'md', 'lg'];

    const [textInputLabel, setTextInputLabel] = React.useState<string | undefined>(undefined);
    const [textInputErrorMessage, setTextInputErrorMessage] = React.useState<string | undefined>(undefined);
    const [textInputRequired, setTextInputRequired] = React.useState<boolean>(false);
    const [textInputDisabled, setTextInputDisabled] = React.useState<boolean>(false);

    const [textAreaLabel, setTextAreaLabel] = React.useState<string | undefined>(undefined);
    const [textAreaErrorMessage, setTextAreaErrorMessage] = React.useState<string | undefined>(undefined);
    const [textAreaRequired, setTextAreaRequired] = React.useState<boolean>(false);
    const [textAreaDisabled, setTextAreaDisabled] = React.useState<boolean>(false);

    const [selectInputLabel, setSelectInputLabel] = React.useState<string | undefined>(undefined);
    const [selectInputErrorMessage, setSelectInputErrorMessage] = React.useState<string | undefined>(undefined);
    const [selectInputRequired, setSelectInputRequired] = React.useState<boolean>(false);
    const [selectInputDisabled, setSelectInputDisabled] = React.useState<boolean>(false);
    const [selectValue, setSelectValue] = React.useState<{[size: string]: string | undefined}>({'sm' : undefined, 'md': undefined});

    const [radioInputLabel, setRadioInputLabel] = React.useState<string | undefined>(undefined);
    const [radioInputErrorMessage, setRadioInputErrorMessage] = React.useState<string | undefined>(undefined);
    const [radioInputRequired, setRadioInputRequired] = React.useState<boolean>(false);
    const [radioInputDisabled, setRadioInputDisabled] = React.useState<boolean>(false);
    const [radioChecked, setRadioChecked] = React.useState<{[size: string]: boolean}>({'sm' : false, 'md': false, 'lg': false});

    const [checkboxInputLabel, setCheckboxInputLabel] = React.useState<string | undefined>(undefined);
    const [checkboxInputErrorMessage, setCheckboxInputErrorMessage] = React.useState<string | undefined>(undefined);
    const [checkboxInputRequired, setCheckboxInputRequired] = React.useState<boolean>(false);
    const [checkboxInputDisabled, setCheckboxInputDisabled] = React.useState<boolean>(false);
    const [checkboxChecked, setCheckboxChecked] = React.useState<{[size: string]: boolean}>({'sm' : false, 'md': false, 'lg': false});

    const [filterInputLabel, setFilterInputLabel] = React.useState<string | undefined>(undefined);
    const [filterInputDisabled, setFilterInputDisabled] = React.useState<boolean>(false);
    const [selectFilterValue, setSelectFilterValue] = React.useState<string | undefined>(undefined);
    const [searchFilterValue, setSearchFilterValue] = React.useState<string | undefined>(undefined);

    const [formErrorMessage, setFormErrorMessage] = React.useState<string | undefined>(undefined);

    const statusBadgeStatuses : ('on' | 'standby' | 'off')[] = ['on', 'standby', 'off'];
    const [statusBadgeMessage, setStatusBadgeMessage] = React.useState<string | undefined>(undefined);
    const [filterBadgeCount, setFilterBadgeCount] = React.useState<number>(0);

    return (
        <div>
        <div className="l-container--lg l-wrap" style={{backgroundColor: '--color-neutral-90'}}>

            <h1 className="o-heading--xl u-display--flex u-justify-content--center">ARC Design System Demo</h1>

            Dev Notes:
            1. Change background color at each breakpoint
            2. If new classes need to be created, use the BEM method (example: https://csswizardry.com/2015/03/more-transparent-ui-code-with-namespaces/)
            <h1>Default H1 Style</h1>
            <h2>Default H2 Style</h2>
            <h3>Default H3 Style</h3>
            <h4>Default H4 Style</h4>
            <h5>Default H5 Style</h5>
            <h6>Default H6 Style</h6>
            <h1 className="o-heading--xl">This is a Long Heading Example for o-heading--xl</h1>
            <h2 className="o-heading--l">This is a Long Heading Example for o-heading--l</h2>
            <h3 className="o-heading--m">This is a Long Heading Example for o-heading--m</h3>
            <h4 className="o-heading--s">This is a Long Heading Example for o-heading--s</h4>
            <h5 className="o-heading--xs">This is a Long Heading Example for o-heading--xs</h5>
            <hr />
            <h1>Default Paragraph tags</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In at mi nulla. <em>Fusce iaculis placerat enim, ut semper risus posuere eu. Praesent ullamcorper laoreet felis nec maximus.</em> Vivamus gravida libero a risus sagittis volutpat at vel nibh. Aenean dignissim gravida mi, id vestibulum enim maximus nec. Mauris tincidunt sollicitudin lorem quis viverra. Donec gravida auctor nulla vitae ultricies. Sed dignissim bibendum massa, et bibendum nunc sodales vel. Nunc euismod neque id vestibulum finibus.</p>
            <p>Praesent ac justo est. Nunc enim tortor, consectetur ultrices volutpat quis, feugiat quis felis. Pellentesque quam odio, convallis ac molestie nec, scelerisque eget nibh. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quam odio, volutpat in pharetra sed, ullamcorper vel ante. In interdum ultricies lorem. Cras euismod, turpis ut tincidunt maximus, neque neque consectetur arcu, eget aliquet tellus urna id nisl. Vivamus vitae odio ut enim tristique commodo ut vel eros. Ut ac tellus orci. Fusce faucibus ipsum dolor. Etiam a eros porttitor, convallis neque nec, dapibus leo.</p>
            <h1>o-body-text examples</h1>
            <p className="o-body-text">This is an example of body text using the o-body-text class. Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis. </p>
            <p className="o-body-text--l">This is an example of body text using the o-body-text class. Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis. </p>
            <p className="o-body-text--m">This is an example of body text using the o-body-text class. Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis. </p>
            <p className="o-body-text--s">This is an example of body text using the o-body-text class. Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis. </p>
            <p className="o-body-text--xs">This is an example of body text using the o-body-text class. Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis. </p>
            <hr />
            <h1>Unordered List with Unordered Sublist</h1>
            <ul>
                <li><strong>This is an unordered list.</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum lectus vitae lobortis placerat. Integer id venenatis mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                <li>Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis.
                    <ul>
                        <li><strong>This is an unordered sublist.</strong> Praesent ac justo est. Nunc enim tortor, consectetur ultrices volutpat quis, feugiat quis felis. Pellentesque quam odio, convallis ac molestie nec, scelerisque eget nibh.</li>
                        <li>Cras euismod, turpis ut tincidunt maximus, neque neque consectetur arcu, eget aliquet tellus urna id nisl. Vivamus vitae odio ut enim tristique commodo ut vel eros. </li>
                        <li>Sub List item 3</li>
                        <li>Sub List item 4</li>
                        <li>Sub List item 5</li>
                    </ul>
                </li>
                <li>List item 3</li>
                <li>List item 4</li>
                <li>List item 5</li>
            </ul>
            <h1>Unordered List with Ordered Sublist</h1>
            <ul>
                <li><strong>This is an unordered list.</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum lectus vitae lobortis placerat. Integer id venenatis mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                <li>Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis.
                    <ol>
                        <li><strong>This is an ordered sublist.</strong> Praesent ac justo est. Nunc enim tortor, consectetur ultrices volutpat quis, feugiat quis felis. Pellentesque quam odio, convallis ac molestie nec, scelerisque eget nibh.</li>
                        <li>Cras euismod, turpis ut tincidunt maximus, neque neque consectetur arcu, eget aliquet tellus urna id nisl. Vivamus vitae odio ut enim tristique commodo ut vel eros. </li>
                        <li>Sub List item 3</li>
                        <li>Sub List item 4</li>
                        <li>Sub List item 5</li>
                    </ol>
                </li>
                <li>List item 3</li>
                <li>List item 4</li>
                <li>List item 5</li>
            </ul>
            <h1>Ordered List with Ordered Sublist</h1>
            <ol>
                <li><strong>This is an ordered list.</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum lectus vitae lobortis placerat. Integer id venenatis mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                <li>Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis.
                    <ol>
                        <li><strong>This is an ordered sublist.</strong> Praesent ac justo est. Nunc enim tortor, consectetur ultrices volutpat quis, feugiat quis felis. Pellentesque quam odio, convallis ac molestie nec, scelerisque eget nibh.</li>
                        <li>Cras euismod, turpis ut tincidunt maximus, neque neque consectetur arcu, eget aliquet tellus urna id nisl. Vivamus vitae odio ut enim tristique commodo ut vel eros. </li>
                        <li>Sub List item 3</li>
                        <li>Sub List item 4</li>
                        <li>Sub List item 5</li>
                    </ol>
                </li>
                <li>List item 3</li>
                <li>List item 4</li>
                <li>List item 5</li>
            </ol>
            <h1>Ordered List with Unordered Sublist</h1>
            <ol>
                <li><strong>This is an ordered list.</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dictum lectus vitae lobortis placerat. Integer id venenatis mi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</li>
                <li>Praesent eget velit at erat tincidunt iaculis sed feugiat nisl. Nunc eget nibh erat. Suspendisse efficitur orci et bibendum dignissim. Sed eu nisl in magna gravida convallis.
                    <ul>
                        <li><strong>This is an unordered sublist.</strong> Praesent ac justo est. Nunc enim tortor, consectetur ultrices volutpat quis, feugiat quis felis. Pellentesque quam odio, convallis ac molestie nec, scelerisque eget nibh.</li>
                        <li>Cras euismod, turpis ut tincidunt maximus, neque neque consectetur arcu, eget aliquet tellus urna id nisl. Vivamus vitae odio ut enim tristique commodo ut vel eros. </li>
                        <li>Sub List item 3</li>
                        <li>Sub List item 4</li>
                        <li>Sub List item 5</li>
                    </ul>
                </li>
                <li>List item 3</li>
                <li>List item 4</li>
                <li>List item 5</li>
            </ol>
            <hr />
            <h1>Show all containers:</h1>
            <div className="l-container--lg l-wrap" style={{ background: 'var(--color-yellow-70)' }}>This is a div element that tests out the l-container--xl. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed massa nibh. Nulla at lacus ut lacus lobortis pellentesque ac luctus arcu. Fusce eu ante ut libero fringilla semper nec id augue. Integer laoreet fermentum rhoncus. Integer venenatis, odio nec cursus dignissim, est lectus pharetra purus, tincidunt sagittis orci tortor vitae enim. Nulla vulputate eleifend lacinia. Sed laoreet, urna sit amet rutrum euismod, lacus ligula placerat lectus, eget egestas sapien tortor at justo. Nam in magna eu orci dictum interdum quis eu lectus.</div>
            <div className="l-container l-wrap" style={{ background: 'var(--color-yellow-50)' }}>This is a div element that tests out the (default) l-container. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed massa nibh. Nulla at lacus ut lacus lobortis pellentesque ac luctus arcu. Fusce eu ante ut libero fringilla semper nec id augue. Integer laoreet fermentum rhoncus. Integer venenatis, odio nec cursus dignissim, est lectus pharetra purus, tincidunt sagittis orci tortor vitae enim. Nulla vulputate eleifend lacinia. Sed laoreet, urna sit amet rutrum euismod, lacus ligula placerat lectus, eget egestas sapien tortor at justo. Nam in magna eu orci dictum interdum quis eu lectus.</div>
            <div className="l-container--md l-wrap" style={{ background: 'var(--color-yellow-40)' }}>This is a div element that tests out the l-container--m. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed massa nibh. Nulla at lacus ut lacus lobortis pellentesque ac luctus arcu. Fusce eu ante ut libero fringilla semper nec id augue. Integer laoreet fermentum rhoncus. Integer venenatis, odio nec cursus dignissim, est lectus pharetra purus, tincidunt sagittis orci tortor vitae enim. Nulla vulputate eleifend lacinia. Sed laoreet, urna sit amet rutrum euismod, lacus ligula placerat lectus, eget egestas sapien tortor at justo. Nam in magna eu orci dictum interdum quis eu lectus.</div>
            <div className="l-container--sm l-wrap" style={{ background: 'var(--color-yellow-20)' }}>This is a div element that tests out the l-container--s. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut sed massa nibh. Nulla at lacus ut lacus lobortis pellentesque ac luctus arcu. Fusce eu ante ut libero fringilla semper nec id augue. Integer laoreet fermentum rhoncus. Integer venenatis, odio nec cursus dignissim, est lectus pharetra purus, tincidunt sagittis orci tortor vitae enim. Nulla vulputate eleifend lacinia. Sed laoreet, urna sit amet rutrum euismod, lacus ligula placerat lectus, eget egestas sapien tortor at justo. Nam in magna eu orci dictum interdum quis eu lectus.</div>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p><a href="">This is a text link</a></p>
            <h1>Icons:</h1>

            <div className="l-container--lg l-wrap u-display--flex u-flex-wrap" style={{background: 'var(--color-neutral-90)'}}>

                {
                    icons.map(icon => {
                        return (
                            <div className="u-space-sm u-display--flex u-display--flex-column u-align-items--center">
                                <h3 className="o-heading--m" style={{color: 'var(--color-neutral-10)'}}>{icon}</h3>
                                {
                                    iconSizes.map(size => {
                                        return (
                                            <ARCIcon icon={icon} size={size} className={'u-space-sm'} color={'var(--color-neutral-10)'}/>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </div>

            <h1>Buttons:</h1>


            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCSelectInput size={'sm'} id={'button-sample-icon'} required={false} className={'arc-text-input u-space-sm'} options={[{value: undefined, label: 'Select Icon...'}, ...icons.map(icon => {return {value: icon as string, label: icon as string, icon: <ARCIcon icon={icon} size={'sm'}/>}})]} label={'Select Icon'} value={buttonIcon} onChange={(option) => {setButtonIcon(option.value as ARCIcons)}}/>
                    <ARCTextInput size={'sm'} id={'button-sample-text-input'} required={false} value={undefined} className={'u-space-sm'} onChange={(event) => setButtonText(event.target.value)} label={'Sample Text'} placeholder={'Button Sample Text'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'button-disabled-checkbox'} label={'Disabled?'} onChange={() => setButtonsDisabled(!buttonsDisabled)} checked={buttonsDisabled}/>
                    </div>
                </div>

                <div className={'u-display--flex u-justify-content--center u-flex-wrap'}>
                    {buttonSizes.map(size => {
                        return (
                        <div className={'u-space-sm'}>
                            <h1>{size}</h1>
                            {
                                buttonStyles.map(style => {
                                    return (
                                        <div className={'u-space-sm'}>
                                            {style}
                                            {
                                                buttonVariants.map(variant => {
                                                    return (
                                                        <div className={'u-space-sm'}>
                                                            {variant}
                                                            <ARCButton size={size}
                                                                       variant={variant}
                                                                       fill={style}
                                                                       label={buttonText ? buttonText : 'Text'}
                                                                       icon={buttonIcon}
                                                                       disabled={buttonsDisabled}
                                                                       onClick={() => {}}/>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        )
                    })}
                </div>
            </div>
            
            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCSelectInput 
                        size={'sm'} 
                        id={'textlink-sample-icon'} 
                        required={false} 
                        className={'arc-text-input u-space-sm'} 
                        options={[{value: undefined, label: 'Select Icon...'}, ...icons.map(icon => {return {value: icon as string, label: icon as string, icon: <ARCIcon icon={icon} size={'sm'}/>}})]} label={'Select Icon'} value={textLinkIcon} onChange={(option) => {setTextLinkIcon(option.value as ARCIcons)}}
                    />
                    <ARCTextInput size={'sm'} id={'textlink-sample-text-input'} required={false} value={undefined} className={'u-space-sm'} onChange={(event) => setTextLinkText(event.target.value)} label={'Sample Text'} placeholder={'Text Link Sample Text'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'textlink-disabled-checkbox'} label={'Disabled?'} onChange={() => setTextLinksDisabled(!textLinksDisabled)} checked={textLinksDisabled}/>
                    </div>
                </div>

                <div className={'u-display--flex u-justify-content--center u-flex-wrap'}>
                    {textLinkSizes.map(size => {
                        return (
                        <div className={'u-space-sm'}>
                            <h1>{size}</h1>
                            {
                                textLinkVariants.map(variant => {
                                    return (
                                        <div className={'u-space-sm'}>
                                            {variant}
                                            <ARCTextLink size={size}
                                                       variant={variant}
                                                       label={textLinkText ? textLinkText : 'Text'}
                                                       icon={textLinkIcon}
                                                       disabled={textLinksDisabled}
                                                       onClick={() => {}}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        )
                    })}
                </div>
            </div>

            <h1>Show all form elements:</h1>


            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Text Input</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-textinput-label'} value={textInputLabel} className={'u-space-sm'} onChange={(event) => setTextInputLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <ARCTextInput size={'sm'} required={false} id={'sandbox-textinput-error'} value={textInputErrorMessage} className={'u-space-sm'} onChange={(event) => setTextInputErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-text-required-checkbox'} label={'Required?'} onChange={() => setTextInputRequired(!textInputRequired)} checked={textInputRequired}/>
                    </div>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-text-disabled-checkbox'} label={'Disabled?'} onChange={() => setTextInputDisabled(!textInputDisabled)} checked={textInputDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {formInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm'}>
                                <h2>{size}</h2>

                                <ARCTextInput size={size} placeholder={'Sample Text'} required={textInputRequired} disabled={textInputDisabled} label={textInputLabel || 'Sample Text'} onChange={(event) => {}} value={undefined} id={`sample-text-input-${size}`} validate={(val) => {if(val) {return textInputErrorMessage;}}}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>


            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Text Area</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-textarea-label'} value={textAreaLabel} className={'u-space-sm'} onChange={(event) => setTextAreaLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <ARCTextInput size={'sm'} required={false} id={'sandbox-textarea-error'} value={textAreaErrorMessage} className={'u-space-sm'} onChange={(event) => setTextAreaErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-textarea-required-checkbox'} label={'Required?'} onChange={() => setTextAreaRequired(!textAreaRequired)} checked={textAreaRequired}/>
                    </div>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-textarea-disabled-checkbox'} label={'Disabled?'} onChange={() => setTextAreaDisabled(!textAreaDisabled)} checked={textAreaDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {formInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm'}>
                                <h2>{size}</h2>

                                <ARCTextArea size={size} placeholder={'Sample Text'} required={textAreaRequired} disabled={textAreaDisabled} label={textAreaLabel || 'Sample Text'} onChange={(event) => {}} value={undefined} id={`sample-text-input-${size}`} validate={(val) => {if(val) {return textAreaErrorMessage;}}}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Select Input</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-selectinput-label'} value={selectInputLabel} className={'u-space-sm'} onChange={(event) => setSelectInputLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <ARCTextInput size={'sm'} required={false} id={'sandbox-selectinput-error'} value={selectInputErrorMessage} className={'u-space-sm'} onChange={(event) => setSelectInputErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-selectinput-required-checkbox'} label={'Required?'} onChange={() => setSelectInputRequired(!selectInputRequired)} checked={selectInputRequired}/>
                    </div>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-selectinput-disabled-checkbox'} label={'Disabled?'} onChange={() => setSelectInputDisabled(!selectInputDisabled)} checked={selectInputDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {formInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm'}>
                                <h2>{size}</h2>

                                <ARCSelectInput size={size}
                                                placeholder={'Sample Text'}
                                                required={selectInputRequired}
                                                disabled={selectInputDisabled}
                                                label={selectInputLabel || 'Sample Text'}
                                                onChange={(opt) => {setSelectValue(prevState => ({...prevState, [size]: opt.value}))}}
                                                options={
                                                    [
                                                        {value: '1', label: 'Option Text 1'},
                                                        {value: '2', label: 'Option Text 2'},
                                                        {value: '3', label: 'Option Text 3'},
                                                        {value: '4', label: 'Option Text 4'}
                                                    ]
                                                }
                                                value={selectValue[size]}
                                                id={`sample-select-input-${size}`}
                                                validate={(val) => {
                                                    if(val) {
                                                     return selectInputErrorMessage;
                                                    }
                                                }}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Radio Buttons</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-radioinput-label'} value={radioInputLabel} className={'u-space-sm'} onChange={(event) => setRadioInputLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <ARCTextInput size={'sm'} required={false} id={'sandbox-radioinput-error'} value={radioInputErrorMessage} className={'u-space-sm'} onChange={(event) => setRadioInputErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-radioinput-required-checkbox'} label={'Required?'} onChange={() => setRadioInputRequired(!radioInputRequired)} checked={radioInputRequired}/>
                    </div>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-radioinput-disabled-checkbox'} label={'Disabled?'} onChange={() => setRadioInputDisabled(!radioInputDisabled)} checked={radioInputDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {fullFormInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm'}>
                                <h2>{size}</h2>

                                <ARCRadioInput size={size} disabled={radioInputDisabled} required={radioInputRequired} label={radioInputLabel || 'Sample Text'} onChange={() => {setRadioChecked(prevState => ({...prevState, [size]: !prevState[size]}))}} validate={() => {return radioInputErrorMessage;}} id={`sample-radio-input-${size}`} on={!!radioChecked[size]}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Checkboxes</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-checkbox-label'} value={checkboxInputLabel} className={'u-space-sm'} onChange={(event) => setCheckboxInputLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <ARCTextInput size={'sm'} required={false} id={'sandbox-checkbox-error'} value={checkboxInputErrorMessage} className={'u-space-sm'} onChange={(event) => setCheckboxInputErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-checkbox-required-checkbox'} label={'Required?'} onChange={() => setCheckboxInputRequired(!checkboxInputRequired)} checked={checkboxInputRequired}/>
                    </div>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-checkbox-disabled-checkbox'} label={'Disabled?'} onChange={() => setCheckboxInputDisabled(!checkboxInputDisabled)} checked={checkboxInputDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {fullFormInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm u-overflow-hidden'}>
                                <h2>{size}</h2>

                                <ARCCheckbox size={size} required={checkboxInputRequired} disabled={checkboxInputDisabled} label={checkboxInputLabel || 'Sample Text'} onChange={() => {setCheckboxChecked(prevState => ({...prevState, [size]: !prevState[size]}))}} checked={checkboxChecked[size]} id={`sample-checkbox-input-${size}`} validate={() => {return checkboxInputErrorMessage;}}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Filters</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-filter-label'} value={filterInputLabel} className={'u-space-sm'} onChange={(event) => setFilterInputLabel(event.target.value)} label={'Label'} placeholder={'Label'}/>

                    <div className={'u-display--flex u-display--flex-row u-space-sm'}>
                        <ARCCheckbox size={'sm'} id={'input-filter-checkbox-disabled'} label={'Disabled?'} onChange={() => setFilterInputDisabled(!filterInputDisabled)} checked={filterInputDisabled}/>
                    </div>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                        <div className={'u-space-sm'}>
                            <h2>{'Select'}</h2>
                            <ARCSelectFilterInput placeholder={'Sample Text'}
                                                  disabled={filterInputDisabled}
                                                  label={filterInputLabel || 'Sample Text'}
                                                  onChange={(opt) => {setSelectFilterValue(opt.value)}}
                                                  onClear={() => {setSelectFilterValue(undefined)}}
                                                  options={
                                                      [
                                                          {value: '1', label: 'Option Text 1'},
                                                          {value: '2', label: 'Option Text 2'},
                                                          {value: '3', label: 'Option Text 3'},
                                                          {value: '4', label: 'Option Text 4'}
                                                      ]
                                                  }
                                                  value={selectFilterValue}
                                                  id={`sample-select-filter`}/>
                        </div>

                        <div className={'u-space-sm'}>
                            <h2>{'Search'}</h2>
                            <ARCTextFilterInput placeholder={'Sample Text'}
                                                disabled={filterInputDisabled}
                                                label={filterInputLabel || 'Sample Text'}
                                                onChange={(event) => {setSearchFilterValue(event.target.value)}}
                                                onClear={() => {setSearchFilterValue('')}}
                                                value={searchFilterValue}
                                                id={`sample-select-filter`}/>
                        </div>
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Form Errors</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-formerror-label'} value={formErrorMessage} className={'u-space-sm'} onChange={(event) => setFormErrorMessage(event.target.value)} label={'Error Message'} placeholder={'Error Message'}/>
                </div>

                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {formInputSizes.map(size => {
                        return (
                            <div className={'u-space-sm'}>
                                <h2>{size}</h2>

                                <ARCFormError size={size} errorMessage={formErrorMessage || 'Error Message'}/>
                            </div>
                        )
                    })}
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Badges</h2>

                <div className={'l-container--sm l-wrap u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <ARCTextInput size={'sm'} required={false} id={'sandbox-statusbadge-label'} value={statusBadgeMessage} className={'u-space-sm'} onChange={(event) => setStatusBadgeMessage(event.target.value)} label={'Message'} placeholder={'Message'}/>
                </div>

                <h2>Status</h2>
                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    {statusBadgeStatuses.map(status => {
                        return (
                            <div className={'u-space-sm'}>
                                <h3>{status}</h3>

                                <ARCBadge status={status} label={statusBadgeMessage || 'Status'} type={'status'}/>
                            </div>
                        )
                    })}
                </div>

                <h2>Filter</h2>
                <div className={'u-display--flex'}>
                    <ARCButton size={'md'}
                               variant={'danger'}
                               fill={'filled'}
                               label={'Decrement'}
                               onClick={() => {setFilterBadgeCount(filterBadgeCount - 1)}}/>

                    <ARCButton size={'md'}
                               variant={'primary'}
                               fill={'filled'}
                               label={'Increment'}
                               onClick={() => {setFilterBadgeCount(filterBadgeCount + 1)}}/>
                </div>
                <div className={'l-container--md u-flex-wrap u-display--flex u-display--flex-row u-align-items--center u-justify-content--center'}>
                    <div className={'u-space-sm'}>
                        <ARCBadge status={'on'} label={`${filterBadgeCount}`} type={'filter-number'}/>
                    </div>
                </div>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Ruler</h2>

                <ARCRuler/>

                <div className={'u-space-sm'}/>
            </div>


            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <ARCRuler/>

                <h2>Accordion</h2>


            <ARCAccordion 
                title={'Sample Accordion'}
                sections={[
                    {title: 'Section #1', content: <div>{'Section 1 content'}</div>},
                    {title: 'Section #2', content: <div>{'Section 2 content'}</div>},
                    {title: 'Section #3', content: <div>{'Section 3 content'}</div>},
                    {title: 'Section #4', content: <div>{'Section 4 content'}</div>}
                ]}
            />



                <div className={'u-space-sm'}/>

                <ARCRuler/>
            </div>

            <div className={'u-space-sm l-container--lg u-display--flex u-display--flex-column u-align-items--center u-justify-content--center u-display--flex u-display--flex-column u-align-items--center u-justify-content--center'}>
                <h2>Footer</h2>

                <div className={'u-space-sm'}/>
            </div>

        </div>
            <ARCFooter/>
        </div>
    );
}